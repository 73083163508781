import React from 'react';
import AuthUserContext from '../providers/AuthUserContext';

const withAuthUser = Component => props => (
  <AuthUserContext.Consumer>
    {me => <Component {...props} authUser={me} />}
  </AuthUserContext.Consumer>
);

export default withAuthUser;
