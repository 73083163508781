import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Popconfirm } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import { styleConstructor } from 'center-ui/utils';
import { sansProps } from 'center-ui/utils/styledSansProps';

const DeleteButtonCircle = sansProps(styled, {
  styling: true,
})(Button)`
  &&& {
    width: 1.5rem !important;
    height: 1.5rem !important;
    color: white !important;
    border: none !important;
    ${({ styling }) => styling && styleConstructor(styling)};
  }
`;

const DeleteButton = ({
  onClick,
  onConfirmVisibleChange,
  placement,
  confirmTitle,
  normalButton,
  buttonText,
  okText,
  styling,
  ...rest
}) => (
  <Popconfirm
    title={confirmTitle}
    onConfirm={onClick}
    placement={placement}
    okText={okText}
    onVisibleChange={onConfirmVisibleChange}
  >
    {normalButton ? (
      <Button {...rest}>{buttonText}</Button>
    ) : (
      <DeleteButtonCircle {...rest} styling={styling} />
    )}
  </Popconfirm>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  onConfirmVisibleChange: PropTypes.func,
  placement: PropTypes.string,
  confirmTitle: PropTypes.any,
  buttonText: PropTypes.any,
  normalButton: PropTypes.bool,
  okText: PropTypes.string,
  styling: PropTypes.object,
};

DeleteButton.defaultProps = {
  okText: 'Remove',
  styling: {
    backgroundColor: Colors.gray,
    position: 'absolute',
    fontSize: '0.875rem',
  },
};

export default DeleteButton;
