import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Notifier, Ellipsis, Checkbox, Icon } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import { MQBreakPoints } from 'core-ui/utils/consts';
import { sansProps } from 'center-ui/utils/styledSansProps';
import ReceiptImg from '../Receipt/ReceiptImg';
import SimpleButton from '../SimpleButton';
import API from '../../services/rest/api';
import { fileDownload, getIsPDF } from '../../utils';
import { MIME_TYPES } from '../../utils/constants';
import DeleteButton from '../DeleteButton';

import pdfAreaImg from '../../assets/imgs/pdf-area.svg';

const { MOBILE } = MQBreakPoints;

const ReceiptBinImageWrapper = styled.div`
  position: relative;
  color: ${Colors.white};
  font-size: 1.25rem;
  height: 6.875rem;
  width: 6.875rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const ReceiptImgStyled = styled(ReceiptImg)`
  border: 1px solid ${Colors.gray_5};
`;

const PdfImg = styled.img`
  height: 6.875rem;
  width: 6.875rem;
`;

const PdfFileName = styled.div`
  position: absolute;
  top: 55%;
  width: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
  color: ${Colors.gray};
`;

const ReceiptActions = sansProps(styled, {
  selected: true,
  loading: true,
  isMobile: true,
})('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ selected, loading }) => (selected || loading ? 1 : 0)};
  transition: opacity 50ms ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ isMobile }) =>
      isMobile ? 'transparent' : Colors.navy};
    opacity: ${({ selected, loading }) => (selected || loading ? 0.6 : 0)};
  }

  ${ReceiptBinImageWrapper}:hover &,
  ${ReceiptBinImageWrapper}:focus &,
  ${ReceiptBinImageWrapper}:active & {
    opacity: 1;
    &::after {
      opacity: 0.6;
    }
  }
`;

const ReceiptActionsInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckboxStyled = styled(Checkbox)`
  position: absolute;
  top: 0.1rem;
  left: 0.2rem;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ViewReceiptBtn = styled(SimpleButton)`
  &:hover:not(disabled) {
    opacity: 0.7;
  }
`;

const ReceiptBinPreview = ({
  receipt,
  onDeleteReceipt,
  onSelectReceipt,
  onViewReceipt,
  loading,
}) => {
  const { URI, fileID, type } = receipt;
  const [isDownloadingPdf, setDownloadingPdf] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const isPdf = getIsPDF(type);
  const fileName = `${fileID}`.split('/').pop();
  const receiptElementID = `bin-receipt-${
    isPdf ? 'pdf' : 'image'
  }-${fileName.replace(/\s/gu, '_')}`;

  const showActions = (!loading && !isSelected) || isActive;

  const handlePdfDownload = () => {
    const isUriBlobData = URI.startsWith('data:');
    setDownloadingPdf(true);
    API.downloadFileFromURI(URI, MIME_TYPES.pdf, !isUriBlobData)
      .then(blob => {
        fileDownload(blob, fileName, MIME_TYPES.pdf);
        setDownloadingPdf(false);
      })
      .catch(err => {
        Notifier.notification({
          type: 'error',
          message: 'PDF download',
          description: err.message,
        });
      });
  };

  const renderPdfReceipt = () => (
    <>
      <SimpleButton onClick={handlePdfDownload}>
        <PdfImg
          id={receiptElementID}
          data-testid={receiptElementID}
          src={pdfAreaImg}
          alt="PDF receipt"
        />
        <PdfFileName>
          <Ellipsis lines={3}>{fileName}</Ellipsis>
        </PdfFileName>
      </SimpleButton>
    </>
  );

  const renderImgReceipt = () => (
    <ReceiptImgStyled
      id={receiptElementID}
      data-testid={receiptElementID}
      src={receipt.hdResThumbURI || receipt.URI}
      backgroundSize="cover"
      aspectRatio={1}
      showLoadingSpinner={false}
    />
  );

  return (
    <MediaQuery {...MOBILE}>
      {isMobile => (
        <ReceiptBinImageWrapper>
          {isPdf ? renderPdfReceipt() : renderImgReceipt()}
          <ReceiptActions
            selected={isSelected || isActive}
            loading={loading}
            isMobile={isMobile}
          >
            {!isMobile && (
              <ReceiptActionsInner>
                <CheckboxStyled
                  onChange={e => {
                    setIsSelected(e.target.checked);
                    onSelectReceipt(e.target.checked, fileID);
                  }}
                />

                {loading && (
                  <Loader>
                    <Icon type="loading" />
                  </Loader>
                )}
                {showActions && (
                  <ActionButtonsWrapper>
                    <ViewReceiptBtn
                      onClick={() =>
                        isPdf ? handlePdfDownload() : onViewReceipt(receipt)
                      }
                    >
                      <Icon type={isDownloadingPdf ? 'loading' : 'eye'} />
                    </ViewReceiptBtn>

                    <DeleteButton
                      data-testid="delete-receipt-button"
                      onClick={() => onDeleteReceipt(fileID)}
                      shape="circle"
                      icon="delete"
                      onConfirmVisibleChange={visible => setIsActive(visible)}
                      confirmTitle={
                        <span>
                          Are you sure you want<br />
                          to remove this receipt?
                        </span>
                      }
                      styling={{
                        backgroundColor: 'transparent',
                        position: 'relative',
                      }}
                    />
                  </ActionButtonsWrapper>
                )}
              </ReceiptActionsInner>
            )}
          </ReceiptActions>
        </ReceiptBinImageWrapper>
      )}
    </MediaQuery>
  );
};

ReceiptBinPreview.propTypes = {
  receipt: PropTypes.object,
  onDeleteReceipt: PropTypes.func,
  onSelectReceipt: PropTypes.func,
  onViewReceipt: PropTypes.func,
  loading: PropTypes.bool,
};

export default ReceiptBinPreview;
