import React from 'react';
import PropTypes from 'prop-types';

class TablePure extends React.PureComponent {
  render = () => {
    const { children, ...rest } = this.props;
    return <table {...rest}>{children}</table>;
  };
}

TablePure.propTypes = {
  children: PropTypes.any,
};

export default TablePure;
