import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { notification } from 'antd';
import Vars from '../../themes/variables';
import { generateRandomString } from '../../utils';

const notificationFn = {
  SUCCESS: notification.success,
  ERROR: notification.error,
  INFO: notification.info,
  WARNING: notification.warning,
  WARN: notification.warn,
  OPEN: notification.open,
  CLOSE: notification.close,
  DESTROY: notification.destroy,
};

const typeColors = {
  SUCCESS: Vars.SUCCESS_COLOR,
  ERROR: Vars.ERROR_COLOR,
  INFO: Vars.PRIMARY_COLOR,
  WARNING: Vars.WARNING_COLOR,
  WARN: Vars.WARNING_COLOR,
  OPEN: Vars.PRIMARY_COLOR,
  CLOSE: null,
  DESTROY: null,
  PLAIN: 'transparent',
};

const NotificationStyled = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 5px;
    background-color: ${({ type }) => typeColors[type]};
    top: 0;
    left: 0;
  }
`;

class Notifier extends React.PureComponent {
  render = () => {
    const { type, children } = this.props;
    const TYPE = type.toUpperCase();
    return <NotificationStyled type={TYPE}>{children}</NotificationStyled>;
  };
}

Notifier.propTypes = {
  type: PropTypes.oneOf([
    'success',
    'error',
    'info',
    'warning',
    'warn',
    'open',
    'close',
    'destroy',
    'plain',
  ]).isRequired,
  children: PropTypes.node,
};

Notifier.defaultProps = {};

const onNotify = ({
  key = generateRandomString('notification'),
  type = 'OPEN',
  showIcon = false,
  message,
  ...params
}) => {
  const TYPE = type.toUpperCase();
  if (TYPE === 'CLOSE') {
    notificationFn[TYPE](key);
    return;
  }
  const styledMessage = <Notifier type={type}>{message}</Notifier>;
  notificationFn[showIcon ? TYPE : 'OPEN']({
    ...params,
    key,
    message: styledMessage,
  });
};

Notifier.notification = onNotify;

export default Notifier;
