import { createGlobalStyle } from 'styled-components';
import styledSanitize from 'styled-sanitize';
import media from 'styled-media-query';
import Vars from 'center-ui/themes/variables';
import Colors from 'center-ui/themes/colors';

/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`
  ${styledSanitize}

  html {
    font-family: 'Gotham SSm A', 'Gotham SSm B', Helvetica, Tahoma, sans-serif;
    font-size: 16px;
    color: ${Vars.PRIMARY_COLOR};
  }

  html,
  body {
    height: 100%;
    width: 100% !important;
    padding: 0 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f8f8fA!important;
    font-variant: normal !important;
  }

  * {
  font-variant: normal !important;
  }

  body {
    overflow-x: hidden;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.01875rem;
    font-weight: normal;
  }

  #root, .awsappsync {
    height: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  a {
    :focus {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .wrap-text {
    white-space: normal !important;
    word-break: normal !important;
  }

  .table-row-grayed-out {
      color: ${Colors.gray} !important;
      .ant-avatar {
        color: ${Colors.gray} !important;
        border-color: ${Colors.gray} !important;
      }
  }

  input:focus,
  textarea:focus,
  select:focus,
  div[role=combobox] {
    box-shadow: none !important;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* This is a hack to override antd styles, there is no currently a way to do at component level */
  .ant-select-dropdown {
    .ant-select-dropdown-menu-item-group-title {
      font-weight: 700 !important;
      color: ${Colors.navy} !important;
    }
    .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown-menu-item-selected:hover {
      color: ${Colors.blue} !important;
      font-weight: 400 !important;
    }
  }

  /* Date Range Picker for mobile */
  .ant-calendar {
    ${media.lessThan('medium')`
      .ant-calendar-panel {
        background-color: ${Colors.white} !important;
        margin-top: 3rem auto !important;
        width: 99vw !important;
        max-width: 420px !important;
        .ant-calendar-range-left {
            display: block !important;
            width: 100% !important;
            max-width: 412px !important;
            float: unset !important;
        }
        .ant-calendar-range-right {
            display: block !important;
            width: 100% !important;
            max-width: 412px !important;
            float: unset !important;
        }
        .ant-calendar-input  {
          display: none !important;
        }
      }
      .ant-calendar-range {
        width: unset !important;
        background-color: unset !important;
      }
    `};
    .ant-calendar-selected-day:not(.ant-calendar-disabled-cell) .ant-calendar-date {
      background: ${Colors.blue} !important;
      color: ${Colors.white} !important;
    }
  }

  .table-row-shadowed {
    box-shadow: 0px 9px 0px 0px white, 0px 2px 8px rgba(0, 0, 0, 0.12);
    td:first-child {
        border-left: 2px solid ${Colors.blue} !important;
    }
    td {
      background: ${Colors.gray_7}!important;
    }
  }

  .hide-close-x-notification {
    .ant-notification-notice-close {
      display: none;
    }
  }

  .anticon {
    vertical-align: 2px !important;
  }

  /* ::-webkit-scrollbar-thumb {
    background: ${Colors.gray_4};
    border-radius: 10px;
  } */

  .track-vertical {
    top: 2px !important;
    bottom: 2px !important;
    right: 2px !important;
    z-index: 2;
  }

  .ant-tooltip {
    z-index: 1 !important;
    /* max-width: 420px; */
    .ant-tooltip-inner {
      background-color: ${Colors.white} !important;
      color: ${Colors.navy} !important;
      padding: 1.125rem;
    }
    .ant-tooltip-arrow:before {
      background-color: ${Colors.white} !important;
    }
  }
`;

export default GlobalStyles;
