import React from 'react';
import PropTypes from 'prop-types';
import Empty from '../../components/Empty';
import ConfigProvider from '../../components/ConfigProvider';

export const withEmptyState = WrappedComponent => {
  class WithEmptyState extends React.PureComponent {
    render = () => {
      const {
        emptyStateParams,
        renderEmpty,
        showDefaultEmptyState,
        ...rest
      } = this.props;

      return showDefaultEmptyState ? (
        <WrappedComponent {...rest} />
      ) : (
        <ConfigProvider
          renderEmpty={() =>
            renderEmpty ? renderEmpty() : <Empty {...emptyStateParams} />
          }
        >
          <WrappedComponent {...rest} />
        </ConfigProvider>
      );
    };
  }

  WithEmptyState.propTypes = {
    showDefaultEmptyState: PropTypes.bool,
    emptyStateParams: PropTypes.shape({
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      alt: PropTypes.string,
      styling: PropTypes.object,
    }),
    renderEmpty: PropTypes.func,
  };

  WithEmptyState.defaultProps = {
    showDefaultEmptyState: false,
  };

  return WithEmptyState;
};

export default withEmptyState;
