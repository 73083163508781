/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import Steps from '../Steps';
import Actions from '../Actions';
import Colors from '../../themes/colors';

const { Step } = Steps;

const DialogFooter = styled(Actions)`
  justify-content: flex-end;
  padding: 1.5rem 0 !important;
  ${media.lessThan('medium')`
    padding: 1.5rem !important;
    width: 100% !important;
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    border-top: 1px solid ${Colors.gray_6};
  `};

  li:first-child {
    margin-right: ${({ hasBackBtn }) =>
      hasBackBtn ? 'auto' : 'inherit'} !important;
  }
`;

class Wizard extends React.PureComponent {
  constructor(props) {
    super(props);
    const step = props.initialStep || 0;
    this.state = {
      step,
      data: props.data,
      elements: this.init(props.children, props.data, step),
    };
  }

  componentDidUpdate(prevProps) {
    const { initialStep } = this.props;
    const { data } = this.state;
    if (initialStep !== null && initialStep >= 0) {
      if (prevProps.initialStep !== initialStep) {
        this.navigate(initialStep, data);
      }
    }
  }

  init = (children, data, step) => {
    const params = {
      nextStep: this.nextStep,
      data,
      currentStep: step,
      previousStep: this.previousStep,
      navigate: this.navigate,
      resetData: this.resetData,
    };
    const allComponents = children.map((obj, i) => {
      params.key = i;
      params.title = obj.title;
      return React.cloneElement(obj.content, params);
    });
    return allComponents;
  };

  resetData = callback => {
    this.setState({ data: undefined }, () => {
      if (callback) callback();
    });
  };

  navigate = (toStep, data) => {
    const { step } = this.state;
    const { onStepChanged } = this.props;
    if (toStep >= 0 && toStep !== step) {
      this.setState(
        {
          step: toStep,
          data,
        },
        () => {
          if (onStepChanged) {
            onStepChanged(step);
          }
        },
      );
    }
  };

  nextStep = data => {
    const { elements, step } = this.state;
    const { onStepChanged } = this.props;
    if (elements.length - step > 1) {
      const params = {
        nextStep: this.nextStep,
        data,
        currentStep: step + 1,
        previousStep: this.previousStep,
        navigate: this.navigate,
        resetData: this.resetData,
      };

      const newElements = elements.map((obj, i) => {
        params.key = i;
        return React.cloneElement(obj, params);
      });

      this.setState(
        {
          step: step + 1,
          data,
          elements: newElements,
        },
        () => {
          if (onStepChanged) {
            onStepChanged(step + 1);
          }
        },
      );
    }
  };

  previousStep = data => {
    const { elements, step } = this.state;
    const { onStepChanged } = this.props;
    if (step > 0) {
      const params = {
        nextStep: this.nextStep,
        data,
        currentStep: step - 1,
        previousStep: this.previousStep,
        navigate: this.navigate,
        resetData: this.resetData,
      };

      const newElements = elements.map((obj, i) => {
        params.key = i;
        return React.cloneElement(obj, params);
      });

      this.setState(
        {
          step: step - 1,
          data,
          elements: newElements,
        },
        () => {
          if (onStepChanged) {
            onStepChanged(step + 1);
          }
        },
      );
    }
  };

  render = () => {
    const { showSteps, stepsClassName, isMobile, className } = this.props;
    const { elements, step } = this.state;
    return (
      <>
        {showSteps && (
          <Steps
            className={stepsClassName}
            current={step}
            progressDot={isMobile}
          >
            {elements.map(s => (
              <Step key={s.props.title} title={s.props.title} />
            ))}
          </Steps>
        )}
        <div className={className}>{elements[step]}</div>
      </>
    );
  };
}

Wizard.propTypes = {
  data: PropTypes.any,
  children: PropTypes.array.isRequired,
  onStepChanged: PropTypes.func,
  stepsClassName: PropTypes.string,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  initialStep: PropTypes.number,
  showSteps: PropTypes.bool,
};

Wizard.defaultProps = {
  showSteps: false,
};

Wizard.DialogFooter = DialogFooter;

export default Wizard;
