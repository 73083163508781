import React from 'react';
import PropTypes from 'prop-types';

class BodyPure extends React.PureComponent {
  render = () => {
    const { children, ...rest } = this.props;
    return <tbody {...rest}>{children}</tbody>;
  };
}

BodyPure.propTypes = {
  children: PropTypes.any,
};

export default BodyPure;
