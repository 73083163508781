/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import memoize from 'memoize-one';
import _get from 'lodash/get';
import { Avatar } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import { styleConstructor } from 'center-ui/utils';
import { AppNotificationSubjectCodes } from '../../utils/appNotifications';
import { cardStatusCodesEnums } from '../../utils/cards';
import CreditCardIcon from '../../assets/imgs/credit-card-icon.svg';

const getStatusColor = statusCode => {
  let color = Colors.navy;
  switch (statusCode) {
    case cardStatusCodesEnums.IssuedInactive:
      color = Colors.blue;
      break;
    case cardStatusCodesEnums.Open:
      color = Colors.green;
      break;
    case cardStatusCodesEnums.Closed:
      color = Colors.gray;
      break;
    case cardStatusCodesEnums.Inactive:
    case cardStatusCodesEnums.Lost:
    case cardStatusCodesEnums.Stolen:
      color = Colors.red;
      break;
    default:
      break;
  }
  return color;
};

const Wrapper = styled.span`
  img {
    object-fit: none !important;
  }
  .anticon {
    vertical-align: 0.1rem !important;
  }
  .ant-avatar {
    ${({ statusCode }) =>
      styleConstructor({
        border: `1px solid ${getStatusColor(statusCode)}`,
    })};
  }
`;

const NotificationAvatar = ({ subjectCode, size, meta }) => {
  const metaJSON = memoize(m => JSON.parse(m))(meta);

  const getAvatarIconParams = () => {
    const params = { src: null, icon: null };
    switch (subjectCode) {
      case AppNotificationSubjectCodes.CardStatusChanged:
      case AppNotificationSubjectCodes.ReplacementCardIssued:
        params.src = CreditCardIcon;
        break;
      default:
        params.icon = 'notification';
        break;
    }
    return params;
  };

  return (
    <Wrapper statusCode={_get(metaJSON, 'statusCode')}>
      <Avatar size={size} {...getAvatarIconParams()} />
    </Wrapper>
  );
};

NotificationAvatar.propTypes = {
  meta: PropTypes.string,
  subjectCode: PropTypes.string,
  size: PropTypes.any,
};

NotificationAvatar.defaultProps = {
  size: 39,
};

export default NotificationAvatar;
