import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from 'center-ui';
import {
  getMenuDataForLocation,
  getMenuTitleForLocation,
} from '../../common/menu';
import NavMenu from '../NavMenu';

const HeadingStyled = styled(Heading)`
  padding: 0 24px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
`;

class SideNav extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <Fragment>
        <HeadingStyled as="h3">
          {getMenuTitleForLocation(location)}
        </HeadingStyled>
        <NavMenu
          mode="vertical"
          menuData={getMenuDataForLocation(location)}
          location={location}
        />
      </Fragment>
    );
  }
}

SideNav.propTypes = {
  location: PropTypes.object,
};

export default SideNav;
