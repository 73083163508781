import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import MediaQuery from 'react-responsive';
import { Mutation } from 'react-apollo';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import { Drawer, Heading } from 'center-ui';
import { MQBreakPoints } from 'core-ui/utils/consts';
import { withRouter } from 'react-router-dom';
import { TOGGLE_SHOW_RECEIPTS_BIN_PANEL } from '../../services/graphql/mutations';
import { withShowReceiptsBinPanelFlag } from '../../services/graphql/hoc';
import ReceiptsBinContent from './ReceiptsBinContent';
import ReceiptBinDropZone from './ReceiptBinDropZone';
import { GET_RECEIPTS_BIN } from '../../services/graphql/queries';
import useDelegateUser from '../../common/useDelegateUser';
import { shouldUseDelegateContext } from '../../utils';

const { MOBILE, DESKTOP } = MQBreakPoints;

const media = generateMedia({
  medium: `${DESKTOP.minWidth}px`,
});

const DrawerStyled = styled(Drawer)`
  margin-top: 3.25rem;
  ${media.greaterThan('medium')`
    margin-top: 4rem;
  `};
  .ant-drawer-content-wrapper {
    max-height: calc(100% - 3.25rem);
    ${media.greaterThan('medium')`
      max-height: calc(100% - 4rem);
    `};
  }
  .ant-drawer-content {
    height: 100%;
    ${media.greaterThan('medium')`
      padding: 0 !important;
    `};
  }
  .ant-drawer-header {
    padding: 1rem 1rem 0rem 1rem !important;
    ${media.greaterThan('medium')`
      margin-top: 2rem !important;
    `};
  }

  .ant-drawer-title {
    padding-left: 24px;
  }

  .ant-drawer-body {
    padding: 0 2.5rem 0 2.5rem !important;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .ant-drawer-close-x {
    font-size: 1.125rem;
    ${media.greaterThan('medium')`
      margin-top: 2rem !important;
      margin-right: 2rem !important;
    `};
  }

  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
  }
`;

const ReceiptsBinPanel = ({ match, isReceiptsBinPanelVisible }) => {
  const [delegateUser] = useDelegateUser();
  const delegateOf =
    delegateUser && shouldUseDelegateContext(match.path)
      ? delegateUser.ID
      : undefined;

  const handleToggleReceiptsBin = setShowReceiptsBinPanel => {
    setShowReceiptsBinPanel({
      variables: { visible: !isReceiptsBinPanelVisible },
    });
  };

  const handleUploadStart = (client, { fileID, type }) => {
    const receiptsResult = client.readQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
    });
    const receipts = _get(receiptsResult, 'receipts', []);

    const newReceipt = {
      fileID,
      URI: '',
      hdResThumbURI: '',
      type,
      uploadDate: moment().toISOString(),
      __typename: 'Receipt',
    };

    client.writeQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
      data: { receipts: [...receipts, newReceipt] },
    });
  };

  const handleUploadSuccess = (client, { file, fileID, tempFileID }) => {
    const receiptsResult = client.readQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
    });
    const receipts = _get(receiptsResult, 'receipts', []);
    const uploadedImages = _remove(
      receipts,
      receiptItem => receiptItem.fileID === tempFileID,
    );

    const [uploadedImage] = uploadedImages;
    if (uploadedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        uploadedImage.fileID = fileID;
        uploadedImage.URI = imageData;
        uploadedImage.hdResThumbURI = '';
        uploadedImage.type = file.type;

        client.writeQuery({
          query: GET_RECEIPTS_BIN,
          variables: { delegateOf },
          data: { receipts: [...receipts, uploadedImage] },
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUploadError = (client, { fileID }) => {
    const receiptsResult = client.readQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
    });
    const receipts = _get(receiptsResult, 'receipts', []);

    _remove(receipts, receiptItem => receiptItem.fileID === fileID);

    client.writeQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
      data: { receipts },
    });
  };

  return (
    <Mutation mutation={TOGGLE_SHOW_RECEIPTS_BIN_PANEL} ignoreResults>
      {(setShowReceiptsBinPanel, { client }) => (
        <div id="affix-receipt-bin-panel">
          <MediaQuery {...MOBILE}>
            {isMobile => (
              <DrawerStyled
                title={
                  <Heading as="h2">
                    {delegateUser && shouldUseDelegateContext(match.path)
                      ? `${delegateUser.fullName}'s Receipts`
                      : 'Receipts'}
                  </Heading>
                }
                placement="right"
                width={isMobile ? '100%' : 570}
                mask={false}
                closable
                zIndex={1}
                onClose={() => {
                  handleToggleReceiptsBin(setShowReceiptsBinPanel);
                }}
                visible={isReceiptsBinPanelVisible}
                level={null}
                getContainer={() =>
                  document.getElementById('affix-receipt-bin-panel')
                }
              >
                <ReceiptBinDropZone
                  delegateOf={delegateOf}
                  onUploadStart={uploadProps => {
                    handleUploadStart(client, uploadProps);
                  }}
                  onUploadSuccess={uploadProps => {
                    handleUploadSuccess(client, uploadProps);
                  }}
                  onUploadError={uploadProps => {
                    handleUploadError(client, uploadProps);
                  }}
                >
                  {({ openFileDialog }) => (
                    <ReceiptsBinContent
                      match={match}
                      delegateOf={delegateOf}
                      openFileDialog={openFileDialog}
                    />
                  )}
                </ReceiptBinDropZone>
              </DrawerStyled>
            )}
          </MediaQuery>
        </div>
      )}
    </Mutation>
  );
};

ReceiptsBinPanel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
  }),
  isReceiptsBinPanelVisible: PropTypes.bool,
};

export default withShowReceiptsBinPanelFlag(withRouter(ReceiptsBinPanel));
