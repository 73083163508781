import gql from 'graphql-tag';

export const COST_CENTER_FRAGMENT = gql`
  fragment CostCenterFragment on CostCenter {
    ID
    name
    externalID
    parentID
    spenderIDs
  }
`;

export const COMPONENT_STATE_FRAGMENT = gql`
  fragment ComponentState on ComponentState {
    ID
    state
  }
`;

export const CARD_INFO_FRAGMENT = gql`
  fragment CardInfoFragment on CardInfo {
    ID
    activeState
    isLocked
    isClosed
    isRequested
    canUnlock
  }
`;

export const CARD_FRAGMENT = gql`
  fragment CardFragment on Card {
    ID
    displayLabel
    limit
    last4
    cardProgramInstanceID
    reissued {
      reasonCode
    }
    statusCode
    statusLabel
    typeLabel
    cardInfo {
      ...CardInfoFragment
    }
  }
  ${CARD_INFO_FRAGMENT}
`;

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    address
    city
    country
    postalCode
    state
  }
`;

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFragment on Pagination {
    next
    previousOffset
    offset
    pages
    totalItems
  }
`;

export const EVENTS_FRAGMENT = gql`
  fragment EventFragment on ExpenseEvent {
    timeStamp
    type
    values
  }
`;

export const GENERAL_LEDGER_ACCOUNT_FRAGMENT = gql`
  fragment GeneralLedgerAccountFragment on GeneralLedgerAccount {
    ID
    name
    code
    isDeleted
  }
`;

export const EXPENSE_TYPE_FRAGMENT = gql`
  fragment ExpenseTypeFragment on ExpenseType {
    ID
    name
    isDeleted
  }
`;

export const POLICY_FORM_FRAGMENT = gql`
  fragment PolicyFormFragment on ExpensePolicyForm {
    fields {
      custom
      hidden
      values
      description
      property
      type
      required
    }
  }
`;

export const FLAG_FRAGMENT = gql`
  fragment FlagFragment on Flag {
    ID
    description
    title
    name
    group {
      type
      name
    }
  }
`;

export const EXPENSE_SPLITS_FRAGMENT = gql`
  fragment ExpenseSplitsFragment on Expense {
    ID
    amount
    attendees {
      fullName
      relationship
    }
    dateTime
    customFields
    currencyCode
    costCenterID
    costCenterName
    costCenter @client {
      ID
      name
    }
    expenseTypeID
    expenseTypeName
    expenseType @client {
      ID
      name
    }
    isPersonal
    merchant {
      name
      rawName
    }
    flags {
      ...FlagFragment
    }
    notes
    purpose
    policyForm {
      fields {
        custom
        hidden
        values
        description
        property
        type
        required
      }
    }
    postedDateTime
  }
  ${FLAG_FRAGMENT}
`;

export const AUTO_DEPOSIT_FRAGMENT = gql`
  fragment AutoDepositFragment on AutoDepositToMFA {
    ID
    thresholdAmount
    fromBankAccountID
  }
`;

// TODO Don't use expense fragment on expense list query
export const EXPENSE_FRAGMENT = gql`
  fragment ExpenseFragment on Expense {
    ID
    userID
    user @client {
      ID
      fullName
    }
    isPersonal
    isOOP
    isMileage
    dateTime
    postedDateTime
    approvedDateTime
    rejectedDateTime
    sentBackDateTime
    costCenterID
    costCenterName
    costCenter @client {
      ID
      name
    }
    merchant {
      ID
      name
      rawName
    }
    expenseTypeID
    expenseTypeName
    expenseType @client {
      ID
      name
    }
    policyID
    amount
    status
    purpose
    hasSplits
    hasReceipts
    flags {
      ...FlagFragment
    }
    receiptAffidavit {
      receiptAffidavitAvailable
    }
    requiresAttention
    notes
    attendees {
      fullName
      relationship
    }
    currencyCode
    parent {
      ID
      amount
      postedDateTime
      merchant {
        name
      }
    }
    customFields
    currentApprover {
      user {
        ID
        fullName @client
      }
    }
  }
  ${FLAG_FRAGMENT}
`;

export const ERP_CONFIGURATION_FRAGMENT = gql`
  fragment ERPConfigurationFragment on ERPConfiguration {
    exportSettings {
      businessExpenses {
        accountToCredit {
          ID
          name
        }
        exportType
        possibleExportTypes
      }
      personalExpenses {
        accountToCredit {
          ID
          name
        }
        exportType
        possibleExportTypes
      }
      reimbursableExpenses {
        accountToCredit {
          ID
          name
        }
        exportType
        possibleExportTypes
      }
    }
    importSettings {
      costCenters {
        possibleSources
        source
      }
    }
  }
`;
