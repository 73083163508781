/* eslint no-unused-vars: 0 */
import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';
import Vars from '../../themes/variables';

const Alert = styled(AntAlert)`
  background-color: #fff !important;
  border: solid 1px #e6e6e6 !important;
  border-radius: 1px !important;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 5px;
    background-color: ${Vars.ERROR_COLOR};
    top: 0;
    left: 0;
  }
`;

export default Alert;
