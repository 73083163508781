import _forEach from 'lodash/forEach';

export { userHasFeatures } from './feature';
export { urlToList } from './urlToList';

export const constructFilterQuery = filters => {
  const q = [];
  const constructField = (f, o, v) => `${f}[${o}]:${v}`;
  _forEach(filters, (fVal, field) => {
    const { value, operator } = fVal;
    const val = Array.isArray(value) ? value.join(',') : value;
    q.push(constructField(field, operator, val));
  });
  return q.length > 0 ? q.join(';') : undefined;
};

export const constructSortQuery = sorts => {
  const q = [];
  const constructField = (f, s) => `${f}[${s}]`;
  _forEach(sorts, ([field, sort]) => {
    q.push(constructField(field, sort));
  });
  return q.length > 0 ? q.join(',') : undefined;
};
