import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { sansProps } from 'center-ui/utils/styledSansProps';
import { generateMedia } from 'styled-media-query';
import { Layout } from 'center-ui';
import { Mobile, Desktop } from 'core-ui/components/responsive';
import logoBig from '../../assets/imgs/center-logo-white.svg';
import logoSmall from '../../assets/imgs/center-logo-small-white.svg';
import hamburger from '../../assets/imgs/hamburger.svg';

const media = generateMedia({
  medium: '62.75rem',
});

const LayoutStyled = sansProps(styled, { isHeightFixed: true })(Layout)`
  min-height: 100vh !important;
  overflow-x: hidden !important;
  ${media.greaterThan('medium')`
    ${({ isHeightFixed }) => isHeightFixed && `height:100vh`};
  `};
`;

const Header = styled(Layout.Header)`
  position: fixed !important;
  z-index: 2 !important;
  width: 100% !important;
  display: flex;
  color: #fff;
  padding: 0 1.5rem !important;
`;

const Logo = styled.img`
  margin-right: 1.5rem;
`;

const LogoSmall = styled.img``;

const HamburgerBtn = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  :focus {
    outline: none;
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  flex: 1 1 0%;
  ${media.lessThan('medium')`
    display: none !important;
  `};
`;

const Wrapper = styled(Layout)`
  margin-top: 4rem !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2) !important;
`;

const Sider = styled(Layout.Sider)`
  padding: 2.5rem 0 !important;
  background: #f0f0f0 !important;
`;

const Content = sansProps(styled, { noContainer: true, isHeightFixed: true })(
  Layout.Content,
)`
  position: relative !important;
  background-color: #fff !important;
  z-index: 1 !important;
  ${({ isHeightFixed }) =>
    isHeightFixed && `height: calc(100vh - 64px) !important;`};
  
  ${({ noContainer }) => !noContainer && `padding: 2.5rem 1.5rem !important;`};
`;

const InsetPageTemplate = ({
  header,
  sider,
  drawer,
  notifications,
  children,
  onOpenDrawer,
  fixedHeight,
  noContainer,
  ...props
}) => {
  const handleOpenDrawer = () => {
    if (onOpenDrawer) onOpenDrawer();
  };

  return (
    <LayoutStyled isHeightFixed={fixedHeight} {...props}>
      <Header>
        <Mobile>
          <HamburgerBtn type="button" onClick={handleOpenDrawer}>
            <img alt="Hamburger Button" src={hamburger} width={20} />
          </HamburgerBtn>
        </Mobile>

        <Desktop>
          <Link to="/">
            <Mobile>
              <LogoSmall alt="Center Logo" src={logoSmall} height={24} />
            </Mobile>
            <Desktop>
              <Logo alt="Center Logo" src={logoBig} height={32} />
            </Desktop>
          </Link>
          <HeaderMenu>{header}</HeaderMenu>
        </Desktop>
      </Header>

      <Mobile>{drawer}</Mobile>
      <Wrapper>
        {sider && (
          <Desktop>
            <Sider
              width={224}
              defaultCollapsed
              breakpoint="md"
              collapsedWidth={0}
            >
              {sider}
            </Sider>
          </Desktop>
        )}
        <Content noContainer={noContainer} isHeightFixed={fixedHeight}>
          {children}
        </Content>
        {notifications}
      </Wrapper>
    </LayoutStyled>
  );
};

InsetPageTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  sider: PropTypes.node,
  drawer: PropTypes.node,
  notifications: PropTypes.node,
  children: PropTypes.any.isRequired,
  onOpenDrawer: PropTypes.func,
  fixedHeight: PropTypes.bool,
  noContainer: PropTypes.bool,
};

InsetPageTemplate.defaultProps = {};

export default InsetPageTemplate;
