// Center UI Colors
module.exports = {
  navy: '#001c3d',
  navy_1: '#073561',
  navy_2: '#1a4473',
  navy_3: '#627897',
  navy_4: '#b9c4d3',
  navy_alpha_40: 'rgba(0, 28, 61, 0.4)', // navy @ 40% opacity
  gray: '#849099',
  gray_2: '#b9c4d3',
  gray_3: '#e2e3ea',
  gray_4: '#e4e7ed',
  gray_5: '#e6e6e6',
  gray_6: '#f0f0f0',
  gray_7: '#f8f8fa',
  red: '#9B283C',
  green: '#00a597',
  yellow: '#fdb913',
  yellow_50: 'rgba(253, 185, 19, 0.5)',
  blue: '#004fe0',
  blue_1: '#0a56a5',
  magenta: '#00a597',
  white: '#fff',
};
