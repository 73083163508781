export const AUTH_DATE_LABEL = 'Transaction Date';
export const POSTED_DATE_LABEL = 'Settle Date';
export const AUTH_ON = 'Transaction on';
export const POSTED_ON = 'Settled on';

export const MIME_TYPES = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  csv: 'text/csv',
  json: 'application/json',
};

export const EMAIL_REMINDER_TYPES = {
  delinquent: 'delinquent',
  pendingApproval: 'pendingApproval',
  unsubmitted: 'unsubmitted',
};
