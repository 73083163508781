const Colors = require('./colors');
const Vars = require('./variables');

module.exports = () => ({
  // Colors
  '@primary-color': Vars.PRIMARY_COLOR,
  '@success-color': Vars.SUCCESS_COLOR,
  '@error-color': Vars.ERROR_COLOR,
  '@warning-color': Vars.WARNING_COLOR,

  // Font
  '@font-family': 'Gotham SSm A, Gotham SSm B, Helvetica, Tahoma, sans-serif',
  '@font-size-lg': '14px',

  // Layout
  '@layout-body-background': '#f8f8fA',
  '@layout-header-background': Vars.PRIMARY_COLOR,

  // Components
  '@text-color': Vars.PRIMARY_COLOR,
  '@heading-color': Vars.PRIMARY_COLOR,
  '@border-radius-base': '1px',
  '@border-color-base': Colors.gray_3,

  // Button
  '@btn-font-size-lg': '14px',

  // Shadow
  '@shadow-color': 'rgba(0, 0, 0, .1)',
  '@box-shadow-base': '@shadow-1-down',
  '@shadow-1-up': '0 2px 10px @box-shadow-base',
  '@shadow-1-down': '0 2px 10px @shadow-color',
  '@shadow-1-left': '-2px 0 10px @shadow-color',
  '@shadow-1-right': '2px 0 10px @shadow-color',

  // Card
  '@card-padding-wider': '24px',

  // Items
  '@item-hover-bg': Colors.gray_5,
  '@item-active-bg': Colors.gray_5,

  // Table
  '@table-padding-vertical': '12px',

  // Input
  '@input-placeholder-color': Colors.gray_2,
  '@input-height-lg': '36px',

  '@disabled-bg': '#EDEFF4',

  '@modal-mask-bg': `fade(${Colors.gray_2}, 30%)`,
});
