import React from 'react';

const wrapReactComponentToIgnoreSomeProps = (component, propsToOmit = {}) => {
  const newComponent = props => {
    const newProps = Object.entries(props).reduce(
      (acc, [propName, propValue]) => {
        if (propName in propsToOmit && propsToOmit[propName] === true) {
          return acc;
        }

        return {
          ...acc,
          [propName]: propValue,
        };
      },
      {},
    );

    return React.createElement(component, newProps);
  };

  return newComponent;
};

/**
 * Usage: sansProps(styled, { propNotInDom: true })('div')``;
 * @param {Function} styled
 * @param {Object} propsToOmit
 * @return {Function}
 */
export const sansProps = (styled, propsToOmit = {}) => {
  const _styled = (component, ...args) => {
    const componentSansProps = wrapReactComponentToIgnoreSomeProps(
      component,
      propsToOmit,
    );

    return styled(componentSansProps, ...args);
  };

  return _styled;
};
