import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'center-ui';
import Color from 'center-ui/themes/colors';
import { getHumanizedDate } from '../../utils';
import NotificationAvatar from './NotificationAvatar';

const { Item } = List;

const AlertsItem = props => {
  const { ID, isRead, text, sentDateTime, subjectCode, meta } = props;

  const ReadIndicator = styled.div`
    position: absolute;
    top: 0.5rem;
    left: 0;
    color: ${Color.blue};
    font-size: 1.875rem;
  `;

  const ItemStyled = styled(Item)`
    position: relative;
    :last-of-type {
      margin-bottom: 3.5rem;
    }
    h4 {
      font-weight: 400 !important;
    }
    .ant-list-item-meta-avatar {
      margin-right: 0 !important;
    }
    .ant-list-item-meta-content {
      flex: 1 0 !important;
    }
  `;

  const ItemContent = styled(Item.Meta)`
    margin-left: 1.6rem;
  `;

  return (
    <ItemStyled id={ID}>
      <ItemContent
        avatar={<NotificationAvatar subjectCode={subjectCode} meta={meta} />}
        title={text}
        description={getHumanizedDate(sentDateTime)}
      />
      {!isRead && <ReadIndicator>•</ReadIndicator>}
    </ItemStyled>
  );
};

AlertsItem.propTypes = {
  ID: PropTypes.string,
  isRead: PropTypes.bool,
  text: PropTypes.string,
  sentDateTime: PropTypes.string,
  subjectCode: PropTypes.string,
  meta: PropTypes.string,
};

export default AlertsItem;
