import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Dropdown, Menu } from 'center-ui';
import { withRouter } from 'react-router-dom';
import Colors from 'center-ui/themes/colors';
import withAuthUser from 'core-ui/components/hoc/withUserAuth';
import API from '../../services/rest/api';
import * as Routes from '../../utils/routes';

const Wrapper = styled(Dropdown)`
  display: inline-flex;
  height: 4rem;
`;

const UserMenu = props => {
  const {
    authUser: {
      fullName,
      organization: { name },
    },
    location,
    history,
  } = props;
  const handleMenuItemClicked = item => {
    switch (item.key) {
      case 'logout':
        API.logout();
        break;
      case 'help':
        break;
      case 'change-password':
        history.push(`${Routes.CHANGE_PASSWORD}?origin=${location.pathname}`);
        break;
      default:
        break;
    }
  };

  const getMenu = () => (
    <Menu onClick={item => handleMenuItemClicked(item)}>
      <Menu.Item id="change-password-link" key="change-password">
        Change Password
      </Menu.Item>
      <Menu.Item id="help-link" key="help">
        <a
          href={Routes.CONTACT_SUPPORT}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Help</span>
        </a>
      </Menu.Item>
      <Menu.Item id="logout-link" key="logout">
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper overlay={getMenu()} trigger={['hover']}>
      <span id="user-menu">
        <Avatar
          name={fullName}
          label={name}
          header={fullName}
          ellipsis={{ length: 15 }}
          styleAvatar={{ color: Colors.white, borderColor: Colors.white }}
          styleHeader={{ color: Colors.white }}
          styleLabel={{ color: Colors.white }}
        />
      </span>
    </Wrapper>
  );
};

UserMenu.propTypes = {
  authUser: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(withAuthUser(UserMenu));
