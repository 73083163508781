import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Spin } from 'center-ui';
import AuthUserContext from 'core-ui/components/providers/AuthUserContext';
import ErrorPage from 'core-ui/components/pages/ErrorPage';
import { userHasFeatures } from 'core-ui/utils';
import * as Routes from '../utils/routes';
import { isUserInRoles } from '../common/roles';
import {
  COST_CENTER_FRAGMENT,
  EXPENSE_TYPE_FRAGMENT,
} from '../services/graphql/fragments';

const GET_ME = gql`
  {
    me {
      ID
      fullName
      roles
      loginID
      delegateOf {
        ID
        avatarURI
        firstName
        fullName @client
        lastName
      }
      cardItems {
        ID
        statusCode
        last4
        typeLabel
      }
      costCenters {
        ID
      }
      toggles
      organization {
        ID
        name
        timeZone
        createdOn
        currencyCode
        shippingAddress {
          address1
          city
          state
          postalCode
          country
        }
        cardPrograms {
          ID
          name
          active
          issueCards
        }
      }
    }
    users(limit: -1) {
      items {
        ID
        fullName
      }
    }
    costCenters {
      ...CostCenterFragment
    }
    expenseTypes(showDeleted: true) @connection(key: "expenseTypes") {
      ...ExpenseTypeFragment
    }
  }
  ${COST_CENTER_FRAGMENT}
  ${EXPENSE_TYPE_FRAGMENT}
`;

const SpinStyled = styled(Spin)`
  display: flex !important;
  flex: 1 !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 100% !important;
`;

const BaseLayout = ({ children, authority, featureFlags }) => {
  const { data = {}, loading, error } = useQuery(GET_ME, {
    onCompleted: res => {
      moment.tz.setDefault(res.me.organization.timeZone);
    },
  });
  if (error) return <ErrorPage />;
  if (loading) return <SpinStyled spinning size="large" />;
  const isUserAuthorized = isUserInRoles(data.me.roles, authority);
  const hasFeatures = userHasFeatures(data.me.toggles, featureFlags);
  const hasAccess = isUserAuthorized && hasFeatures;
  if (hasAccess) {
    return (
      <AuthUserContext.Provider value={data.me}>
        {children(data)}
      </AuthUserContext.Provider>
    );
  }
  return <Redirect exact to={Routes.HOME} />;
};

BaseLayout.propTypes = {
  children: PropTypes.func,
  authority: PropTypes.array,
  featureFlags: PropTypes.array,
};

export default BaseLayout;
