import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _forEach from 'lodash/forEach';
import DropZone from 'react-dropzone';
import Colors from 'center-ui/themes/colors';
import { Notifier } from 'center-ui';
import { generateRandomString } from 'center-ui/utils';
import API from '../../services/rest/api';
import { getUploadMimeTypes, getIsPDF } from '../../utils';

import dragDropIcon from '../../assets/imgs/drag-drop.svg';

const DropZoneStyled = styled(DropZone)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DropZoneMaskWrapper = styled.div`
  position: fixed;
  width: 570px;
  height: 100%;
  right: 0;
  top: 0;
`;

const DropZoneMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.gray_2};
  opacity: 0.4;
`;

const DragDropImg = styled.img`
  position: absolute;
  top: 0;
  left: calc(50% - 62px);
  bottom: 0;
  margin: auto;
`;

const ReceiptBinDropZone = ({
  delegateOf,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
  children,
}) => {
  const [isDragging, setDragging] = useState(false);

  const handleDrop = files => {
    setDragging(false);
    if (files && files.length > 0) {
      _forEach(files, file => {
        const isPdf = getIsPDF(file.type);
        const tempFileID = isPdf
          ? file.name
          : generateRandomString('uploadImage');
        onUploadStart({ fileID: tempFileID, type: file.type });

        API.uploadReceipt(file, null, null, delegateOf)
          .then(({ fileID, URI }) => {
            onUploadSuccess({ file, URI, fileID, tempFileID });
          })
          .catch(err => {
            Notifier.notification({
              type: 'error',
              message: 'Upload Receipt',
              description: err.message,
            });
            onUploadError({ fileID: tempFileID });
          });
      });
    } else {
      Notifier.notification({
        type: 'error',
        message: 'Upload Receipt',
        description: 'Only jpeg, png, gif and pdf files under 25MB are allowed',
      });
    }
  };

  const renderDropZoneMask = () =>
    isDragging ? (
      <DropZoneMaskWrapper>
        <DropZoneMask />
        <DragDropImg alt="Drag & Drop receipts zone" src={dragDropIcon} />
      </DropZoneMaskWrapper>
    ) : null;

  return (
    <DropZoneStyled
      data-testid="drop-zone"
      multiple
      disableClick
      accept={getUploadMimeTypes()}
      maxSize={25000000}
      onDrop={handleDrop}
      onDragEnter={() => {
        setDragging(true);
      }}
      onDragLeave={() => {
        setDragging(false);
      }}
    >
      {({ open }) => (
        <>
          {children({ openFileDialog: open })}
          {renderDropZoneMask()}
        </>
      )}
    </DropZoneStyled>
  );
};

ReceiptBinDropZone.propTypes = {
  delegateOf: PropTypes.string,
  children: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
};

export default ReceiptBinDropZone;
