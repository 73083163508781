import React from 'react';
import styled from 'styled-components';
import { Spin as AntSpin } from 'antd';
import Colors from '../../themes/colors';

const Spin = styled(({ blur, bordered, ...props }) => (
  <AntSpin data-testid="loading-spin" {...props} />
))`
  border: ${({ bordered }) =>
    bordered ? `1px solid ${Colors.gray_6}` : 'none'} !important;
  max-height: 100% !important;
  background-color: ${({ blur }) =>
    blur ? 'transparent' : 'white !important'};
`;

export default Spin;
