import gql from 'graphql-tag';
import {
  ERP_CONFIGURATION_FRAGMENT,
  EXPENSE_SPLITS_FRAGMENT,
  POLICY_FORM_FRAGMENT,
  EVENTS_FRAGMENT,
  COST_CENTER_FRAGMENT,
  EXPENSE_TYPE_FRAGMENT,
  GENERAL_LEDGER_ACCOUNT_FRAGMENT,
} from './fragments';

export const SET_AUTH = gql`
  mutation setAuth($isAuthenticated: Auth) {
    setAuth(isAuthenticated: $isAuthenticated) @client
  }
`;

export const SET_CARD_STATUS = gql`
  mutation setCardStatus($ID: String, $statusCode: String) {
    setCardStatus(ID: $ID, statusCode: $statusCode)
  }
`;

export const TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL = gql`
  mutation setShowAppNotificationsPanel($visible: Boolean) {
    setShowAppNotificationsPanel(visible: $visible) @client
  }
`;

export const TOGGLE_SHOW_RECEIPTS_BIN_PANEL = gql`
  mutation setShowReceiptsBinPanel($visible: Boolean) {
    setShowReceiptsBinPanel(visible: $visible) @client
  }
`;

export const TOGGLE_SHOW_RECEIPT = gql`
  mutation setShowReceipt($visible: Boolean, $receiptViewImagesData: String) {
    setShowReceipt(
      visible: $visible
      receiptViewImagesData: $receiptViewImagesData
    ) @client
  }
`;

export const ATTACH_RECEIPTS_TO_EXPENSE = gql`
  mutation attachReceiptsToExpense(
    $expenseId: ID!
    $fileIds: [ID!]
    $userId: ID
    $delegateOf: ID
  ) {
    attachReceiptsToExpense(
      expenseId: $expenseId
      fileIds: $fileIds
      userId: $userId
      delegateOf: $delegateOf
    ) {
      fileID
      URI
      type
      uploadDate
    }
  }
`;

export const DETACH_RECEIPTS_FROM_EXPENSE = gql`
  mutation detachReceiptsFromExpense(
    $expenseId: ID!
    $fileIds: [ID!]
    $spenderId: ID
    $delegateOf: ID
  ) {
    detachReceiptsFromExpense(
      expenseId: $expenseId
      fileIds: $fileIds
      spenderId: $spenderId
      delegateOf: $delegateOf
    ) {
      fileID
      URI
      type
      uploadDate
    }
  }
`;

export const UPDATE_EXPENSE_STATUS = gql`
  mutation updateExpenseStatus($id: ID!, $status: String!, $delegateOf: ID) {
    updateExpenseStatus(id: $id, status: $status, delegateOf: $delegateOf) {
      ID
      status
    }
  }
`;

export const ADD_COMMENT_TO_EXPENSE = gql`
  mutation addCommentToExpense($id: ID!, $comment: String!) {
    addCommentToExpense(id: $id, comment: $comment) {
      values
      timeStamp
      type
    }
  }
`;

export const UPDATE_HAS_RECEIPTS = gql`
  mutation updateHasReceipts($id: ID!, $input: ExpenseInput!, $delegateOf: ID) {
    updateExpense(id: $id, input: $input, delegateOf: $delegateOf) {
      ID
      hasReceipts
      status
      policyForm {
        fields {
          custom
          hidden
          values
          description
          property
          type
          required
        }
      }
    }
  }
`;

export const getUpdateExpenseMutation = (fields = '') => {
  const UPDATE_EXPENSE = gql`
    mutation updateExpense($id: ID!, $input: ExpenseInput!, $delegateOf: ID) {
      updateExpense(id: $id, input: $input, delegateOf: $delegateOf) {
        ID
        ${fields}
        events {
        ...EventFragment
        }
      }
    }
    ${EVENTS_FRAGMENT}
  `;
  return UPDATE_EXPENSE;
};

export const getUpdateSplitMutation = (fields = '') => {
  const UPDATE_EXPENSE = gql`
    mutation updateExpense($id: ID!, $input: ExpenseInput!, $delegateOf: ID) {
      updateExpense(id: $id, input: $input, delegateOf: $delegateOf) {
        ID
        ${fields}
        hasSplits
        policyForm {
          ...PolicyFormFragment
        }
        splits {
          ...ExpenseSplitsFragment
        }
        status
      }
    }
    ${POLICY_FORM_FRAGMENT}
    ${EXPENSE_SPLITS_FRAGMENT}
  `;
  return UPDATE_EXPENSE;
};

export const getUpdateExpensesMutation = (fields = '') => {
  const UPDATE_EXPENSE = gql`
    mutation updateExpenses($ids: [ID!], $input: ExpenseInput!) {
      updateExpenses(ids: $ids, input: $input) {
        ID
        ${fields}
      }
    }
  `;
  return UPDATE_EXPENSE;
};

export const SET_ASYNC_JOBS = gql`
  mutation setAsyncJobs($asyncJobsData: JSON!) {
    setAsyncJobs(asyncJobsData: $asyncJobsData) @client
  }
`;

export const SET_COMPONENT_STATE = gql`
  mutation setComponentState($id: String!, $state: JSON!) {
    setComponentState(id: $id, state: $state) @client
  }
`;

export const DELETE_OUT_OF_POCKET_EXPENSE = gql`
  mutation deleteOutOfPocket($id: ID!, $delegateOf: ID) {
    deleteOutOfPocket(id: $id, delegateOf: $delegateOf) {
      ID
    }
  }
`;

export const DELETE_EXPENSE_EXPORT = gql`
  mutation deleteExpenseExport($id: ID!) {
    deleteExpenseExport(id: $id) {
      ID
    }
  }
`;

export const APPROVE_ALL_EXPENSES = gql`
  mutation approveAllExpenses {
    approveAllExpenses {
      ID
    }
  }
`;

export const ARCHIVE_ALL_EXPENSES = gql`
  mutation archiveAllExpenses {
    archiveAllExpenses {
      ID
    }
  }
`;

export const CREATE_EXPENSE_EXPORT = gql`
  mutation createExpenseExport(
    $name: String
    $start: AWSDateTime!
    $end: AWSDateTime!
  ) {
    createExpenseExport(name: $name, start: $start, end: $end) {
      ID
    }
  }
`;

export const CREATE_EXPENSE_EXPORT_CHANGES = gql`
  mutation createExpenseExportChanges($expenseExportId: ID!) {
    createExpenseExportChanges(expenseExportId: $expenseExportId) {
      ID
    }
  }
`;

export const CREATE_COST_CENTER = gql`
  mutation createCostCenter($input: CostCenterInput!) {
    createCostCenter(input: $input) {
      ...CostCenterFragment
    }
  }
  ${COST_CENTER_FRAGMENT}
`;

export const UPDATE_COST_CENTER = gql`
  mutation updateCostCenter($id: ID!, $input: CostCenterInput!) {
    updateCostCenter(id: $id, input: $input) {
      ...CostCenterFragment
    }
  }
  ${COST_CENTER_FRAGMENT}
`;

export const CREATE_EXPENSE_TYPE = gql`
  mutation createExpenseType($input: ExpenseTypeInput!) {
    createExpenseType(input: $input) {
      ...ExpenseTypeFragment
    }
  }
  ${EXPENSE_TYPE_FRAGMENT}
`;

export const UPDATE_EXPENSE_TYPE = gql`
  mutation updateExpenseType($id: ID!, $input: ExpenseTypeInput!) {
    updateExpenseType(id: $id, input: $input) {
      ...ExpenseTypeFragment
    }
  }
  ${EXPENSE_TYPE_FRAGMENT}
`;

export const CREATE_GENERAL_LEDGER_ACCOUNT = gql`
  mutation createGeneralLedgerAccount($input: GeneralLedgerAccountInput!) {
    createGeneralLedgerAccount(input: $input) {
      ...GeneralLedgerAccountFragment
    }
  }
  ${GENERAL_LEDGER_ACCOUNT_FRAGMENT}
`;

export const UPDATE_GENERAL_LEDGER_ACCOUNT = gql`
  mutation updateGeneralLedgerAccount(
    $id: ID!
    $input: GeneralLedgerAccountInput!
  ) {
    updateGeneralLedgerAccount(id: $id, input: $input) {
      ...GeneralLedgerAccountFragment
    }
  }
  ${GENERAL_LEDGER_ACCOUNT_FRAGMENT}
`;

export const SET_ERP_CONFIGURATION = gql`
  mutation erpConfiguration($input: ERPConfigurationInput) {
    erpConfiguration(input: $input) {
      ...ERPConfigurationFragment
    }
  }
  ${ERP_CONFIGURATION_FRAGMENT}
`;
