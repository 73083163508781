import React from 'react';
import PropTypes from 'prop-types';

class RowPure extends React.PureComponent {
  render = () => {
    const { children, ...rest } = this.props;
    return <tr {...rest}>{children}</tr>;
  };
}

RowPure.propTypes = {
  children: PropTypes.any,
};

export default RowPure;
