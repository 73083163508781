import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form as AntForm } from 'antd';
import { getElementType } from '../../utils';

// eslint-disable-next-line react/prop-types
let Element = ({ as, ...rest }, ref) => {
  const ElementType = getElementType(as, { as });

  return (
    <span ref={ref}>
      <ElementType {...rest} />
    </span>
  );
};

const Form = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 0.5rem !important;
  }
  &.ant-form-vertical {
    .ant-form-item-label {
      line-height: 1.5rem;
      padding: 0;
    }
  }
  .ant-form-item-label {
    label {
      font-size: 0.75rem;
      letter-spacing: -0.0125rem;
    }

    .ant-form-item-required::before {
      content: '' !important;
      margin: 0 !important;
    }

    .ant-form-item-required::after {
      display: ${({ hideRequiredMark }) =>
        hideRequiredMark ? 'none' : 'inline-block'};
      margin-right: 4px;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
`;

const LabelDisabled = styled.label`
  opacity: 0.3;
`;

Element = forwardRef(Element);

const Field = ({
  as,
  element,
  options,
  item: { label, form, ...otherItem },
  ...rest
}) => {
  const { id, ...otherOptions } = options;
  const { disabled } = element;

  return (
    <Form.Item
      {...rest}
      label={disabled ? <LabelDisabled>{label}</LabelDisabled> : label}
      colon={false}
      {...otherItem}
    >
      {form.getFieldDecorator(id, { ...otherOptions })(
        <Element as={as} {...element} />,
      )}
    </Form.Item>
  );
};

Field.propTypes = {
  as: PropTypes.any.isRequired,
  element: PropTypes.object,
  item: PropTypes.object,
  options: PropTypes.shape({
    id: PropTypes.string,
    rules: PropTypes.array,
  }),
};

Form.Field = Field;

Form.defaultProps = {
  layout: 'vertical',
};

export default Form;
