import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage';

class ErrorBoundary extends React.PureComponent {
  state = { hasError: false };

  componentDidCatch(error, info) {
    const { onErrorCaught } = this.props;
    // Display fallback UI
    this.setState({ hasError: true });
    if (onErrorCaught) onErrorCaught(error, info);
  }

  render() {
    const { placeHolder, children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return placeHolder || <ErrorPage />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  placeHolder: PropTypes.any,
  onErrorCaught: PropTypes.func,
};

export default withRouter(ErrorBoundary);
