import React from 'react';
import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import Colors from '../../themes/colors';

const getTypeStyle = type => {
  switch (type) {
    case 'primary':
      return `color: ${Colors.white} !important;`;
    case 'cancel':
      return `border: 1px solid ${Colors.gray_4} !important;
              color: ${Colors.gray} !important;`;
    case 'secondary':
      return `border: 1px solid ${Colors.gray_4} !important;
              background-color: transparent !important
              color: ${Colors.blue} !important;`;
    case 'tertiary':
      return `border: 1px solid ${Colors.gray_2} !important;
              color: ${Colors.navy} !important;`;
    case 'danger':
      return `background-color: ${Colors.red} !important;
              color: ${Colors.white} !important;
              border: 1px solid ${Colors.red} !important;`;
    case 'link':
      return `background-color: transparent !important;
              border: none !important;
              color: ${Colors.gray} !important;`;
    default:
      return `border: 1px solid ${Colors.navy} !important;
              color: ${Colors.navy} !important;`;
  }
};

const getHoverFocusTypeStyle = type => {
  switch (type) {
    case 'primary':
      return `opacity: 0.8 !important;`;
    case 'cancel':
      return `border: 1px solid ${Colors.gray} !important;
              color: ${Colors.gray} !important;`;
    case 'secondary':
      return `border: 1px solid ${Colors.blue} !important;`;
    case 'tertiary':
      return `border: 1px solid ${Colors.navy} !important;
              opacity: 1 !important;
              color: ${Colors.navy} !important;`;
    default:
      return `opacity: 0.7 !important;`;
  }
};

const getActiveTypeStyle = type => {
  switch (type) {
    case 'primary':
      return `background-color: ${Colors.navy} !important;
              border: 1px solid ${Colors.navy} !important;
              opacity: 1 !important;`;
    case 'cancel':
      return `background-color: ${Colors.gray_6} !important;`;
    case 'secondary':
      return `border: 1px solid ${Colors.navy} !important;
              color: ${Colors.navy} !important;`;
    default:
      return `opacity: 1 !important;`;
  }
};

const getDisableStyle = type => {
  switch (type) {
    case 'default':
      return `background-color: transparent !important`;
    default:
      return null;
  }
};

const ButtonStyled = styled(AntButton)`
  letter-spacing: -0.0187rem;
  transition: none !important;
  border-radius: ${({ shape }) => (!shape ? '2px' : 'auto')} !important;
  box-shadow: none !important;
  .anticon {
    vertical-align: text-top !important;
  }

  :not(.ant-btn-background-ghost):not([disabled]) {
    ${({ type }) => getTypeStyle(type)};
  }

  :hover:not([disabled]) {
    ${({ type }) => getHoverFocusTypeStyle(type)};
  }

  :disabled {
    ${({ type }) => getDisableStyle(type)};
  }

  :focus {
    ${({ type }) => getHoverFocusTypeStyle(type)};
  }

  :active {
    ${({ type }) => getActiveTypeStyle(type)};
  }
`;

const Button = props => <ButtonStyled {...props} />;

export default Button;
