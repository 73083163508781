import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styleConstructor } from '../../utils';

const Wrapper = styled.div`
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  justify-content: space-between;
`;

const Left = styled.span`
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const Right = styled.span`
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const Inline = props => {
  const { className, flex, left, right, leftStyle, rightStyle } = props;
  return (
    <Wrapper className={className} flex={flex}>
      <Left styling={leftStyle}>{left}</Left>
      <Right styling={rightStyle}>{right}</Right>
    </Wrapper>
  );
};

Inline.propTypes = {
  className: PropTypes.string,
  flex: PropTypes.bool,
  left: PropTypes.any,
  right: PropTypes.any,
  leftStyle: PropTypes.object,
  rightStyle: PropTypes.object,
};

Inline.defaultProps = {
  flex: true,
};

export default Inline;
