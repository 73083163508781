import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Drawer } from 'center-ui';
import API from '../../services/rest/api';
import { getMenuDataWithChildren } from '../../common/menu';
import NavMenu from '../NavMenu';
import AppNotificationsIndicator from '../AppNotificationsIndicator';
import { UserRoles } from '../../common/roles';
import * as Routes from '../../utils/routes';
import logo from '../../assets/imgs/center-logo-small-blue.svg';

const NavMenuStyled = styled(NavMenu)`
  .ant-menu-submenu-title,
  > .ant-menu-item {
    padding-left: 0 !important;
    font-size: 1.125rem !important;
    :after {
      border-right: none !important;
    }
  }
  .ant-menu-item {
    :after {
      border-right: none !important;
    }
  }

  .ant-menu-item:not(:last-child) {
    margin-bottom: 4px !important;
  }
`;

class DrawerMenu extends React.PureComponent {
  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  getMenuData = () => {
    const { userFullname, location } = this.props;
    const menuData = getMenuDataWithChildren();
    menuData.push({
      id: 'inbox-link-panel',
      name: <AppNotificationsIndicator onClick={this.handleClose} />,
      icon: null,
      path: null,
      authority: [UserRoles.ALL],
    });
    menuData.push({
      id: 'user-link-drawer',
      name: userFullname,
      icon: null,
      path: null,
      authority: [UserRoles.ALL],
      children: [
        {
          id: 'change-password-link',
          name: 'Change Password',
          icon: null,
          path: `${Routes.CHANGE_PASSWORD}?origin=${location.pathname}`,
          authority: [UserRoles.ALL],
        },
        {
          id: 'help-link',
          name: 'Help',
          icon: null,
          path: Routes.CONTACT_SUPPORT,
          target: '_blank',
          authority: [UserRoles.ALL],
        },
        {
          id: 'logout-link',
          name: 'Log out',
          icon: null,
          path: null,
          onClick: API.logout,
          authority: [UserRoles.ALL],
        },
      ],
    });
    return menuData;
  };

  render() {
    const { location, visible } = this.props;
    return (
      <Drawer
        title={<img alt="Logo" src={logo} width={30} />}
        placement="left"
        width={320}
        closable
        onClose={this.handleClose}
        visible={visible}
      >
        <NavMenuStyled
          mode="inline"
          menuData={this.getMenuData()}
          location={location}
          onLinkClick={this.handleClose}
        />
      </Drawer>
    );
  }
}

DrawerMenu.propTypes = {
  userFullname: PropTypes.string,
  location: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DrawerMenu;
