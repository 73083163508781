import {
  isSupported,
  CookieStorage,
  MemoryStorage,
} from 'local-storage-fallback';

// eslint-disable-next-line import/no-mutable-exports
let storage = null;

if (isSupported('sessionStorage')) {
  storage = window.sessionStorage;
} else if (isSupported('localStorage')) {
  storage = window.localStorage;
} else if (isSupported('cookieStorage')) {
  storage = new CookieStorage();
} else {
  storage = new MemoryStorage();
}

export default storage;
