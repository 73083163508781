import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { Menu } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import { userHasFeatures, urlToList } from 'core-ui/utils';
import withUserAuth from 'core-ui/components/hoc/withUserAuth';
import { isUserInRoles } from '../../common/roles';

const { SubMenu } = Menu;

const MenuStyled = styled(Menu)`
  background: transparent !important;
  border: none !important;
`;

const MenuItemStyled = styled(Menu.Item)`
  padding: 0 1.5rem !important;
  .ant-menu-item {
    padding: 0 1.5rem !important;
    margin: 0 !important;
    transition: 0 !important;
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    background-color: ${Colors.gray_5}!important;
  }
`;

export const getFlatMenuKeys = menu =>
  menu.reduce((keys, item) => {
    if (item.path) {
      keys.push(item.path);
      if (item.children) {
        return keys.concat(getFlatMenuKeys(item.children));
      }
    }
    return keys;
  }, []);

export const getMenuMatchKeys = (flatMenuKeys, paths) =>
  paths.reduce(
    (matchKeys, path) =>
      path &&
      matchKeys.concat(
        flatMenuKeys.filter(item => pathToRegexp(item).test(path)),
      ),
    [],
  );

/**
 * Convert pathname to openKeys
 * /list/search/articles = > ['list','/list/search']
 * @param  props
 */
const getDefaultCollapsedSubMenus = props => {
  const {
    location: { pathname },
  } = props;
  return getMenuMatchKeys(menuKeys, urlToList(pathname));
};

let menuKeys = null;

class NavMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    menuKeys = getFlatMenuKeys(props.menuData);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const {
      location: { pathname },
      openDefault,
    } = props;
    menuKeys = getFlatMenuKeys(props.menuData);
    if (pathname !== state.prevPathname) {
      return {
        selectedKeys: getMenuMatchKeys(menuKeys, urlToList(pathname)),
        openKeys: openDefault ? getDefaultCollapsedSubMenus(props) : [],
        prevPathname: pathname,
      };
    }
    return null;
  }

  conversionPath = path => {
    if (path && (path.indexOf('http') === 0 || path.indexOf('https') === 0)) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };

  getMenuItemPath = item => {
    const itemPath = this.conversionPath(item.path);

    const { id, name, onClick, target } = item;

    // Is it a http link
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          <span>{name}</span>
        </a>
      );
    }

    const { location, onLinkClick } = this.props;
    return (
      <Link
        id={id}
        to={itemPath}
        replace={itemPath === location.pathname}
        onClick={() => {
          if (onClick) onClick();
          if (onLinkClick) onLinkClick();
        }}
      >
        <span>{name}</span>
      </Link>
    );
  };

  getSubMenuOrItem = item => {
    if (item.children && item.children.some(child => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children);
      if (childrenItems && childrenItems.length > 0) {
        return (
          <SubMenu id={item.id} title={item.name} key={item.path}>
            {childrenItems}
          </SubMenu>
        );
      }
      return null;
    }
    return (
      <MenuItemStyled key={item.path || item.id}>
        {item.path || item.onClick ? this.getMenuItemPath(item) : item.name}
      </MenuItemStyled>
    );
  };

  getNavMenuItems = menuData => {
    const { authUser } = this.props;
    if (!menuData) return [];
    return menuData
      .filter(
        item =>
          item.name &&
          isUserInRoles(authUser.roles, item.authority) &&
          (item.disableForFeatureFlags
            ? !userHasFeatures(authUser.toggles, item.disableForFeatureFlags)
            : userHasFeatures(authUser.toggles, item.enableForFeatureFlags)),
      )
      .map(item => this.getSubMenuOrItem(item));
  };

  handleOpenChange = openKeys => {
    this.setState({
      openKeys: [...openKeys],
    });
  };

  render() {
    const {
      menuData,
      location,
      onLinkClick,
      openDefault,
      authUser,
      ...menuProps
    } = this.props;
    if (!menuData) return null;
    const { selectedKeys, openKeys } = this.state;
    return (
      <MenuStyled
        {...menuProps}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        triggerSubMenuAction="hover"
        onOpenChange={this.handleOpenChange}
      >
        {this.getNavMenuItems(menuData)}
      </MenuStyled>
    );
  }
}

NavMenu.propTypes = {
  menuData: PropTypes.array.isRequired,
  location: PropTypes.object,
  openDefault: PropTypes.bool,
  onLinkClick: PropTypes.func,
  authUser: PropTypes.object,
};

NavMenu.defaultProps = {
  openDefault: true,
};

export default withUserAuth(NavMenu);
