import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styleConstructor } from '../../utils';
import Colors from '../../themes/colors';

const KpiLabelStyled = styled.div`
  color: ${Colors.gray};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const KpiLabel = ({ className, label, styling }) => (
  <KpiLabelStyled className={className} styling={styling}>
    {label}
  </KpiLabelStyled>
);

KpiLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  styling: PropTypes.object,
};

export default React.memo(KpiLabel);
