import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import _get from 'lodash/get';
import { Desktop, Mobile } from 'core-ui/components/responsive';
import { Badge } from 'center-ui';
import SimpleButton from '../SimpleButton';
import { TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL } from '../../services/graphql/mutations';
import { GET_APP_NOTIFICATIONS_COUNT } from '../../services/graphql/queries';
import { withShowAppNotificationsPanelFlag } from '../../services/graphql/hoc';
import { pollInterval } from '../../utils/appNotifications';
import envelopeIcon from '../../assets/imgs/envelope.svg';

const EnvelopeIcon = styled.button`
  background-color: transparent;
  border: 0;
  margin-right: 2rem;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  :focus {
    outline: none;
  }
`;

// isMobile menu
const InboxButton = styled(SimpleButton)`
  background-color: transparent;
  display: inherit;
  border: 0;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  :focus {
    outline: none;
  }
  text-align: left;
  width: 100%;
`;
const BadgeStyled = styled(Badge)`
  float: right !important;
`;

const AppNotificationsIndicator = props => {
  const { isAppNotificationsPanelVisible, onClick } = props;

  const handleToggleAppNotifications = setShowAppNotificationsPanel => {
    setShowAppNotificationsPanel({
      variables: { visible: !isAppNotificationsPanelVisible },
    });
    if (onClick) onClick();
  };

  const renderWithTogglePanelMutation = (totalCount, showDot) => (
    <Mutation mutation={TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL} ignoreResults>
      {setShowAppNotificationsPanel => (
        <Fragment>
          <Desktop>
            <EnvelopeIcon
              id="app-notifications-indicator"
              onClick={() =>
                handleToggleAppNotifications(setShowAppNotificationsPanel)
              }
            >
              <Badge count={totalCount} dot={showDot}>
                <img alt="Toggle inbox panel icon" src={envelopeIcon} />
              </Badge>
            </EnvelopeIcon>
          </Desktop>
          <Mobile>
            <InboxButton
              onClick={() =>
                handleToggleAppNotifications(setShowAppNotificationsPanel)
              }
            >
              Inbox
              <BadgeStyled
                count={totalCount}
                dot={showDot}
                offset={showDot ? [0, 16] : [0, 10]}
              />
            </InboxButton>
          </Mobile>
        </Fragment>
      )}
    </Mutation>
  );

  return (
    <Query
      query={GET_APP_NOTIFICATIONS_COUNT}
      notifyOnNetworkStatusChange
      fetchPolicy="network-only"
      pollInterval={isAppNotificationsPanelVisible ? 0 : pollInterval}
      // 0 == stop polling
    >
      {({ data = {}, error }) => {
        if (error) {
          console.error(error.message);
        }
        const alertsCount = _get(data, 'alerts.alertsCount', 0);
        const actionsCount = _get(data, 'alerts.actionsCount', 0);
        const totalCount = alertsCount + actionsCount || 0;
        const showDot = alertsCount > 0 && actionsCount === 0;
        return renderWithTogglePanelMutation(totalCount, showDot);
      }}
    </Query>
  );
};

AppNotificationsIndicator.propTypes = {
  isAppNotificationsPanelVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withShowAppNotificationsPanelFlag(AppNotificationsIndicator);
