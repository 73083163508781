import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Notifier } from 'center-ui';
import ErrorBoundary from 'core-ui/components/providers/ErrorBoundary';
import ErrorPage from 'core-ui/components/pages/ErrorPage';
import BaseLayout from './BaseLayout';
import * as Routes from '../utils/routes';
import API from '../services/rest/api';
import { getRouterData } from '../common/router';
import InsetPageTemplate from '../templates/InsetPageTemplate';
import SideNav from '../components/SideNav';
import TopNav from '../components/TopNav';
import AppNotificationsPanel from '../components/AppNotificationsPanel';
import ReceiptsBinPanel from '../components/ReceiptsBinPanel';
import AsyncJobs from '../features/AsyncJobs';
import DrawerMenu from '../components/DrawerMenu';

const routerData = getRouterData();
const routes = () => [
  {
    component: routerData[Routes.HOME].component,
    path: Routes.HOME,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_OVERVIEW].component,
    path: Routes.MYCARD_OVERVIEW,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_EXPENSES].component,
    path: Routes.MYCARD_EXPENSES,
    exact: true,
  },
  {
    component: routerData[Routes.CENTERACCOUNT_SUMMARY].component,
    path: Routes.CENTERACCOUNT_SUMMARY,
    exact: true,
  },
  {
    component: routerData[Routes.CENTERACCOUNT_TRANSACTIONS].component,
    path: Routes.CENTERACCOUNT_TRANSACTIONS,
    exact: true,
  },
  {
    component: routerData[Routes.LINKED_ACCOUNTS].component,
    path: Routes.LINKED_ACCOUNTS,
    exact: true,
  },
  {
    component: routerData[Routes.STATEMENTS].component,
    path: Routes.STATEMENTS,
    exact: true,
  },
  {
    component: routerData[Routes.USER_CREATE].component,
    path: Routes.USER_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.USER_EDIT].component,
    path: Routes.USER_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.USER_INFO].component,
    path: Routes.USER_INFO,
    exact: true,
  },
  {
    component: routerData[Routes.USERS].component,
    path: Routes.USERS,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS].component,
    path: Routes.COST_CENTERS,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS_INFO].component,
    path: Routes.COST_CENTERS_INFO,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS_CREATE].component,
    path: Routes.COST_CENTERS_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS_EDIT].component,
    path: Routes.COST_CENTERS_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_TYPES].component,
    path: Routes.EXPENSE_TYPES,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_TYPES_CREATE].component,
    path: Routes.EXPENSE_TYPES_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_TYPES_EDIT].component,
    path: Routes.EXPENSE_TYPES_EDIT,
  },
  {
    component: routerData[Routes.GENERAL_LEDGER].component,
    path: Routes.GENERAL_LEDGER,
    exact: true,
  },
  {
    component: routerData[Routes.GENERAL_LEDGER_CREATE].component,
    path: Routes.GENERAL_LEDGER_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.GENERAL_LEDGER_EDIT].component,
    path: Routes.GENERAL_LEDGER_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.INTEGRATIONS].component,
    path: Routes.INTEGRATIONS,
    exact: false,
  },
  {
    component: routerData[Routes.EXPENSE_HUB].component,
    path: Routes.EXPENSE_HUB,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_DETAILS_VIEW].component,
    path: Routes.EXPENSE_DETAILS_VIEW,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_DETAILS_REVIEW].component,
    path: Routes.EXPENSE_DETAILS_REVIEW,
    exact: true,
  },
  {
    component: routerData[Routes.MY_MILEAGE_EDIT].component,
    path: Routes.MY_MILEAGE_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.MY_OUT_OF_POCKET_EDIT].component,
    path: Routes.MY_OUT_OF_POCKET_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.REPORTS].component,
    path: Routes.REPORTS,
    exact: true,
  },
  {
    component: routerData[Routes.INSIGHTS].component,
    path: Routes.INSIGHTS,
    exact: false,
  },
  {
    component: routerData[Routes.RULES_CUSTOM_APPROVAL].component,
    path: Routes.RULES_CUSTOM_APPROVAL,
    exact: false,
  },
  // {
  //   component: routerData[Routes.RULES_CUSTOM_APPROVAL_CREATE].component,
  //   path: Routes.RULES_CUSTOM_APPROVAL_CREATE,
  //   exact: false,
  // },
  // {
  //   component: routerData[Routes.RULES_CUSTOM_APPROVAL_EDIT].component,
  //   path: Routes.RULES_CUSTOM_APPROVAL_EDIT,
  //   exact: false,
  // },
];

class InsetLayout extends React.PureComponent {
  state = { isDrawerOpen: false };

  handleAsyncCallback = payload => {
    Notifier.notification(payload);
  };

  handleOpenDrawer = () => {
    this.setState({ isDrawerOpen: true });
  };

  handleCloseDrawer = () => {
    this.setState({ isDrawerOpen: false });
  };

  handleError = (error, info, { me }) => {
    API.logError(API.LogLevel.error, {
      message: 'React error',
      details: {
        error,
        info,
        user: me,
      },
    });
  };

  renderSider = () => {
    const { location } = this.props;
    return <SideNav location={location} />;
  };

  renderNotificationsPanel = () => (
    <>
      <ReceiptsBinPanel />
      <AppNotificationsPanel />
    </>
  );

  renderTopNav = () => {
    const { location } = this.props;
    return <TopNav location={location} />;
  };

  renderDrawer = ({ me }) => {
    const { location } = this.props;
    const { isDrawerOpen } = this.state;
    return (
      <DrawerMenu
        userFullname={me.fullName}
        location={location}
        visible={isDrawerOpen}
        onClose={this.handleCloseDrawer}
      />
    );
  };

  render = () => {
    const {
      showSideNav,
      match,
      fixedHeight,
      noContainer,
      ...rest
    } = this.props;

    return (
      <BaseLayout {...rest}>
        {data => (
          <InsetPageTemplate
            header={this.renderTopNav()}
            sider={showSideNav && this.renderSider()}
            drawer={this.renderDrawer(data)}
            notifications={this.renderNotificationsPanel()}
            onOpenDrawer={this.handleOpenDrawer}
            fixedHeight={fixedHeight}
            noContainer={noContainer}
          >
            <ErrorBoundary
              placeHolder={<ErrorPage />}
              onErrorCaught={(error, info) =>
                this.handleError(error, info, data)
              }
            >
              <Switch>
                {routes(match).map(route => (
                  <Route key={route.path} {...route} />
                ))}
              </Switch>
            </ErrorBoundary>
            <AsyncJobs onAsyncCallback={this.handleAsyncCallback} />
          </InsetPageTemplate>
        )}
      </BaseLayout>
    );
  };
}

InsetLayout.propTypes = {
  match: PropTypes.object,
  component: PropTypes.any,
  location: PropTypes.object,
  showSideNav: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  noContainer: PropTypes.bool,
};

InsetLayout.defaultProps = {
  showSideNav: true,
  fixedHeight: false,
  noContainer: false,
};

export default InsetLayout;
