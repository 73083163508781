import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import DeleteButton from '../DeleteButton';

const ActionBar = styled.div`
  border-top: 1px solid ${Colors.gray_5};
  padding: 1rem;
  text-align: left;
  display: flex;
`;

const AddToExpenseButton = styled(Button)`
  margin-right: 1rem;
`;

const ReceiptBinActionBar = ({
  onAttach,
  onDelete,
  placement,
  attachingReceipt,
  deletingReceipt,
  ...rest
}) => {
  const renderDeleteConfirmation = () => {
    const hasMultipleReceipts = placement === 'drawer';
    return (
      <span>
        Are you sure you want<br />
        {hasMultipleReceipts
          ? 'to remove these receipts'
          : 'to remove this receipt?'}
      </span>
    );
  };

  return (
    <ActionBar {...rest}>
      <AddToExpenseButton
        onClick={onAttach}
        disabled={!onAttach}
        type="primary"
        id={`${placement}-add-receipt-to-expense`}
        size="large"
        loading={attachingReceipt}
      >
        Add to expense
      </AddToExpenseButton>
      <DeleteButton
        onClick={onDelete}
        disabled={!onDelete}
        id={`${placement}-delete-receipt`}
        size="large"
        buttonText="Delete"
        loading={deletingReceipt}
        confirmTitle={renderDeleteConfirmation()}
        normalButton
      />
    </ActionBar>
  );
};

ReceiptBinActionBar.propTypes = {
  onAttach: PropTypes.func,
  onDelete: PropTypes.func,
  placement: PropTypes.oneOf(['drawer', 'modal']).isRequired,
  attachingReceipt: PropTypes.bool,
  deletingReceipt: PropTypes.bool,
};

export default ReceiptBinActionBar;
