import { capitalize, filter, find, forEach } from 'lodash';

export const cardStatusCodesEnums = {
  IssuedInactive: 'IssuedInactive',
  Open: 'Open',
  Lost: 'Lost',
  Stolen: 'Stolen',
  NoWithdrawals: 'NoWithdrawals',
  Closed: 'Closed',
  LostCardNotCaptured: 'LostCardNotCaptured',
  StolenCardNotCaptured: 'StolenCardNotCaptured',
  Inactive: 'Inactive',
  Reissued: 'Reissued',
  FraudBlocked: 'FraudBlocked',
};

const {
  IssuedInactive,
  Open,
  // Lost,
  // Stolen,
  // Closed,
  NoWithdrawals,
  // LostCardNotCaptured,
  // StolenCardNotCaptured,
  Inactive,
  Reissued,
  // FraudBlocked,
} = cardStatusCodesEnums;

const cardReissuedReasonCodeEnums = {
  DAMAGED: 'damaged',
  EXPIRED: 'expired',
  LOST: 'lost',
  STOLEN: 'stolen',
};

// Function to get all active cards, injecting a reissued card if necessary
export const getActiveCards = cards => {
  if (!cards || cards.length === 0) return [];
  const primaryCard = getPrimaryCard(cards);
  const activeCards = filter(
    cards,
    c =>
      c.cardInfo &&
      c.cardInfo.activeState &&
      !(primaryCard && primaryCard.ID === c.ID),
  );
  if (primaryCard && primaryCard.cardInfo.hasReissued) {
    const reissuedCard = {
      ...primaryCard,
      reissued: null,
      statusCode: Reissued,
      statusLabel: 'Awaiting activation',
      cardInfo: {
        ...primaryCard.cardInfo,
        hasReissued: false,
        canUnlock: false,
        isRequested: true,
      },
    };
    return [primaryCard, reissuedCard, ...activeCards];
  }
  if (primaryCard) return [primaryCard, ...activeCards];
  return activeCards;
};

// Find primary card (if exists), and inject props / flags
export const getPrimaryCard = cards => {
  if (!cards || cards.length === 0) return null;
  let primaryCard = null;
  const injectCardProps = card => {
    let hasReissued = false;
    if (card.reissued && card.reissued.reasonCode) {
      const { DAMAGED, EXPIRED } = cardReissuedReasonCodeEnums;
      const {
        reissued: { reasonCode },
      } = card;
      switch (reasonCode) {
        case DAMAGED:
        case EXPIRED:
          hasReissued = true;
          break;
        default:
          break;
      }
    }
    return {
      ...card,
      cardInfo: {
        ...card.cardInfo,
        hasReissued,
        reissuedReason: hasReissued
          ? capitalize(card.reissued.reasonCode)
          : null,
      },
    };
  };
  forEach(cards, card => {
    if (card) {
      const { statusCode } = card;
      if (!statusCode) {
        console.error(
          'Card does not have statusCode to find primary card',
          card,
        );
        return;
      }
      switch (statusCode) {
        case Open:
          primaryCard = injectCardProps(card);
          break;
        case IssuedInactive:
        case Inactive:
        case NoWithdrawals:
          if (!primaryCard) primaryCard = injectCardProps(card);
          break;
        default:
          break;
      }
    }
  });
  return primaryCard;
};

export const getCardProgram = (
  cardPrograms,
  isActive = true,
  canIssueCards = true,
) => {
  if (!cardPrograms || cardPrograms.length === 0) return null;
  const foundProgram = find(
    cardPrograms,
    p => p.active === isActive && p.issueCards === canIssueCards,
  );
  return foundProgram || null;
};

export const validateCardLimitInput = (rule, value, callback) => {
  if (!value || (value < 1 || value > 100000 || value % 1 !== 0)) {
    callback(
      'Card limit cannot exceed $100,000, be less than $1, or contain decimals',
    );
  } else {
    callback();
  }
};

export const isCredit = cardProgram =>
  cardProgram && cardProgram.name.toLowerCase().indexOf('credit') > -1;

export const canIssueCards = (cardPrograms, cards) => {
  const activeProgram = getCardProgram(cardPrograms);
  if (!activeProgram) return false;
  const hasCard = find(
    cards,
    card => card && card.cardProgramInstanceID === activeProgram.ID,
  );

  if (hasCard) return false;

  return true;
};

export const canReissueCards = (cardPrograms, card) => {
  const activeProgram = getCardProgram(cardPrograms);
  if (!activeProgram || !card) return false;
  return card.cardProgramInstanceID === activeProgram.ID;
};

export default {
  getActiveCards,
  getPrimaryCard,
  getCardProgram,
  canIssueCards,
  canReissueCards,
  isCredit,
};
