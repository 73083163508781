import React from 'react';

import Input from '../Input';
import Icon from '../Icon';

class InputPassword extends React.PureComponent {
  state = { showText: false };

  onShowText = () => this.setState(state => ({ showText: !state.showText }));

  render = () => {
    const { showText } = this.state;
    const fieldProps = {
      ...this.props,
      type: showText ? 'text' : 'password',
      suffix: (
        <Icon
          type="eye"
          theme={showText ? 'outlined' : 'filled'}
          onClick={this.onShowText}
        />
      ),
    };
    return <Input {...fieldProps} />;
  };
}

export default InputPassword;
