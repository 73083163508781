import _forEach from 'lodash/forEach';
import _kebabCase from 'lodash/kebabCase';

export { getElementType } from './getElementType';
export { childrenUtils } from './childrenUtils';

export const styleConstructor = params => {
  if (!params) return '';
  if (typeof params !== 'object') {
    console.error('Invalid type supplied to style constructor: ', { params });
    return '';
  }
  let styleString = '';
  const styleParam = [];
  const buildStyleString = (val, key) => {
    if (val) styleParam.push({ [key]: val });
  };
  _forEach(params, buildStyleString);
  if (styleParam.length > 0) {
    const sParams = styleParam.map((param, idx) => {
      const property = Object.keys(styleParam[idx])[0];
      const s = `${_kebabCase(property)}: ${
        styleParam[idx][property]
      } !important;\n`;
      return s;
    });
    styleString = `${sParams.join('')}`;
  }
  return styleString;
};

// Number formatter helpers
export const convertToPennies = value => {
  if (!Number.isFinite(Number(value))) {
    console.error(`Cannot convert value that is not a number: ${value}`);
  }
  const pennyValue = Math.floor((value * 100).toFixed(0));
  if (!Number.isInteger(pennyValue)) {
    console.error(
      `Converted value is not an integer: ${value} => ${pennyValue}`,
    );
  }
  return pennyValue;
};

export const convertToDollars = value => {
  if (!Number.isFinite(Number(value))) {
    console.error(`Cannot convert value that is not a number: ${value}`);
  }
  return value * 0.01;
};

export const makeShortNumber = value => {
  if (Math.abs(value) > 9999) {
    return { number: value / 1000, suffix: 'K' };
  }

  return { number: value, suffix: '' };
};

export const formatNumber = params => {
  const {
    decimalPrecision = 2,
    currencyCode,
    transform,
    style,
    currencyDisplay,
    suffix,
    locales = { nu: navigator.language || navigator.userLanguage },
    shortFormat = false,
    ...otherProps
  } = params;
  const negateSign = params.value < 0 ? '-' : '';
  const value = Math.abs(params.value);
  const precision = shortFormat ? 0 : decimalPrecision;
  const fVal = new Intl.NumberFormat([locales.nu, 'en-US'], {
    style,
    currencyDisplay,
    // TODO Falling back to USD to avoid app crash; reconsider better alternatives
    currency: currencyCode || 'USD',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    ...otherProps,
  });
  // TODO Falling back to USD to avoid app crash; reconsider better alternatives
  if (!currencyCode && style === 'currency')
    console.error('Invalid currency code provided to formatNumber(): ', {
      currencyCode,
    });
  const transformedValue = transform ? transform(value) : value;
  let shortNumber = null;
  if (shortFormat) {
    shortNumber = makeShortNumber(transformedValue);
  }
  return shortFormat
    ? `${negateSign}${fVal.format(shortNumber.number)}${
        shortNumber.suffix
      } ${suffix || ''}`
    : `${negateSign}${fVal.format(transformedValue)} ${suffix || ''}`;
};

export const generateRandomString = (prefix = 'random') =>
  `${prefix}-${Math.random()
    .toString(36)
    .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)}`;
