import gql from 'graphql-tag';
import {
  PAGINATION_FRAGMENT,
  EVENTS_FRAGMENT,
  AUTO_DEPOSIT_FRAGMENT,
  ERP_CONFIGURATION_FRAGMENT,
  EXPENSE_FRAGMENT,
  EXPENSE_SPLITS_FRAGMENT,
  EXPENSE_TYPE_FRAGMENT,
  FLAG_FRAGMENT,
  COST_CENTER_FRAGMENT,
} from './fragments';

export const GET_AUTH = gql`
  query {
    auth @client {
      isAuthenticated
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query bankAccounts($orgId: ID) {
    bankAccounts(orgId: $orgId) {
      ID
      nickname
      accountNumber
      verification
    }
  }
`;

export const GET_AUTO_DEPOSITS = gql`
  query autoDeposits($orgId: ID) {
    autoDeposits(orgId: $orgId) {
      ...AutoDepositFragment
    }
  }
  ${AUTO_DEPOSIT_FRAGMENT}
`;

export const GET_MY_EXPENSE_FILTERS = gql`
  query myExpenseFilters($delegateOf: ID) {
    myExpenseFilters(delegateOf: $delegateOf) {
      code
      count
    }
  }
`;

export const GET_APP_NOTIFICATIONS_COUNT = gql`
  query alerts {
    alerts {
      alertsCount
      actionsCount
    }
  }
`;

export const GET_EXPENSE = gql`
  query expense($id: ID!, $delegateOf: ID) {
    expense(id: $id, delegateOf: $delegateOf) {
      ...ExpenseFragment
      splits {
        ...ExpenseSplitsFragment
      }
      policyForm {
        fields {
          custom
          hidden
          values
          description
          property
          type
          defaultValue
          required
          calculatedProperties {
            value
            property
            rate
          }
        }
      }
      events {
        ...EventFragment
      }
    }
  }
  ${EXPENSE_FRAGMENT}
  ${EXPENSE_SPLITS_FRAGMENT}
  ${EVENTS_FRAGMENT}
`;

export const GET_EXPENSES = gql`
  query expenses(
    $limit: Int
    $offset: String
    $status: String
    $filter: String
    $statusOop: String
    $sort: String
    $prioritizeRole: String
  ) {
    expenses(
      limit: $limit
      offset: $offset
      status: $status
      statusOop: $statusOop
      filter: $filter
      sort: $sort
      prioritizeRole: $prioritizeRole
    ) @connection(key: "expenses", filter: ["status", "filter"]) {
      items {
        ...ExpenseFragment
        splits {
          ID
          amount
          purpose
          notes
          costCenterID
          costCenterName
          costCenter @client {
            ID
            name
          }
          expenseTypeID
          expenseTypeName
          expenseType @client {
            ID
            name
          }
          flags {
            ...FlagFragment
          }
          isPersonal
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${EXPENSE_FRAGMENT}
  ${FLAG_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_MY_EXPENSES = gql`
  query myExpenses(
    $limit: Int
    $offset: String
    $status: String
    $filter: String
    $sort: String
    $delegateOf: ID
  ) {
    myExpenses(
      limit: $limit
      offset: $offset
      status: $status
      filter: $filter
      sort: $sort
      delegateOf: $delegateOf
    ) @connection(key: "myExpenses", filter: ["status", "filter"]) {
      items {
        ...ExpenseFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${EXPENSE_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSE_EVENTS = gql`
  query expense($id: ID!, $delegateOf: ID) {
    expense(id: $id, delegateOf: $delegateOf) {
      ID
      events {
        ...EventFragment
      }
    }
  }
  ${EVENTS_FRAGMENT}
`;

export const GET_RECEIPTS = gql`
  query receipts($expenseId: ID, $userId: ID, $delegateOf: ID) {
    receipts(expenseId: $expenseId, userId: $userId, delegateOf: $delegateOf) {
      fileID
      URI
      type
      uploadDate
    }
  }
`;

export const GET_RECEIPTS_BIN = gql`
  query receipts($delegateOf: ID) {
    receipts(delegateOf: $delegateOf) {
      fileID
      URI
      hdResThumbURI
      type
      uploadDate
    }
  }
`;

export const GET_USERS = gql`
  query users($limit: Int) {
    users(limit: $limit) {
      items {
        ID
        fullName
      }
    }
  }
`;

export const GET_COST_CENTERS = gql`
  query costCenters {
    costCenters {
      ...CostCenterFragment
    }
  }
  ${COST_CENTER_FRAGMENT}
`;

export const GET_MY_COST_CENTERS = gql`
  query myCostCenters($delegateOf: ID) {
    myCostCenters(delegateOf: $delegateOf) {
      ID
      name
    }
  }
`;

export const GET_PURPOSES = gql`
  query expensePurposes {
    expensePurposes {
      recent
      suggested
    }
  }
`;

export const GET_MERCHANTS = gql`
  query merchants($prefix: String, $limit: Int, $offset: String) {
    merchants(prefix: $prefix, limit: $limit, offset: $offset) {
      items {
        ID
        name
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSE_TYPES = gql`
  query expenseTypes($showDeleted: Boolean, $showParents: Boolean) {
    expenseTypes(showDeleted: $showDeleted, showParents: $showParents)
      @connection(key: "expenseTypes") {
      ...ExpenseTypeFragment
    }
  }
  ${EXPENSE_TYPE_FRAGMENT}
`;

export const GET_GENERAL_LEDGER = gql`
  query generalLedgerAccounts($limit: Int, $offset: String) {
    generalLedgerAccounts(limit: $limit, offset: $offset)
      @connection(key: "generalLedgerAccounts") {
      items {
        ID
        code
        name
        isDeleted
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

/**
 * Dashboards
 */
export const SPEND_BY = gql`
  query spendBy($filter: String, $path: String!, $pivotType: String!) {
    spendBy(drillDownPath: $path, pivotType: $pivotType, filter: $filter) {
      ID
      key
      label
      amount
      expenseCount
      avatarURI
    }
  }
`;

export const SPEND_SUMMARY = gql`
  query spendSummary(
    $filter: String
    $itemID: ID!
    $path: String!
    $pivotType: String!
  ) {
    spendSummary(
      drillDownPath: $path
      pivotType: $pivotType
      itemID: $itemID
      filter: $filter
    ) {
      ID
      spend
      expenseCount
      average
      projected
    }
  }
`;

export const SPEND_KPI = gql`
  query spendKPI($id: ID!, $filter: String) {
    spendKPI(id: $id, filter: $filter) {
      ID
      amount
      currencyCode
      expenseCount
      transactionCount
    }
  }
`;

export const GET_ASYNC_JOBS = gql`
  query {
    asyncJobs @client {
      jobs {
        ID
        jobType
        message
        status
        pollInterval
      }
      hasPendingJob
      message
    }
  }
`;

export const GET_JOB = gql`
  query job($id: ID!) {
    job(id: $id) {
      ID
      jobType
      status
      timeStamp
      warning
    }
  }
`;

export const GET_EXPENSE_EXPORTS = gql`
  query expenseExports {
    expenseExports {
      items {
        ID
        endDate
        jobType
        format
        name
        uri
        startDate
        status
        timeStamp
        changeReports {
          ID
          changeCount
          timeStamp
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSE_EXPORT = gql`
  query expenseExport($id: ID!) {
    expenseExport(id: $id) {
      ID
      endDate
      jobType
      format
      name
      uri
      startDate
      status
      timeStamp
      changeReports {
        ID
        timeStamp
        uri
      }
    }
  }
`;

export const GET_CUSTOM_FIELDS = gql`
  query customFields {
    customFields {
      description
      property
    }
  }
`;

export const GET_ERP_CONNECTORS = gql`
  query erpConnectors {
    erpConnectors {
      ID
      connected
      configured
      type
      name
      shortName @client
    }
  }
`;

export const GET_ERP_AUTH_URL = gql`
  query authorizationURL($url: AWSURL!, $connectorType: String!) {
    authorizationURL(url: $url, connectorType: $connectorType) {
      URL
    }
  }
`;

export const GET_ERP_CONFIGURATION = gql`
  query erpConfiguration @connection(key: "erpConfiguration") {
    erpConfiguration {
      ...ERPConfigurationFragment
    }
  }
  ${ERP_CONFIGURATION_FRAGMENT}
`;

export const GET_ERP_ACCOUNTS = gql`
  query erpAccounts {
    erpAccounts {
      ID
      name
      code
      type
    }
  }
`;

export const GET_ERP_COST_CENTERS = gql`
  query erpCostCenters {
    class: erpCostCenters(source: "class") {
      ID
      name
      parentID
    }
    department: erpCostCenters(source: "department") {
      ID
      name
      parentID
    }
    location: erpCostCenters(source: "location") {
      ID
      name
      parentID
    }
  }
`;
