import { forEach } from 'lodash';

export const HOME = '/';
export const LOGIN = '/login';
export const NOT_FOUND_PAGE = '/notfound';

// Card Management
export const MYCARD = '/mycard';
export const MYCARD_OVERVIEW = '/mycard/overview';
export const MYCARD_EXPENSES = '/mycard/expenses';

// Center Account
export const CENTERACCOUNT = '/centeraccount';
export const CENTERACCOUNT_SUMMARY = '/centeraccount/summary';
export const CENTERACCOUNT_TRANSACTIONS = '/centeraccount/transactions';
export const LINKED_ACCOUNTS = '/centeraccount/summary/accounts';
export const STATEMENTS = '/centeraccount/statements';

// Users
export const ADMINISTRATION = '/admin';
export const USERS = '/admin/users';
export const USER_CREATE = '/admin/users/create';
export const USER_INFO = '/admin/users/:id/view';
export const USER_EDIT = '/admin/users/:id/edit';

// Cost Centers
export const COST_CENTERS = '/admin/costcenters';
export const COST_CENTERS_INFO = '/admin/costcenters/:id/view';
export const COST_CENTERS_CREATE = '/admin/costcenters/create';
export const COST_CENTERS_EDIT = '/admin/costcenters/:id/edit';

// Expense Types
export const EXPENSE_TYPES = '/admin/expensetypes';
export const EXPENSE_TYPES_CREATE = '/admin/expensetypes/create';
export const EXPENSE_TYPES_EDIT = '/admin/expensetypes/:id/edit';

// General Ledger
export const GENERAL_LEDGER = '/admin/glaccounts';
export const GENERAL_LEDGER_CREATE = '/admin/glaccounts/create';
export const GENERAL_LEDGER_EDIT = '/admin/glaccounts/:id/edit';

// Integration
export const INTEGRATIONS = '/admin/integrations';
export const INTEGRATIONS_CONNECTORS = '/admin/integrations/connectors';
export const INTEGRATIONS_SETUP = '/admin/integrations/:erpType/setup';
export const INTEGRATIONS_SETTINGS = '/admin/integrations/:erpType/settings';
export const INTEGRATIONS_IMPORT_SOURCE =
  '/admin/integrations/:erpType/import/:source';

// Expense Hub
export const EXPENSE_HUB = '/expensehub';
export const EXPENSE_DETAILS_VIEW = '/expenses/:id/view';
export const EXPENSE_DETAILS_REVIEW = '/expenses/:id/review';
export const REPORTS = '/expensehub/reports';

// Out of Pocket
export const MY_OUT_OF_POCKET_EDIT = '/reimbursable/me/:id/edit';
export const MY_MILEAGE_EDIT = '/mileage/:id/edit';

// Password Management
export const CHANGE_PASSWORD = '/password/change';
export const FORGOT_PASSWORD = '/password/forgot';
export const RESET_PASSWORD = '/password/reset';
export const CREATE_PASSWORD = '/password/create';

// External Links
export const CONTACT_SUPPORT = 'https://support.centercard.com';

// Insights
export const INSIGHTS = '/insights';
export const INSIGHTS_DRILL_DOWN = '/insights/detail/:id';

// Policy Rules
export const RULES = '/admin/rules';
export const RULES_CUSTOM_APPROVAL = '/admin/rules/customapprovals';
export const RULES_CUSTOM_APPROVAL_CREATE =
  '/admin/rules/customapprovals/create';
export const RULES_CUSTOM_APPROVAL_EDIT =
  '/admin/rules/customapprovals/:id/edit';

// Construct route with path params
export const constructRoute = (route, params) => {
  if (!route || !params) {
    console.error('Cannot construct route: ', { route, params });
    return '';
  }
  let constructedRoute = route;
  forEach(params, (val, key) => {
    constructedRoute = constructedRoute.replace(`:${key}`, val);
  });

  return constructedRoute;
};

export const getRouteCorrection = (page, ID, expenseDetails) => {
  let correctPage;
  if (expenseDetails.isMileage) {
    correctPage = MY_MILEAGE_EDIT;
  } else if (expenseDetails.isOOP) {
    correctPage = MY_OUT_OF_POCKET_EDIT;
  } else {
    correctPage = EXPENSE_DETAILS_VIEW;
  }

  if (correctPage === page) return null;

  return constructRoute(correctPage, { id: ID });
};
