/* eslint-disable */
function _interopDefault(ex) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex.default : ex;
}

const React = _interopDefault(require('react'));
const PropTypes = _interopDefault(require('prop-types'));
const reactRouterDom = require('react-router-dom');

const _extends =
  Object.assign ||
  function(target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];

      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

const objectWithoutProperties = function(obj, keys) {
  const target = {};

  for (const i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

export const AuthRoute = function AuthRoute(_ref) {
  let Component = _ref.component,
    authenticated = _ref.authenticated,
    redirectTo = _ref.redirectTo,
    rest = objectWithoutProperties(_ref, [
      'component',
      'authenticated',
      'redirectTo',
    ]);

  return React.createElement(
    reactRouterDom.Route,
    _extends({}, rest, {
      render: function render(props) {
        return authenticated
          ? React.createElement(Component, { ...props, ...rest })
          : React.createElement(reactRouterDom.Redirect, {
            to: {
                pathname: redirectTo,
                state: { from: props.location },
              },
          });
      },
    }),
  );
};

AuthRoute.propTypes = {
  authenticated: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

AuthRoute.defaultProps = {
  authenticated: false,
};

export const UnauthRoute = function UnauthRoute(_ref) {
  let Component = _ref.component,
    authenticated = _ref.authenticated,
    redirectTo = _ref.redirectTo,
    rest = objectWithoutProperties(_ref, [
      'component',
      'authenticated',
      'redirectTo',
    ]);

  return React.createElement(
    reactRouterDom.Route,
    _extends({}, rest, {
      render: function render(props) {
        return authenticated
          ? React.createElement(reactRouterDom.Redirect, {
            to: {
              pathname: redirectTo,
              state: { from: props.location },
              },
          })
          : React.createElement(Component, { ...props, ...rest });
      },
    }),
  );
};

UnauthRoute.propTypes = {
  authenticated: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

UnauthRoute.defaultProps = {
  authenticated: false,
};
