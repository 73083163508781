import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import _isEqual from 'lodash/isEqual';
import usePersistentStorage, { DELEGATE_USER } from './usePersistentStorage';

export const SET_DELEGATE_USER = gql`
  mutation setDelegateUser($delegateUser: JSON!) {
    setDelegateUser(delegateUser: $delegateUser) @client
  }
`;

export const GET_DELEGATE_USER = gql`
  query {
    delegateUser @client {
      ID
      firstName
      fullName
      lastName
      avatarURI
    }
  }
`;

const useDelegateUser = () => {
  const [, setDelegateUserStorage] = usePersistentStorage(DELEGATE_USER, null);
  const [setDelegateUser] = useMutation(SET_DELEGATE_USER);
  const { data } = useQuery(GET_DELEGATE_USER);
  const { delegateUser } = data || {};

  const handleSetDelegateUser = user => {
    if (_isEqual(delegateUser, user)) return;
    setDelegateUser({
      variables: { delegateUser: user },
    });
    setDelegateUserStorage(user);
  };

  return [delegateUser, handleSetDelegateUser];
};

export default useDelegateUser;
