import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'center-ui';
import Color from 'center-ui/themes/colors';
import Link from '../Link';

const Wrapper = styled.div`
  position: fixed;
  background-color: ${Color.navy};
  bottom: 24px;
  left: 24px;
  padding: 0 24px;
  z-index: 9;
`;

const Content = styled.div`
  color: ${Color.white};
  display: block;
  position: relative;
  height: 100%;
  line-height: 52px;
`;

const LinkStyled = styled(Link)`
  color: ${Color.gray_4} !important;
  float: right;
  &:hover {
    color: ${Color.white} !important;
  }
`;

const SpinIcon = styled(Icon)`
  font-size: 1rem !important;
  color: ${Color.white} !important;
  display: inline-block;
  margin-right: 20px !important;
`;

const AsyncProgressDialog = ({ message, closeText, onClose }) => (
  <Wrapper>
    <Content>
      <SpinIcon type="loading" />
      {message}
      {closeText && (
        <LinkStyled id="cancel-async-request-btn" onClick={onClose}>
          {closeText}
        </LinkStyled>
      )}
    </Content>
  </Wrapper>
);

AsyncProgressDialog.propTypes = {
  message: PropTypes.string,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
};

AsyncProgressDialog.defaultProps = {
  closeText: 'Cancel',
};

export default AsyncProgressDialog;
