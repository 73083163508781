import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from 'core-ui/components/providers/ErrorBoundary';
import ErrorPage from 'core-ui/components/pages/ErrorPage';
import BaseLayout from './BaseLayout';
import * as Routes from '../utils/routes';
import { getRouterData } from '../common/router';

const routerData = getRouterData();
const routes = [
  {
    component: routerData[Routes.CHANGE_PASSWORD].component,
    path: Routes.CHANGE_PASSWORD,
    exact: true,
  },
];

const BlankLayout = props => (
  <BaseLayout {...props}>
    {() => (
      <ErrorBoundary placeHolder={<ErrorPage />}>
        <Switch>
          {routes.map(route => <Route key={route.path} {...route} />)}
        </Switch>
      </ErrorBoundary>
    )}
  </BaseLayout>
);

BlankLayout.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export default BlankLayout;
