import _get from 'lodash/get';
import { useApolloClient, useQuery, useMutation } from 'react-apollo';
import _forEach from 'lodash/forEach';
import { generateRandomString } from 'center-ui/utils';
import API from '../services/rest/api';
import { SET_ASYNC_JOBS } from '../services/graphql/mutations';
import { GET_ASYNC_JOBS } from '../services/graphql/queries';
import {
  ASYNC_JOB_STATUS,
  ASYNC_JOB_PARAMS,
  processAsyncJobs,
} from '../utils/asyncJobs';

const useAsyncJobs = () => {
  const client = useApolloClient();
  const [setAsyncJobs] = useMutation(SET_ASYNC_JOBS);
  const { data: asyncJobsData = {} } = useQuery(GET_ASYNC_JOBS);
  const jobs = _get(asyncJobsData, 'asyncJobs.jobs', []);
  const hasPendingJob = _get(asyncJobsData, 'asyncJobs.hasPendingJob', false);
  const message = _get(asyncJobsData, 'asyncJobs.message', null);

  const setAsyncJob = async job => {
    const { ID: jobID, jobType, variables } = job;

    if (jobID) {
      // Update async job in cache
      await setAsyncJobs({
        variables: {
          asyncJobsData: processAsyncJobs(job, client),
        },
      });
    } else {
      // Initiate new job then add to cache
      const { mutation } = ASYNC_JOB_PARAMS[jobType];

      const tempID = generateRandomString('tempAsyncJob');
      const deleteTempJob = () =>
        // Delete temp job
        setAsyncJobs({
          variables: {
            asyncJobsData: processAsyncJobs(
              {
                ID: tempID,
                shouldDelete: true,
              },
              client,
            ),
          },
        });

      // Add temp job immediately to affect UI
      await setAsyncJobs({
        variables: {
          asyncJobsData: processAsyncJobs(
            {
              ID: tempID,
              jobType,
              variables,
            },
            client,
          ),
        },
      });

      await client
        .mutate({ mutation, variables })
        .then(async ({ data }) => {
          let ID = null;
          // Traverse data response to find job ID (expect 1 level deep)
          _forEach(data, dataNode => {
            _forEach(dataNode, (val, key) => {
              if (key === 'ID') ID = val;
            });
          });
          if (!ID)
            API.logError(API.LogLevel.error, {
              message: `Unable to get job ID from data on ${jobType} mutation`,
              details: { data },
            });
          await setAsyncJobs({
            variables: {
              asyncJobsData: processAsyncJobs({ ID, ...job }, client),
            },
          });
          await deleteTempJob();
        })
        .catch(err => {
          setAsyncJobs({
            variables: {
              asyncJobsData: processAsyncJobs(
                {
                  ID: tempID,
                  status: ASYNC_JOB_STATUS.Error,
                  jobType,
                },
                client,
              ),
            },
          });
          API.logError(API.LogLevel.error, {
            message: 'GraphQL error in submitJob() mutation',
            details: {
              error: err,
            },
          });
        });
    }
    return Promise.resolve();
  };

  const upsertAsyncJob = setAsyncJob;
  const deleteAsyncJob = jobID =>
    setAsyncJob({ ID: jobID, shouldDelete: true });

  return [{ jobs, hasPendingJob, message }, upsertAsyncJob, deleteAsyncJob];
};

export default useAsyncJobs;
