import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Heading } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import CenteredPageTemplate from '../../templates/CenteredPageTemplate';
import emoji3 from '../../assets/imgs/emoji3.png';
import emoji4 from '../../assets/imgs/emoji4.png';

const CenteredPageTemplateStyled = styled(CenteredPageTemplate)`
  min-height: 100% !important;
`;

const Wrapper = styled.div`
  max-width: 28.5rem;
  width: 100%;
  text-align: center;
`;

const Title = styled(Heading)`
  margin-bottom: 0.125rem !important;
`;

const Emoji = styled.img`
  margin: 1.25rem 0;
`;

const Text = styled(Heading)`
  margin-bottom: 1rem !important;
`;

const Description = styled.p`
  white-space: normal;
`;

const AnchorStyled = styled.a`
  color: ${Colors.blue};
  :hover {
    color: ${Colors.blue};
    opacity: 0.8;
  }
`;

const ComponentError = props => {
  const { size, error, ...rest } = props;
  if (error) {
    console.error(error);
  }
  const text = (
    <Description>
      There was an error loading this component. Please try refreshing the page
      or{' '}
      <AnchorStyled
        href="https://support.centercard.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact us.
      </AnchorStyled>
    </Description>
  );
  if (size === 'default') {
    return (
      <Card {...rest}>
        <Title as="h5">
          <img alt="Error emoji" src={emoji3} width={22} /> Something is missing
        </Title>
        {text}
      </Card>
    );
  }
  return (
    <Card {...rest}>
      <CenteredPageTemplateStyled>
        <Wrapper>
          <Heading as="h1">Oops!</Heading>
          <Emoji alt="Error emoji" src={emoji4} width={140} />
          <Text as="h4">Error loading</Text>
          {text}
        </Wrapper>
      </CenteredPageTemplateStyled>
    </Card>
  );
};

ComponentError.propTypes = {
  size: PropTypes.oneOf(['default', 'large']),
  error: PropTypes.any,
};

ComponentError.defaultProps = {
  size: 'default',
};

export default ComponentError;
