import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { graphql, ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { createAppSyncLink } from 'aws-appsync';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { UnauthRoute, AuthRoute } from './common/authorizer';
import AppSyncConfig from './AppSync';
import GlobalStyles from './global-styles';
import { TokenRefreshLink } from './common/tokenRefreshLink';
import API from './services/rest/api';
import { GET_AUTH } from './services/graphql/queries';
import localResolvers from './services/graphql/resolvers';
import * as Routes from './utils/routes';
import Storage from './utils/storage';
import { getRouterData } from './common/router';
import { UserRoles } from './common/roles';
import BlankLayout from './layouts/BlankLayout';
import FeatureFlags from './utils/featureFlags';
import DashboardLayout from './layouts/DashboardLayout';

const { ALL, FM, EA, SP, IV } = UserRoles;

let authObj = null;
let client = null;

const url = AppSyncConfig.graphqlEndpoint;

export const cache = new InMemoryCache({
  dataIdFromObject: obj => {
    const { ID, __typename } = obj;
    if (__typename === 'Approver') {
      return `${__typename}:${obj.ruleID}`;
    }
    if (__typename === 'CurrentApproverUser') {
      return null;
    }
    if (__typename === 'Receipt') {
      return `${__typename}:${obj.fileID}`;
    }
    if (__typename === 'SpendSummary') {
      return null;
    }
    if (__typename === 'SpendData') {
      return null;
    }
    if (ID) {
      return `${__typename}:${ID}`;
    }
    return null;
  },
});

cache.writeData({ data: localResolvers.data });

const appSyncLink = createAppSyncLink({
  url,
  region: AppSyncConfig.region,
  auth: {
    type: AppSyncConfig.authenticationType,
    apiKey: AppSyncConfig.apiKey,
  },
});

const httpLink = createHttpLink({ uri: url });

const isTokenExpired = () => moment.utc().isAfter(authObj.expires_on);

const refreshTokenLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => !isTokenExpired(),
  fetchAccessToken: () => API.refreshToken(),
  handleFetch: accessTokenObj => {
    authObj = accessTokenObj;
    Storage.setItem('auth', JSON.stringify(authObj));
  },
  handleError: err => {
    console.error(err);
    API.logout();
    authObj = null;
  },
});

const notFoundPageLinks = [
  { href: Routes.CONTACT_SUPPORT, label: 'Home' },
  { url: Routes.MYCARD_OVERVIEW, label: 'CenterCard Overview' },
];

const contextLink = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    Authorization: `Bearer ${authObj.access_token}`,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Fetching me from cache
  const GET_ME = gql`
    query {
      me @client {
        ID
        fullName
        roles
        loginID
        organization {
          ID
          name
        }
      }
    }
  `;
  let me = null;
  try {
    me = client.readQuery({ query: GET_ME });
  } catch (err) {
    me = null;
  }
  if (graphQLErrors) {
    API.logError(API.LogLevel.error, {
      message: 'GraphQL error',
      details: {
        error: graphQLErrors.map(e => e.message),
        info: graphQLErrors.map(({ locations, path }) => ({
          locations,
          path,
        })),
        user: me,
      },
    });
  }
  if (networkError) {
    API.logError(API.LogLevel.error, {
      message: 'Network error',
      details: {
        error: networkError,
        user: me,
      },
    });
  }
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const omitTypename = (key, value) =>
    key === '__typename' ? undefined : value;

  if (operation.variables && !operation.variables.file) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }

  return forward(operation);
});

const link = ApolloLink.from([
  refreshTokenLink,
  contextLink,
  cleanTypenameLink,
  errorLink,
  appSyncLink,
  createSubscriptionHandshakeLink(url, httpLink),
]);

client = new ApolloClient({
  cache,
  link,
  resolvers: localResolvers.resolvers,
});

API.setGraphqlClient(client, localResolvers);

const routerData = getRouterData();

const NotFoundPage = routerData[Routes.NOT_FOUND_PAGE].component;
const LoginPage = routerData[Routes.LOGIN].component;
const ForgotPasswordPage = routerData[Routes.FORGOT_PASSWORD].component;
const ResetPasswordPage = routerData[Routes.RESET_PASSWORD].component;
const CreatePasswordPage = routerData[Routes.CREATE_PASSWORD].component;

const App = props => {
  const { isAuthenticated, loading } = props;
  if (loading) return null;

  const routesProps = {
    exact: true,
    redirectTo: Routes.LOGIN,
    component: DashboardLayout,
    authenticated: isAuthenticated,
  };

  return (
    <Router>
      <Switch>
        <AuthRoute
          {...routesProps}
          path={Routes.HOME}
          showSideNav={false}
          authority={[ALL]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.MYCARD_OVERVIEW}
          authority={[SP]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.MYCARD_EXPENSES}
          authority={[SP]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.CENTERACCOUNT_SUMMARY}
          authority={[FM]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.CENTERACCOUNT_TRANSACTIONS}
          authority={[FM]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.LINKED_ACCOUNTS}
          authority={[FM]}
        />
        <AuthRoute {...routesProps} path={Routes.STATEMENTS} authority={[FM]} />

        <AuthRoute
          {...routesProps}
          path={Routes.USER_CREATE}
          authority={[FM]}
        />
        <AuthRoute {...routesProps} path={Routes.USER_EDIT} authority={[FM]} />
        <AuthRoute {...routesProps} path={Routes.USER_INFO} authority={[FM]} />
        <AuthRoute {...routesProps} path={Routes.USERS} authority={[FM]} />
        <AuthRoute
          {...routesProps}
          path={Routes.COST_CENTERS}
          authority={[FM]}
          featureFlags={[FeatureFlags.COST_CENTERS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.COST_CENTERS_INFO}
          authority={[FM]}
          featureFlags={[FeatureFlags.COST_CENTERS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.COST_CENTERS_CREATE}
          authority={[FM]}
          featureFlags={[FeatureFlags.COST_CENTERS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.COST_CENTERS_EDIT}
          authority={[FM]}
          featureFlags={[FeatureFlags.COST_CENTERS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.EXPENSE_TYPES}
          authority={[FM]}
          featureFlags={[FeatureFlags.EXPENSE_TYPES]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.EXPENSE_TYPES_CREATE}
          authority={[FM]}
          featureFlags={[FeatureFlags.EXPENSE_TYPES]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.EXPENSE_TYPES_EDIT}
          authority={[FM]}
          featureFlags={[FeatureFlags.EXPENSE_TYPES]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.GENERAL_LEDGER}
          authority={[FM]}
          featureFlags={[FeatureFlags.GENERAL_LEDGER]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.GENERAL_LEDGER_CREATE}
          authority={[FM]}
          featureFlags={[FeatureFlags.GENERAL_LEDGER]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.GENERAL_LEDGER_EDIT}
          authority={[FM]}
          featureFlags={[FeatureFlags.GENERAL_LEDGER]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INTEGRATIONS}
          authority={[FM]}
          featureFlags={[FeatureFlags.INTEGRATIONS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INTEGRATIONS_CONNECTORS}
          authority={[FM]}
          featureFlags={[FeatureFlags.INTEGRATIONS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INTEGRATIONS_SETUP}
          showSideNav={false}
          authority={[FM]}
          featureFlags={[FeatureFlags.INTEGRATIONS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INTEGRATIONS_SETTINGS}
          authority={[FM]}
          featureFlags={[FeatureFlags.INTEGRATIONS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INTEGRATIONS_IMPORT_SOURCE}
          showSideNav={false}
          authority={[FM]}
          featureFlags={[FeatureFlags.INTEGRATIONS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.EXPENSE_HUB}
          showSideNav={false}
          authority={[FM, EA]}
        />
        <AuthRoute
          {...routesProps}
          fixedHeight
          noContainer
          path={Routes.EXPENSE_DETAILS_VIEW}
          showSideNav={false}
          authority={[SP]}
        />
        <AuthRoute
          {...routesProps}
          fixedHeight
          noContainer
          path={Routes.EXPENSE_DETAILS_REVIEW}
          showSideNav={false}
          authority={[FM, EA]}
        />
        <AuthRoute
          {...routesProps}
          fixedHeight
          noContainer
          path={Routes.MY_OUT_OF_POCKET_EDIT}
          showSideNav={false}
          authority={[SP]}
          featureFlags={[FeatureFlags.OUT_OF_POCKET]}
        />
        <AuthRoute
          {...routesProps}
          fixedHeight
          noContainer
          path={Routes.MY_MILEAGE_EDIT}
          showSideNav={false}
          authority={[SP]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.REPORTS}
          showSideNav={false}
          authority={[FM]}
          featureFlags={[FeatureFlags.EXPENSE_REPORTS]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INSIGHTS}
          showSideNav={false}
          authority={[FM, IV]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.INSIGHTS_DRILL_DOWN}
          showSideNav={false}
          authority={[FM, IV]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.RULES_CUSTOM_APPROVAL}
          showSideNav
          authority={[FM]}
          featureFlags={[FeatureFlags.RULES_CUSTOM_APPROVAL]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.RULES_CUSTOM_APPROVAL_CREATE}
          showSideNav
          authority={[FM]}
          featureFlags={[FeatureFlags.RULES_CUSTOM_APPROVAL]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.RULES_CUSTOM_APPROVAL_EDIT}
          showSideNav
          authority={[FM]}
          featureFlags={[FeatureFlags.RULES_CUSTOM_APPROVAL]}
        />
        <AuthRoute
          {...routesProps}
          path={Routes.CHANGE_PASSWORD}
          component={BlankLayout}
          authority={[ALL]}
        />
        <UnauthRoute
          {...routesProps}
          path={Routes.FORGOT_PASSWORD}
          redirectTo={Routes.HOME}
          component={ForgotPasswordPage}
        />
        <UnauthRoute
          {...routesProps}
          path={Routes.RESET_PASSWORD}
          redirectTo={Routes.HOME}
          component={ResetPasswordPage}
        />
        <UnauthRoute
          {...routesProps}
          path={Routes.CREATE_PASSWORD}
          redirectTo={Routes.HOME}
          component={CreatePasswordPage}
        />
        <UnauthRoute
          {...routesProps}
          path={Routes.LOGIN}
          redirectTo={Routes.HOME}
          component={LoginPage}
        />
        <Redirect exact from={Routes.MYCARD} to={Routes.MYCARD_OVERVIEW} />
        <Redirect
          exact
          from={Routes.CENTERACCOUNT}
          to={Routes.CENTERACCOUNT_SUMMARY}
        />
        <Redirect exact from={Routes.ADMINISTRATION} to={Routes.USERS} />
        <Redirect exact from={Routes.RULES} to={Routes.RULES_CUSTOM_APPROVAL} />

        <Route render={() => <NotFoundPage links={notFoundPageLinks} />} />
      </Switch>
    </Router>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
};

const WithAuthentication = graphql(GET_AUTH, {
  props: ({ data: { loading, error, auth } }) => {
    if (loading) return { loading };
    if (error) return { error };
    const token = Storage.getItem('auth');
    authObj = token ? JSON.parse(token) : null;
    return {
      loading: false,
      isAuthenticated: auth && auth.isAuthenticated,
    };
  },
})(App);

const WithProvider = () => (
  <Scrollbars
    autoHide
    autoHideTimeout={1000}
    // Duration for hide animation in ms.
    autoHideDuration={200}
    renderTrackVertical={props => <div {...props} className="track-vertical" />}
  >
    <ApolloProvider client={client}>
      <GlobalStyles />
      <WithAuthentication />
    </ApolloProvider>
  </Scrollbars>
);

export default WithProvider;
