import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as AntAvatar } from 'antd';
import styled from 'styled-components';
import Ellipsis from '../Ellipsis';
import OverlineLabel from '../OverlineLabel';
import Vars from '../../themes/variables';
import Colors from '../../themes/colors';
import { styleConstructor } from '../../utils';
import { sansProps } from '../../utils/styledSansProps';

const getInitials = name => {
  let result = '*';
  try {
    if (!name) return result; // filter out empty strings in the case a user accidentally adds an extra space
    const words = name.split(' ').filter(n => n !== '');
    const len = words.length;
    result = words
      .map(nm => nm[0])
      .join('')
      .substring(0, len < 5 ? len : 1)
      .toUpperCase();
    return result;
  } catch (err) {
    console.error('Error generating Avatar initials: ', { err, name });
    return result;
  }
};

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

const AvatarStyled = sansProps(styled, { bordered: true, styling: true })(
  'span',
)`
  .ant-avatar {
    background-color: transparent;
    border: ${({ bordered }) =>
      bordered ? `1px solid ${Vars.PRIMARY_COLOR}` : 'none'};
    color: ${Vars.PRIMARY_COLOR};
    margin-right: 0.5rem;
    ${({ styling }) => styling && styleConstructor(styling)};
  }
`;

const InlineWrapper = styled.div`
  align-self: center;
  div {
    line-height: 1.3;
  }
`;

const Anchor = styled.button`
  background-color: transparent !important;
  background: none !important;
  color: ${Colors.blue};
  border: none;
  text-align: left;
  width: 100% !important;
  padding: 0 !important;
  font: inherit;
  border: 0;
  height: 1.3125rem;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.8;
  }
`;

class Avatar extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return (
      nextProps.label !== props.label ||
      nextProps.name !== props.name ||
      nextProps.header !== props.header
    );
  }

  renderEllipsis = item => {
    const { ellipsis } = this.props;
    if (ellipsis) {
      return <Ellipsis {...ellipsis}>{item}</Ellipsis>;
    }
    return item;
  };

  renderLabel = () => {
    const {
      label,
      header,
      onClick,
      styleLabel,
      styleName,
      styleHeader,
    } = this.props;
    const span = (
      <InlineWrapper>
        <OverlineLabel.Text
          styling={{
            fontSize: '0.875rem',
            width: '100%',
            ...styleName,
          }}
        >
          {this.renderEllipsis(label)}
        </OverlineLabel.Text>
      </InlineWrapper>
    );

    // Make anchor link a pseudo button
    const anchor = (
      <Anchor onClick={onClick}>{this.renderEllipsis(label)}</Anchor>
    );

    const overlineSpan = (
      <InlineWrapper>
        <OverlineLabel
          label={this.renderEllipsis(header)}
          text={this.renderEllipsis(label)}
          styleLabel={{
            fontSize: '0.875rem',
            fontWeight: 700,
            ...styleHeader,
          }}
          styleText={{
            fontSize: '0.75rem',
            fontWeight: 400,
            ...styleLabel,
          }}
        />
      </InlineWrapper>
    );

    const overlineAnchor = (
      <InlineWrapper>
        <OverlineLabel
          label={this.renderEllipsis(header)}
          text={anchor}
          styleLabel={{
            fontSize: '0.75rem',
            ...styleHeader,
          }}
          styleText={{
            fontSize: '0.625rem',
            ...styleLabel,
          }}
        />
      </InlineWrapper>
    );

    const result = () => {
      if (label && header && onClick) return overlineAnchor;
      if (label && header) return overlineSpan;
      if (label && onClick) return anchor;
      if (label) return span;
      return null;
    };
    return result();
  };

  render = () => {
    const {
      name,
      label,
      header,
      src,
      onClick,
      showInitials,
      ellipsis,
      size,
      styleAvatar,
      styleLabel,
      styleName,
      styleHeader,
      ...otherProps
    } = this.props;

    let avatar = null;
    if (src) {
      avatar = <AntAvatar icon="user" src={src} size={size} {...otherProps} />;
    } else if (showInitials) {
      avatar = (
        <AntAvatar size={size} {...otherProps}>
          {getInitials(name)}
        </AntAvatar>
      );
    }

    return (
      <Wrapper>
        <AvatarStyled styling={styleAvatar} bordered={!src}>
          {avatar}
        </AvatarStyled>
        {this.renderLabel()}
      </Wrapper>
    );
  };
}

Avatar.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['large', 'default', 'small']),
    PropTypes.number,
  ]),
  type: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  header: PropTypes.any,
  onClick: PropTypes.func,
  showInitials: PropTypes.bool,
  ellipsis: PropTypes.object,
  styleAvatar: PropTypes.object,
  styleLabel: PropTypes.object,
  styleName: PropTypes.object,
  styleHeader: PropTypes.object,
};

Avatar.defaultProps = {
  type: 'circle',
  size: 'default',
  label: null,
  showInitials: true,
  onClick: null,
};

export default Avatar;
