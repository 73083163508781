import moment from 'moment';
import _merge from 'lodash/merge';
import _forEach from 'lodash/forEach';
import { configureRefreshFetch } from 'refresh-fetch';
import { SET_AUTH } from '../graphql/mutations';
import Storage from '../../utils/storage';
import { browserDetection } from '../../utils';
import { MIME_TYPES } from '../../utils/constants';
const CORS_PROXY = 'https://cors.centercard.com';
const API_URL = process.env.REACT_APP_BUILD_API;
const CONSOLE_LOG_ENABLED =
  // env bools are strings
  process.env.REACT_APP_CONSOLE_LOG_ENABLED === 'true';
const LOG_API_ENABLED =
  // env bools are strings
  process.env.REACT_APP_LOG_API_ENABLED === 'true';
const isLocalhost = process.env.NODE_ENV === 'development';

const LogLevel = {
  error: 'error',
  warning: 'warn',
  info: 'info',
};

// always console.error on localhost
const shouldConsoleLog = CONSOLE_LOG_ENABLED || isLocalhost;
// disable log API on localhost
const shouldLogAPI = LOG_API_ENABLED && !isLocalhost;

let graphqlClient = null;
let graphqlResolvers = null;

const setGraphqlClient = (client, resolvers) => {
  graphqlClient = client;
  graphqlResolvers = resolvers;
};

export const queryConstructor = params => {
  let queryString = '';
  const queryParam = [];
  const buildQueryString = (val, key) => {
    if (val !== null && val !== undefined) queryParam.push({ [key]: val });
  };
  _forEach(params, buildQueryString);
  if (queryParam.length > 0) {
    const qParams = queryParam.map((param, idx) => {
      const property = Object.keys(queryParam[idx])[0];
      const q = `${property.toLowerCase()}=${encodeURIComponent(
        queryParam[idx][property],
      )}`;
      if (idx > 0) return `&${q}`;
      return q;
    });
    queryString = `?${qParams.join('')}`;
  }
  return queryString;
};

export const getUriWithCorsProxy = uri => `${CORS_PROXY}/${uri}`;

const retrieveToken = () => {
  const auth = Storage.getItem('auth');
  return auth ? JSON.parse(auth) : null;
};

const cacheToken = token => {
  token.expires_on = moment
    .utc()
    .add(token.expires_in, 'seconds')
    .format();
  Storage.setItem('auth', JSON.stringify(token));
};

const fetchWithToken = async (url, options = {}, token) => {
  if (!token) {
    const auth = retrieveToken();
    if (!auth) {
      logout();
      return Promise.reject(
        new Error('Authentication failed. Please log in and try again.'),
      );
    }
    token = auth.access_token;
  }

  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken = _merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return fetch(url, optionsWithToken);
};

const login = async (username, password) => {
  const auth = window.btoa(`${username}:${password}`);
  const response = await fetch(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/token`),
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Basic ${auth}`,
        'Content-Type': MIME_TYPES.json,
      },
    },
  );

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error(
        'Invalid email or password. Please check and try logging in again.',
      );
    }
    throw new Error(
      'Oops! There was a problem logging you in. Please try again.',
    );
  }

  const token = await response.json();
  cacheToken(token);
  return token;
};

const logout = async () => {
  Storage.clear();
  graphqlResolvers.data = graphqlResolvers.getData();

  await graphqlClient.mutate({
    mutation: SET_AUTH,
    variables: { isAuthenticated: false },
  });
  graphqlClient.cache.reset();
  graphqlClient.cache.writeData({ data: graphqlResolvers.data });
};

const refreshToken = () => {
  const token = retrieveToken();
  return fetch(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/token`), {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': MIME_TYPES.json,
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: token ? token.refresh_token : null,
    }),
  });
};

// Passwords

const changePassword = async (userID, password, token) => {
  if (!(userID, password, token)) {
    console.error('Change password requires ( userID, password, token): ', {
      userID,
      password,
      token,
    });
  }
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/${userID}/password`),
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': MIME_TYPES.json,
      },
      body: JSON.stringify({
        password,
      }),
    },
    token,
  );
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.errorMessage || json.message);
  }
  return Promise.resolve();
};

const forgotPassword = async email => {
  if (!email) {
    console.error('Forgot password requires ( email ): ', email);
  }
  const response = await fetch(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/passwordresetemail`),
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': MIME_TYPES.json,
      },
      body: JSON.stringify({
        loginID: email,
      }),
    },
  );

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error(
        'Unable to send reset link. Please verify and try again, or contact support.',
      );
    }
    throw new Error(
      'Oops! There was a problem sending reset password link. Please verify the email is valid and try again.',
    );
  }

  return Promise.resolve();
};

const resetPassword = async (password, code) => {
  if (!(password && code)) {
    console.error('Reset password requires ( password, code ): ', {
      password,
      code,
    });
  }
  const response = await fetch(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/resetpassword`),
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': MIME_TYPES.json,
      },
      body: JSON.stringify({
        password,
        code,
      }),
    },
  );

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error(
        'Reset password failed. Please send a new link and try again.',
      );
    }
    if (response.status === 400) {
      const json = await response.json();
      throw new Error(json.errorMessage || json.message);
    }
    throw new Error(
      'Oops! There was a problem resetting password. Please try again.',
    );
  }

  return Promise.resolve();
};

// Statements

const downloadStatement = async (resourceId, year, mime) => {
  const throwError = () => {
    throw new Error('An error occurred during the file download.');
  };

  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(
      `${API_URL}/reports/v3.0/statements/${year}/${resourceId}/signeduri`,
    ),
    {
      mode: 'cors',
    },
  );
  if (!response1.ok) {
    throwError();
  }

  const result = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${result.URI}`, {
    mode: 'cors',
    responseType: 'arraybuffer',
    headers: {
      Accept: mime,
    },
  });

  if (!response2.ok) {
    throwError();
  }
  const blob = await response2.arrayBuffer();
  return blob;
};

const uploadReceipt = async (file, expenseId, userId, delegateOf) => {
  const throwError = () => {
    throw new Error(
      'An error occurred during the receipt upload. Please try again.',
    );
  };
  const { type, name } = file;
  const queryparams = queryConstructor({
    contentType: type,
    expenseId,
    fileId: type === MIME_TYPES.pdf ? name : null,
    userId,
    delegateOf,
  });
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/receipts/v3.0/uploaduri${queryparams}`),
    {
      mode: 'cors',
    },
  );
  if (!response1.ok) {
    throwError();
  }

  const uriUpload = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriUpload.URI}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': type,
    },
    body: file,
  });
  if (!response2.ok) {
    throwError();
  }

  return Promise.resolve(uriUpload);
};

const deleteReceipt = async (expenseId, userId, fileId, delegateOf) => {
  const throwError = () => {
    throw new Error(
      'An error occurred during the receipt deletion. Please try again.',
    );
  };
  const queryparams = queryConstructor({
    expenseId,
    fileId,
    userId,
    delegateOf,
  });
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/receipts/v3.0/deletionuri${queryparams}`),
    {
      mode: 'cors',
    },
  );
  if (!response1.ok) {
    throwError();
  }

  const uriDeletion = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriDeletion.URI}`, {
    method: 'DELETE',
    mode: 'cors',
  });
  if (!response2.ok) {
    throwError();
  }

  return Promise.resolve(uriDeletion);
};

const logError = async (level, logObject) => {
  const throwError = () => {
    throw new Error('An error occurred during logging.');
  };
  if (logObject && !logObject.details) logObject.details = {};
  logObject.details.userAgent = navigator.userAgent;
  logObject.details.browser = browserDetection();
  logObject.details.browserUrl = window.location.href;
  if (shouldConsoleLog) {
    console.error(level, logObject);
  }
  if (shouldLogAPI) {
    const response = await fetchWithRefresh(
      getUriWithCorsProxy(`${API_URL}/utils/v3.0/log/${level}`),
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(logObject),
      },
    );
    if (!response.ok) {
      throwError();
    }
    return response;
  }
  return Promise.resolve();
};

const fetchWithRefresh = configureRefreshFetch({
  fetch: fetchWithToken,
  shouldRefreshToken: error => error.status === 401,
  refreshToken: async () => {
    const response = await refreshToken();
    const token = await response.json();
    cacheToken(token);
    return Promise.resolve();
  },
});

const downloadFileFromURI = async (
  uri,
  mime,
  shouldPrependCorsProxy = true,
) => {
  const throwError = () => {
    throw new Error('An error occurred during the file download.');
  };
  const url = shouldPrependCorsProxy ? getUriWithCorsProxy(uri) : uri;

  const response = await fetch(url, {
    mode: shouldPrependCorsProxy ? 'cors' : 'no-cors',
    responseType: 'arraybuffer',
    headers: {
      Accept: mime,
    },
  });
  if (!response.ok) {
    throwError();
  }

  const blob = await response.arrayBuffer();
  return Promise.resolve(blob);
};

const sendReminderEmails = async (reminderType, customMessage) => {
  const throwError = () => {
    throw new Error('An error occurred sending reminder emails.');
  };
  if (!reminderType || !customMessage) {
    console.error('Missing fields on sendReminderEmails()', {
      reminderType,
      customMessage,
    });
    return Promise.reject();
  }
  const response = await fetchWithRefresh(
    getUriWithCorsProxy(
      `${API_URL}/emails/v3.0/reminders/expenses/${reminderType.toLowerCase()}`,
    ),
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ customMessage }),
    },
  );
  if (!response.ok) {
    throwError();
    return Promise.reject();
  }
  return Promise.resolve(response);
};

export default {
  login,
  logout,
  refreshToken,
  changePassword,
  forgotPassword,
  resetPassword,
  downloadStatement,
  uploadReceipt,
  deleteReceipt,
  downloadFileFromURI,
  logError,
  LogLevel,
  sendReminderEmails,
  setGraphqlClient,
};
