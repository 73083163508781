import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styleConstructor } from '../../utils';
import FormattedNumber from '../FormattedNumber';

const KpiValueStyled = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const KpiValue = ({ className, value, styling, numberFormat }) => {
  if (numberFormat) {
    return (
      <KpiValueStyled className={className} styling={styling}>
        <FormattedNumber value={value} {...numberFormat} />
      </KpiValueStyled>
    );
  }
  return <KpiValueStyled styling={styling}>{value}</KpiValueStyled>;
};

KpiValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  styling: PropTypes.object,
  numberFormat: PropTypes.object,
};

export default React.memo(KpiValue);
