import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Color from 'center-ui/themes/colors';
import { getMenuDataWithChildren } from '../../common/menu';
import NavMenu from '../NavMenu';
import UserMenu from '../UserMenu';
import AppNotificationsIndicator from '../AppNotificationsIndicator';

const NavMenuWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  .ant-menu-submenu {
    margin-right: 2rem;
    vertical-align: inherit !important;
    .ant-menu-item-selected,
    .ant-menu-item-active {
      background-color: transparent !important;
      a {
        color: ${Color.blue} !important;
      }
    }
  }
`;

const NavMenuStyled = styled(NavMenu)`
  flex: 1 1 0% !important;
  height: 64px !important;
  line-height: 64px !important;
  > .ant-menu-item {
    top: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    margin-right: 2rem !important;
    vertical-align: inherit !important;
    border-bottom: none !important;
    a {
      color: #fff !important;
      padding-left: 0 !important;
      font-size: 0.875rem !important;
      height: 4rem;
      :hover {
        color: #fff !important;
      }
    }
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      border: none !important;
      background-color: transparent !important;
      a {
        color: #fff !important;
        background-image: linear-gradient(${Color.yellow}, ${Color.yellow}),
          linear-gradient(${Color.yellow}, ${Color.yellow});
        background-size: 100% 5%, 0%;
        background-position: left bottom, 1px 0;
      }
    }
  }

  .ant-menu-submenu-popup {
    z-index: 2 !important;
  }

  .ant-menu-submenu {
    top: 0 !important;
    margin-top: 0 !important;
    border-bottom: none !important;
  }

  .ant-menu-submenu-title {
    color: #fff;
    padding-left: 0 !important;
    font-size: 0.875rem !important;
    height: 4rem;
    :hover {
      color: #fff !important;
    }
    .ant-menu-submenu-arrow {
      color: #fff;
      transform: translateY(-2px);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: absolute;
      top: 2.1875rem;
      right: 0px;
      width: 10px;
    }
    .ant-menu-submenu-arrow:before,
    .ant-menu-submenu-arrow:after {
      content: '';
      position: absolute;
      vertical-align: baseline;
      background: #fff;
      width: 6px;
      height: 1.5px;
      border-radius: 2px;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: rotate(45deg) translateX(2px);
    }

    .ant-menu-submenu-arrow:before {
      transform: rotate(-45deg) translateX(2px);
    }
    .ant-menu-submenu-arrow:after {
      transform: rotate(45deg) translateX(-2px);
    }
  }
  .ant-menu-submenu-open {
    .ant-menu-submenu-arrow {
      transform: translateY(-4px);
    }
    .ant-menu-submenu-arrow:before {
      transform: rotate(45deg) translateX(2px);
    }
    .ant-menu-submenu-arrow:after {
      transform: rotate(-45deg) translateX(-2px);
    }
  }
  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    border: none !important;
    transition: none !important;
    background-image: linear-gradient(${Color.yellow}, ${Color.yellow}),
      linear-gradient(${Color.yellow}, ${Color.yellow});
    background-size: calc(100% - 20px) 5%, 0%;
    background-position: left bottom, 1px 0;
  }
`;

const NavItemWrapper = styled.span``;

class TopNav extends React.PureComponent {
  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  render() {
    const { location } = this.props;
    return (
      <NavMenuWrapper id="top-nav">
        <NavMenuStyled
          mode="horizontal"
          menuData={getMenuDataWithChildren()}
          location={location}
          openDefault={false}
          onLinkClick={this.handleClose}
          getPopupContainer={() => document.getElementById('top-nav')}
        />
        <NavItemWrapper>
          <AppNotificationsIndicator />
          <UserMenu />
        </NavItemWrapper>
      </NavMenuWrapper>
    );
  }
}

TopNav.propTypes = {
  location: PropTypes.object,
  onClose: PropTypes.func,
};

export default TopNav;
