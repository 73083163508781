import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styleConstructor } from '../../utils/index';

const Wrapper = styled.div`
  text-align: center;
  display: inline-block;
`;

const MonthVertical = styled.div`
  font-size: 0.625rem;
  text-transform: uppercase;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const DayVertical = styled.div`
  font-size: 1rem;
  line-height: 1;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const MonthHorizontal = styled.span`
  font-size: 0.875rem;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const DayHorizontal = styled.span`
  font-size: 0.875rem;
  margin-left: 3px;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const YearHorizontal = styled.span`
  font-size: 0.875rem;
  margin-left: 3px;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const TransformedDateTime = styled.span`
  font-size: 0.875rem;
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const Date = props => {
  const {
    date,
    styling,
    monthStyle,
    dayStyle,
    yearStyle,
    mode,
    showYear,
    transformDateTime,
  } = props;
  const d = moment(date);

  if (transformDateTime) {
    return (
      <Wrapper>
        <TransformedDateTime styling={styling}>
          {transformDateTime(d)}
        </TransformedDateTime>
      </Wrapper>
    );
  }
  const month = d.format('MMM');
  const day = d.format('DD');
  const year = d.format('YYYY');
  return mode === 'horizontal' ? (
    <Wrapper>
      <MonthHorizontal styling={styling || monthStyle}>{month}</MonthHorizontal>
      {!showYear && (
        <DayHorizontal styling={styling || dayStyle}>{day}</DayHorizontal>
      )}
      {showYear && (
        <React.Fragment>
          <DayHorizontal styling={styling || dayStyle}>{day},</DayHorizontal>
          <YearHorizontal styling={styling || yearStyle}>{year}</YearHorizontal>
        </React.Fragment>
      )}
    </Wrapper>
  ) : (
    <Wrapper>
      <MonthVertical styling={styling || monthStyle}>{month}</MonthVertical>
      <DayVertical styling={styling || dayStyle}>{day}</DayVertical>
    </Wrapper>
  );
};

Date.propTypes = {
  date: PropTypes.string,
  styling: PropTypes.object,
  dayStyle: PropTypes.object,
  monthStyle: PropTypes.object,
  yearStyle: PropTypes.object,
  mode: PropTypes.oneOf(['vertical', 'horizontal']),
  showYear: PropTypes.bool,
  transformDateTime: PropTypes.func,
};

Date.defaultProps = {
  mode: 'vertical',
};

export default React.memo(Date);
