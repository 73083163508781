export const featureFlags = {
  COST_CENTERS: 'web.ui.costCenters',
  EXPENSE_PRINT: 'web.ui.expensePrint',
  EXPENSE_REPORTS: 'web.ui.expenseReports',
  EXPENSE_TYPES: 'web.ui.expenseTypes',
  GENERAL_LEDGER: 'web.ui.generalLedger',
  USERS_DEFAULT_APPROVER: 'web.ui.users.defaultApprover',
  INSIGHTS_PERMISSIONS: 'web.ui.insights.permissions',
  OUT_OF_POCKET: 'expense.outOfPocket',
  MILEAGE: 'expense.mileage',
  DELEGATE: 'expense.delegate',
  INTEGRATIONS: 'web.ui.integrations',
  RULES_CUSTOM_APPROVAL: 'web.ui.policies.rules.customApproval',
};

export default featureFlags;
