import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

const Wrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%; /* These two lines are counted as one :-)       */
  overflow: hidden;
  align-items: center;
  justify-content: center;

  ${media.lessThan('medium')`
    justify-content: start;
    padding: 1.625rem 0 !important;
  `};
`;

const CenteredPageTemplate = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

CenteredPageTemplate.propTypes = {
  children: PropTypes.any.isRequired,
};

export default CenteredPageTemplate;
