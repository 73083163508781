import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PromiseAllSettled from 'promise.allsettled';
import Colors from 'center-ui/themes/colors';
import { Divider, Heading, Spin, Notifier } from 'center-ui';
import API from '../../services/rest/api';
import SimpleButton from '../SimpleButton';
import ReceiptNoReceiptPlaceholder from '../Expenses/ReceiptNoReceiptPlaceholder';
import ReceiptViewModal from './ReceiptViewModal';
import ReceiptsBinPreview from './ReceiptsBinPreview';
import ReceiptBinActionBar from './ReceiptBinActionBar';
import { GET_RECEIPTS_BIN, GET_RECEIPTS } from '../../services/graphql/queries';
import {
  ATTACH_RECEIPTS_TO_EXPENSE,
  UPDATE_HAS_RECEIPTS,
} from '../../services/graphql/mutations';
import { sortData } from '../../utils';

const ReceiptLoader = styled(Spin)`
  width: 100%;
  margin-top: 1rem !important;
`;

const ReceiptsScrollable = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const HeadingStyled = styled(Heading)`
  color: ${Colors.gray};
  font-weight: normal;
`;

const DividerStyled = styled(Divider)`
  margin-left: -50% !important;
  margin-top: 16px !important;
  width: 200% !important;
`;

const ChooseFileButton = styled(SimpleButton)`
  color: ${Colors.blue};
  display: inline;
  &:hover {
    opacity: 0.8;
  }
`;

const ReceiptBinActionBarStyled = styled(ReceiptBinActionBar)`
  margin: 0 -2.5rem;
  padding: 1rem 2.5rem;
`;

const ReceiptsBinContent = ({ match, delegateOf, openFileDialog }) => {
  const variables = delegateOf ? { delegateOf } : {};
  const { data = {}, loading, error } = useQuery(GET_RECEIPTS_BIN, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const isLoading = loading || !_get(data, 'receipts', null);

  const { id: expenseId } = match.params;
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [activeReceiptId, setActiveReceiptId] = useState(null);
  const [isDeletingReceipts, setIsDeletingReceipts] = useState(false);
  const [showReceiptViewModal, setShowReceiptViewModal] = useState(null);
  const client = useApolloClient();
  const [
    attachReceiptsToExpense,
    { loading: isAttachingReceipts },
  ] = useMutation(ATTACH_RECEIPTS_TO_EXPENSE, {
    variables: {
      expenseId,
      delegateOf,
      fileIds:
        // if we are attaching from modal use that receipt id otherwise use the selected ones
        (showReceiptViewModal && [showReceiptViewModal.fileID]) ||
        selectedReceipts.map(r => r.fileID),
    },
    refetchQueries: [
      { query: GET_RECEIPTS, variables: { expenseId, delegateOf } },
    ],
  });

  const [updateHasReceipts] = useMutation(UPDATE_HAS_RECEIPTS);

  const deleteReceipt = fileID =>
    API.deleteReceipt(null, null, fileID, delegateOf);

  const handleBulkActionSuccess = fileId => {
    removeReceiptsFromBin(fileId);
    setSelectedReceipts([]);
  };

  const removeReceiptsFromBin = fileID => {
    const receiptsResult = client.readQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
    });
    const receipts = _get(receiptsResult, 'receipts', []);
    let result;
    if (fileID) {
      result = receipts.filter(r => r.fileID !== fileID);
    } else {
      result = receipts.filter(
        r => !selectedReceipts.some(({ fileID: fId }) => fId === r.fileID),
      );
    }
    client.writeQuery({
      query: GET_RECEIPTS_BIN,
      variables: { delegateOf },
      data: { receipts: result },
    });
  };

  const handleDeleteReceipt = fileID => {
    setActiveReceiptId(fileID);
    setIsDeletingReceipts(true);
    deleteReceipt(fileID)
      .then(() => {
        removeReceiptsFromBin(fileID);
        setActiveReceiptId(null);
        handleSelectReceipt(false, fileID);
      })
      .catch(() =>
        Notifier.notification({
          type: 'error',
          message: 'Delete receipt',
          description: 'Failed to delete the receipt. Please try again.',
        }),
      )
      .finally(() => {
        setShowReceiptViewModal(null);
        setIsDeletingReceipts(false);
      });
  };

  const handleDeleteReceipts = () => {
    setIsDeletingReceipts(true);

    PromiseAllSettled(
      selectedReceipts.map(({ fileID }) => deleteReceipt(fileID)),
    )
      .then(async () => {
        handleBulkActionSuccess();
      })
      .catch(() =>
        Notifier.notification({
          type: 'error',
          message: 'Delete receipt',
          description: 'Failed to delete the receipt. Please try again.',
        }),
      )
      .finally(() => {
        setIsDeletingReceipts(false);
      });
  };

  const handleSelectReceipt = (selected, fileID) => {
    if (selected) {
      return setSelectedReceipts([...selectedReceipts, { fileID }]);
    }
    return setSelectedReceipts(
      selectedReceipts.filter(r => r.fileID !== fileID),
    );
  };

  const handleAttachToExpense = () => {
    if (!expenseId) {
      return;
    }

    attachReceiptsToExpense()
      .then(() => {
        removeReceiptsFromBin(
          (showReceiptViewModal && showReceiptViewModal.fileID) || null,
        );
        setSelectedReceipts([]);
        if (showReceiptViewModal) {
          setShowReceiptViewModal(null);
        }
        const cachedReceipts = client.readQuery({
          query: GET_RECEIPTS,
          variables: { expenseId, delegateOf },
        });

        const receipts = _get(cachedReceipts, 'receipts', null);
        // if there are not receipts in the cache and we just added some
        if (receipts && receipts.length === 0) {
          updateHasReceipts({
            variables: {
              id: expenseId,
              input: {
                receiptAffidavit: {
                  receiptAffidavitAvailable: false,
                },
                hasReceipts: true,
              },
              delegateOf,
            },
          });
        }
      })
      .catch(() =>
        Notifier.notification({
          type: 'error',
          message: 'Attach receipt',
          description: 'Failed to attach receipt to expense. Please try again.',
        }),
      );
  };

  const handleShowReceiptViewModal = receipt => {
    setShowReceiptViewModal(receipt);
  };

  const renderLoading = () => <ReceiptLoader spinning />;

  const renderNoData = () => (
    <>
      <HeadingStyled as="h5">No receipts added</HeadingStyled>
      <ReceiptNoReceiptPlaceholder onClick={openFileDialog} />
    </>
  );

  const renderReceipts = receipts => {
    const groupedReceipts = _groupBy(receipts, ({ uploadDate }) =>
      moment(uploadDate)
        .startOf('day')
        .format(),
    );
    const receiptGroups = _map(groupedReceipts, (groupItems, groupName) => (
      <ReceiptsBinPreview
        key={`receipts-bin-preview-${groupName}`}
        receipts={groupItems}
        groupName={groupName}
        onDeleteReceipt={handleDeleteReceipt}
        onSelectReceipt={handleSelectReceipt}
        onViewReceipt={handleShowReceiptViewModal}
        selectedReceipts={selectedReceipts}
        activeReceiptId={activeReceiptId}
      />
    ));

    const actionsAvailable = expenseId && !!selectedReceipts.length;
    return (
      <>
        <HeadingStyled as="h5">
          Drop image or PDF to upload or{' '}
          <ChooseFileButton
            id="upload-to-receipt-bin-button"
            onClick={openFileDialog}
          >
            choose file
          </ChooseFileButton>
        </HeadingStyled>
        <DividerStyled />
        <Heading as="h4">Stored receipts</Heading>
        <ReceiptsScrollable>{receiptGroups}</ReceiptsScrollable>
        {actionsAvailable && (
          <ReceiptBinActionBarStyled
            placement="drawer"
            onAttach={handleAttachToExpense}
            onDelete={handleDeleteReceipts}
            attachingReceipt={isAttachingReceipts}
            deletingReceipt={isDeletingReceipts}
          />
        )}
      </>
    );
  };

  const sortedReceipts = useMemo(
    () => sortData(_get(data, 'receipts', []), ['uploadDate'], ['desc']),
    [data],
  );
  const hasReceipts = sortedReceipts.length > 0;

  if (error) return null;
  if (isLoading) return renderLoading();
  return hasReceipts ? (
    <>
      {renderReceipts(sortedReceipts)}
      {showReceiptViewModal && (
        <ReceiptViewModal
          receipt={showReceiptViewModal}
          onAttach={expenseId && handleAttachToExpense}
          onDelete={expenseId && handleDeleteReceipt}
          onCancel={() => handleShowReceiptViewModal(null)}
          attachingReceipt={isAttachingReceipts}
          deletingReceipt={isDeletingReceipts}
        />
      )}
    </>
  ) : (
    renderNoData()
  );
};

ReceiptsBinContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  delegateOf: PropTypes.string,
};

export default withRouter(ReceiptsBinContent);
