import React from 'react';
import styled from 'styled-components';
import Colors from 'center-ui/themes/colors';

const SimpleButtonStyled = styled.button`
  background-color: transparent;
  display: inherit;
  border: 0;
  padding: 0;
  :not([disabled]) {
    cursor: pointer;
  }

  :focus {
    outline: none;
  }

  :disabled {
    color: ${Colors.gray} !important;
  }
`;

const SimpleButton = props => <SimpleButtonStyled {...props} />;

export default SimpleButton;
