import { capitalize } from 'lodash';
import gql from 'graphql-tag';
import Storage from '../../utils/storage';
import { ERP_TYPE_CODE } from '../../utils/erp';
import { DELEGATE_USER } from '../../common/usePersistentStorage';
import { GET_USERS } from './queries';

const getData = () => {
  const token = Storage.getItem('auth');
  const delegateUser = Storage.getItem(DELEGATE_USER);
  return {
    auth: { isAuthenticated: !!token, __typename: 'Auth' },
    showMFAPendingActivities: {
      visible: true,
      __typename: 'ShowMFAPendingActivities',
    },
    showCardPendingActivities: {
      visible: true,
      __typename: 'ShowCardPendingActivities',
    },
    showAppNotificationsPanel: {
      visible: false,
      __typename: 'ShowAppNotificationsPanel',
    },
    showReceiptsBinPanel: {
      visible: false,
      __typename: 'ShowReceiptsBinPanel',
    },
    showReceipt: {
      visible: false,
      receiptViewImagesData: null,
      __typename: 'ShowReceipt',
    },
    asyncJobs: {
      jobs: [],
      hasPendingJob: false,
      message: null,
      __typename: 'AsyncJobs',
    },
    delegateUser: delegateUser ? JSON.parse(delegateUser) : null,
    componentState: {
      ID: null,
      state: null,
      __typename: 'ComponentState',
    },
  };
};

const expenseResolverBase = {
  user: (expense, _args, { cache }) => {
    try {
      const fragment = gql`
        fragment user on User {
          ID
          fullName
        }
      `;
      const data = cache.readFragment({
        id: `User:${expense.userID}`,
        fragment,
      });
      return data;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
  costCenter: (expense, _args, { cache }) => {
    try {
      const fragment = gql`
        fragment costCenter on CostCenter {
          ID
          name
        }
      `;
      const data = cache.readFragment({
        id: `CostCenter:${expense.costCenterID}`,
        fragment,
      });
      return {
        ID: expense.costCenterID,
        name: data ? data.name : expense.costCenterName,
        __typename: 'CostCenter',
      };
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
  expenseType: (expense, _args, { cache }) => {
    try {
      const fragment = gql`
        fragment expenseType on ExpenseType {
          ID
          name
        }
      `;
      const data = cache.readFragment({
        id: `ExpenseType:${expense.expenseTypeID}`,
        fragment,
      });
      return {
        ID: expense.expenseTypeID,
        name: data ? data.name : expense.expenseTypeName,
        __typename: 'ExpenseType',
      };
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
};

const costCenterResolverBase = {
  name: (cc, _args, { cache }) => {
    if (cc && !cc.ID) return null;
    if (cc && cc.name) return cc.name;
    try {
      const fragment = gql`
        fragment costCenter on CostCenter {
          ID
          name
        }
      `;
      const data = cache.readFragment({
        id: `CostCenter:${cc.ID}`,
        fragment,
      });
      return data ? data.name : null;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
  parent: (cc, _args, { cache }) => {
    try {
      const fragment = gql`
        fragment costCenter on CostCenter {
          ID
          name
        }
      `;
      const data = cache.readFragment({
        id: `CostCenter:${cc.parentID}`,
        fragment,
      });
      return data;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
  spenders: (cc, _args, { cache }) => {
    try {
      const result = cache.readQuery({
        query: GET_USERS,
        variables: { limit: -1 },
      });
      const spenders = result.users.items.filter(user =>
        cc.spenderIDs.includes(user.ID),
      );
      return spenders;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
};

const generalLedgerAccountResolverBase = {
  glAccount: gl => {
    try {
      if (!gl) return null;
      return `${gl.code} - ${gl.name}`;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
};

const delegateResolverBase = {
  fullName: user => {
    try {
      if (!user) return null;
      return `${user.firstName} ${user.lastName}`;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
};

const erpConnectorShortNames = {
  [ERP_TYPE_CODE.quickbooks]: `Quickbooks`,
};

const erpConnectorResolverBase = {
  shortName: erpConnector => {
    try {
      return erpConnectorShortNames[erpConnector.type] || null;
    } catch (err) {
      return null;
    }
  },
};

const currentApproverUserResolverBase = {
  fullName: (user, _args, { cache }) => {
    try {
      if (!user || !user.ID) return null;
      const fragment = gql`
        fragment user on User {
          ID
          fullName
        }
      `;
      const data = cache.readFragment({
        id: `User:${user.ID}`,
        fragment,
      });
      if (!data) return null;
      return data.fullName;
    } catch (err) {
      console.error(err, 'err');
      return null;
    }
  },
};

const policyRuleResolverBase = {
  statusLabel: rule => {
    const { status } = rule;
    return status ? capitalize(status) : status;
  },
};

export default {
  data: getData(),
  getData,
  resolvers: {
    Expense: expenseResolverBase,
    CostCenter: costCenterResolverBase,
    GeneralLedgerAccount: generalLedgerAccountResolverBase,
    Delegate: delegateResolverBase,
    ERPConnector: erpConnectorResolverBase,
    CurrentApproverUser: currentApproverUserResolverBase,
    PolicyRule: policyRuleResolverBase,
    Mutation: {
      setAuth: (_, { isAuthenticated }, { cache }) => {
        const data = {
          auth: {
            isAuthenticated,
            __typename: 'Auth',
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowMFAPendingActivities: (_, { visible }, { cache }) => {
        const data = {
          showMFAPendingActivities: {
            visible,
            __typename: 'ShowMFAPendingActivities',
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowCardPendingActivities: (_, { visible }, { cache }) => {
        const data = {
          showCardPendingActivities: {
            visible,
            __typename: 'ShowCardPendingActivities',
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowAppNotificationsPanel: (_, { visible }, { cache }) => {
        const data = {
          showAppNotificationsPanel: {
            visible,
            __typename: 'ShowAppNotificationsPanel',
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowReceiptsBinPanel: (_, { visible }, { cache }) => {
        const data = {
          showReceiptsBinPanel: {
            visible,
            __typename: 'ShowReceiptsBinPanel',
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowReceipt: (_, { visible, receiptViewImagesData }, { cache }) => {
        const data = {
          showReceipt: {
            visible,
            receiptViewImagesData: {
              ...receiptViewImagesData,
              __typename: 'receiptViewImagesData',
            },
            __typename: 'ShowReceipt',
          },
        };
        cache.writeData({ data });
        return null;
      },
      setAsyncJobs: async (_, { asyncJobsData }, { cache }) => {
        const { jobs, hasPendingJob, message, noop } = asyncJobsData;
        if (noop) {
          console.warning('No-op in setAsyncJobs()', { jobs });
          return null;
        }
        const data = {
          asyncJobs: {
            jobs,
            hasPendingJob,
            message,
            __typename: 'AsyncJobs',
          },
        };
        await cache.writeData({ data });
        return null;
      },
      setDelegateUser: async (_, { delegateUser: user }, { cache }) => {
        const data = {
          delegateUser: user
            ? {
                ...user,
                __typename: 'DelegateUser',
              }
            : null,
        };
        await cache.writeData({ data });
        return null;
      },
      setComponentState: (_, { id, state }, { cache }) => {
        const data = {
          componentState: {
            ID: id,
            state: JSON.stringify(state),
            __typename: 'ComponentState',
          },
        };
        cache.writeData({ data });
        return null;
      },
    },
  },
};
