import { UserRoles } from './roles';
import FeatureFlags from '../utils/featureFlags';

const { FM, EA, SP, IV } = UserRoles;

const menuData = [
  {
    id: 'expense-hub-link',
    name: 'Expense Hub',
    icon: null,
    path: 'expensehub',
    authority: [FM, EA],
  },
  {
    id: 'insights-link',
    name: 'Insights',
    icon: null,
    path: 'insights',
    authority: [FM, IV],
  },
  {
    id: 'mycard-link',
    name: 'My Card',
    icon: null,
    path: 'mycard',
    authority: [SP],
    children: [
      {
        id: 'mycard-overview-link',
        name: 'Overview',
        icon: null,
        path: 'overview',
        authority: [SP],
      },
      {
        id: 'mycard-expenses-link',
        name: 'Expenses',
        icon: null,
        path: 'expenses',
        authority: [SP],
      },
    ],
  },
  {
    id: 'centeraccount-link',
    name: 'Account',
    icon: null,
    path: 'centeraccount',
    authority: [FM],
    children: [
      {
        id: 'centeraccount-summary-link',
        name: 'Summary',
        icon: null,
        path: 'summary',
        authority: [FM],
      },
      {
        id: 'centeraccount-transactions-link',
        name: 'Transactions',
        icon: null,
        path: 'transactions',
        authority: [FM],
      },
      {
        id: 'centeraccount-statements-link',
        name: 'Statements',
        icon: null,
        path: 'statements',
        authority: [FM],
      },
    ],
  },
  {
    id: 'administration-link',
    name: 'Admin',
    icon: null,
    path: 'admin',
    authority: [FM],
    children: [
      {
        id: 'administration-users-link',
        name: 'Users',
        icon: null,
        path: 'users',
        authority: [FM],
      },
      {
        id: 'administration-costcenters-link',
        name: 'Cost Centers',
        icon: null,
        path: 'costcenters',
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.COST_CENTERS],
      },
      {
        id: 'administration-expensetypes-link',
        name: 'Expense Types',
        icon: null,
        path: 'expensetypes',
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.EXPENSE_TYPES],
      },
      {
        id: 'administration-generalledger-link',
        name: 'GL Accounts',
        icon: null,
        path: 'glaccounts',
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.GENERAL_LEDGER],
      },
      {
        id: 'administration-integrations-link',
        name: 'Integrations',
        icon: null,
        path: 'integrations',
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.INTEGRATIONS],
      },
      {
        id: 'administration-rules-customapproval-link',
        name: 'Custom Approval Rules',
        icon: null,
        path: 'rules/customapprovals',
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.RULES_CUSTOM_APPROVAL],
      },
    ],
  },
];

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
const isUrl = path => reg.test(path);

function formatter(data, parentPath = '/', parentAuthority) {
  return data.map(item => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(
        item.children,
        `${parentPath}${item.path}/`,
        item.authority,
      );
    }
    return result;
  });
}

function getCurrentMenuItem(location) {
  const menu = formatter(menuData);
  const pathSections = location.pathname.split('/');
  const firstPathSection = pathSections.length > 1 ? pathSections[1] : '';
  return menu.find(item => item.path === `/${firstPathSection}`);
}

export const getMenuDataWithChildren = () => formatter(menuData);

export const getMenuTitleForLocation = location => {
  const currentItem = getCurrentMenuItem(location);
  if (currentItem) {
    return currentItem.name;
  }
  return '';
};

export const getMenuDataForLocation = location => {
  const currentItem = getCurrentMenuItem(location);
  if (currentItem) {
    return formatter(currentItem.children || [currentItem], '');
  }
  return [];
};
