import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import InputNumber from '../InputNumber';
import { formatNumber } from '../../utils';

let init = false;
let currencySymbol = '';
let thousandsSeparator = ',';

const parseInput = (value, re = /[^\d.-]/g) => value.replace(re, '');

// Source: http://blog.stevenlevithan.com/archives/commafy-numbers
const commafy = num =>
  `${num}`.replace(
    /(^|[^\w.])(\d{4,})/g,
    ($0, $1, $2) =>
      $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, `$&${thousandsSeparator}`),
  );

const formatInput = (value, formatNumberParams) => {
  const { prefix, suffix, precision, currencyCode } = formatNumberParams || {};
  if (!init) {
    // Formatting the value using JS Intl.NumberFormat
    // We reference formattedValue, but don't use it directly
    const formattedValue = formatNumber({
      value: 1234567.89, // init value only
      currencyCode,
      decimalPrecision: precision,
      style: currencyCode ? 'currency' : null,
      currencyDisplay: currencyCode ? 'symbol' : null,
      transform: v => v,
    });
    const delimiters = formattedValue.match(/(\D)/g) || '';
    currencySymbol = currencyCode ? formattedValue.match(/^(\D*)/g) : '';
    thousandsSeparator = delimiters[delimiters.length - 3];
    init = true;
  }
  const fVal = commafy(value);
  return `${prefix || ''}${currencySymbol}${fVal}${suffix || ''}`;
};

class InputCurrencyNumber extends React.PureComponent {
  render = () => {
    const {
      formatNumberParams,
      formatter,
      parser,
      centsOnly,
      ...props
    } = this.props;
    const { precision = 2 } = formatNumberParams || {};
    if (!init) formatInput(null, formatNumberParams);
    if (centsOnly)
      return (
        <Input type="number" addonBefore={`${currencySymbol}0.`} {...props} />
      );
    return (
      <InputNumber
        formatter={value => formatInput(value, formatNumberParams)}
        parser={parseInput}
        precision={precision}
        {...props}
      />
    );
  };
}

InputCurrencyNumber.propTypes = {
  formatter: PropTypes.func,
  parser: PropTypes.func,
  centsOnly: PropTypes.bool,
  formatNumberParams: PropTypes.shape({
    currencyCode: PropTypes.string,
    precision: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
  }),
};

InputCurrencyNumber.defaultProps = {
  // TODO: Require parent pass valid currency code
  formatNumberParams: {
    currencyCode: 'USD',
    precision: 2,
    prefix: '',
    suffix: '',
  },
  formatter: formatInput,
  parser: parseInput,
  centsOnly: false,
};

export default InputCurrencyNumber;
