import React from 'react';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Tabs } from 'center-ui';
import Color from 'center-ui/themes/colors';
import { MQBreakPoints } from 'core-ui/utils/consts';
import { GET_APP_NOTIFICATIONS_COUNT } from '../../services/graphql/queries';
import AlertsList from './AlertsList';
// import PendingActionsList from './PendingActionsList';

const { DESKTOP } = MQBreakPoints;
const { TabPane } = Tabs;

const ALERTS_KEY = 'alerts';
// const PENDING_ACTIONS_KEY = 'pendingActions';
const LABELS = {
  ALERTS: 'Alerts',
  // PENDING_ACTIONS: 'Pending Actions',
};

const media = generateMedia({
  medium: `${DESKTOP.minWidth}px`,
});

const UPDATE_ALERT_READ_STATUS = gql`
  mutation updateAlertsReadStatus($ids: [ID]!, $isRead: Boolean!) {
    updateAlertsReadStatus(ids: $ids, isRead: $isRead) {
      ID
      isRead
    }
  }
`;

const TabsStyled = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 !important;
  height: calc(100vh - 100px);
  ${media.greaterThan('medium')`
    height: calc(100vh - 220px);
  `};
  .ant-tabs-tab {
    font-weight: 400 !important;
    padding: 0 !important;
    margin: 0 1.5rem 0 0 !important;
  }
  .ant-tabs-bar {
    margin: 1rem 1rem !important;
    ${media.greaterThan('medium')`
      margin: 0 3rem !important;
    `};
  }
  .ant-tabs-nav {
    color: ${Color.gray};
  }
  .ant-tabs-tab-active {
    color: ${Color.blue} !important;
    padding-bottom: 0.5rem !important;
  }
  .ant-tabs-ink-bar {
    background-color: ${Color.blue};
  }
`;

const TabPaneStyled = styled(TabPane)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem !important;
  ${media.greaterThan('medium')`
    padding: 1rem 3rem !important;
  `};
`;

class AppNotificationsTabs extends React.PureComponent {
  state = {
    currentMenuItem: ALERTS_KEY,
  };

  handleMarkRead = (notificationIDs, updateAlertsReadStatus) => {
    updateAlertsReadStatus({
      variables: { ids: notificationIDs, isRead: true },
    });
  };

  renderTab = params => {
    const { key, label, content } = params;
    return (
      <TabPaneStyled key={key} tab={label}>
        {content}
      </TabPaneStyled>
    );
  };

  render = () => {
    const { currentMenuItem } = this.state;
    return (
      <Mutation
        mutation={UPDATE_ALERT_READ_STATUS}
        refetchQueries={[{ query: GET_APP_NOTIFICATIONS_COUNT }]}
        ignoreResults
      >
        {updateAlertsReadStatus => (
          <TabsStyled
            defaultActiveKey={currentMenuItem}
            onChange={this.handleClick}
          >
            {this.renderTab({
              key: ALERTS_KEY,
              label: LABELS.ALERTS,
              content: (
                <AlertsList
                  onMarkRead={notifications =>
                    this.handleMarkRead(notifications, updateAlertsReadStatus)
                  }
                />
              ),
            })}
          </TabsStyled>
        )}
      </Mutation>
    );
  };
}

export default AppNotificationsTabs;
