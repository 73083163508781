import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import loadimage from 'blueimp-load-image';
import Colors from 'center-ui/themes/colors';
import { Spin } from 'center-ui';
import { getUriWithCorsProxy } from '../../services/rest/api';

const Receipt = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  canvas {
    width: 100%;
  }
`;

const ReceiptImgSkeleton = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${Colors.gray_5};
`;

const ReceiptImgLoadingSpinner = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 30%;
  padding-bottom: 30%;
  text-align: center;
  flex: none;
`;

class ReceiptImg extends React.PureComponent {
  imageCanvas = React.createRef();

  loader = React.createRef();

  componentDidMount() {
    const { onClick, src, backgroundSize, aspectRatio } = this.props;
    this.handleLoadImage({ onClick, src, backgroundSize, aspectRatio });
  }

  componentDidUpdate(prevProps) {
    const { src: prevSrc } = prevProps;
    const { onClick, src, backgroundSize, aspectRatio } = this.props;

    if (prevSrc !== src) {
      const node = this.imageCanvas.current;
      if (node.firstChild) {
        node.removeChild(node.firstChild);
      }
      this.handleLoadImage({ onClick, src, backgroundSize, aspectRatio });
    }
  }

  handleLoadImage = ({ onClick, src, backgroundSize, aspectRatio }) => {
    const imageSrc = src.startsWith('data:') ? src : getUriWithCorsProxy(src);
    this.loader.current.style.display = 'block';
    loadimage(
      imageSrc,
      img => {
        if (img && img.style && this.imageCanvas.current) {
          if (this.loader.current) {
            this.loader.current.style.display = 'none';
          }
          img.style.maxWidth = '100%';
          img.style.maxHeight = '100%';
          img.onClick = onClick;
          this.imageCanvas.current.appendChild(img);
        }
      },
      {
        canvas: true,
        aspectRatio,
        orientation: true,
        ...(backgroundSize === 'contain' ? { contain: true } : {}),
        ...(backgroundSize === 'cover' ? { cover: true } : {}),
      },
    );
  };

  renderLoading = showLoadingSpinner =>
    showLoadingSpinner ? (
      <ReceiptImgLoadingSpinner ref={this.loader}>
        <Spin spinning />
      </ReceiptImgLoadingSpinner>
    ) : (
      <ReceiptImgSkeleton
        data-testid="receipt-img-skeleton"
        ref={this.loader}
      />
    );

  render() {
    const { src, showLoadingSpinner, ...rest } = this.props;
    return (
      <>
        {this.renderLoading(showLoadingSpinner)}
        <Receipt {...rest} ref={this.imageCanvas} />
      </>
    );
  }
}

ReceiptImg.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  backgroundSize: PropTypes.oneOf(['contain', 'cover']),
  aspectRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showLoadingSpinner: PropTypes.bool,
};

ReceiptImg.defaultProps = {
  backgroundSize: 'contain',
  aspectRatio: null,
  showLoadingSpinner: true,
};

export default ReceiptImg;
