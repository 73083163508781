import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table as AntTable } from 'antd';
import media from 'styled-media-query';
import TablePure from './TablePure';
import BodyPure from './BodyPure';
import RowPure from './RowPure';
import CellPure from './CellPure';
import Heading from '../Heading';
import Button from '../Button';
import Colors from '../../themes/colors';
import { generateRandomString } from '../../utils';
import { withEmptyState } from '../../common/hoc';
import ExpandIcon from './ExpandIcon';

const ButtonStyled = styled(Button)`
  margin: auto !important;
  display: block !important;
`;

const TableStyled = styled(AntTable)`
  .ant-table-content {
    padding: 0 2px;
    margin: 0 -2px;
  }

  .ant-table-body {
    .ant-table-expanded-row,
    .ant-table-expanded-row:hover,
    .ant-table-expanded-row:hover td {
      background: transparent !important;
    }

    /* Removes gray background when column is sorted */
    .ant-table-row:not(:hover) {
      .ant-table-column-sort {
        background: transparent !important;
      }
    }
  }

  .ant-table-footer {
    background: transparent !important;
    ${media.lessThan('medium')`
         padding-left: 0 !important;
       }
    `};
  }

  thead {
    line-height: 1.125rem;
    th {
      background-color: transparent !important;
      padding-bottom: 0.625rem !important;
      font-weight: 400 !important;
      color: ${Colors.gray} !important;
    }
    tr {
      background: transparent !important;
    }
  }

  thead > tr:hover > td,
  tbody > tr:hover > td {
    background: ${Colors.gray_7} !important;
  }

  td,
  th {
    white-space: nowrap;
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
    ${media.lessThan('large')`
       &:first-child {
         padding-left: 0 !important;
       }
       &:last-child {
         padding-right: 0 !important;
       }
    `};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f8f8fa !important;
  }

  .ant-table-row-selected:not(:hover) td {
    background: transparent !important;
  }

  .ant-table-row-level-0 {
    ${media.greaterThan('small')`
      box-sizing: border-box !important;
      td:first-child {
        border-left: 2px solid transparent !important;
      }
      :hover {
        td:first-child {
          border-left: 2px solid ${Colors.blue} !important;
        }
      }
    `};
  }

  .ant-table-title {
    font-weight: 700 !important;
    font-size: 0.875rem;
  }

  .ant-table-column-sorter .ant-table-column-sorter-inner-full {
    margin-top: -0.25rem !important;
  }

  .ant-table-placeholder {
    border-bottom: none !important;
  }

  .ant-table-column-title {
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-size: 0.625rem !important;
  }

  .ant-pagination {
    .ant-pagination-total-text {
      color: ${Colors.gray} !important;
      font-size: 0.75rem;
    }
    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: none !important;
    }
    .ant-pagination-prev {
      margin-right: -1px !important;
      position: relative !important;
      z-index: 1;
      :hover,
      :focus {
        z-index: 2;
      }
    }
    .ant-pagination-next {
      position: relative !important;
      z-index: 1;
      :hover,
      :focus {
        z-index: 2;
      }
    }

    .ant-pagination-simple-pager {
      input {
        margin-left: 1px !important;
      }
    }
  }

  .ant-pagination:first-child {
    margin-top: 0 !important;
  }
`;

const Table = ({
  id,
  className,
  title,
  footer,
  onLoadMore,
  fetchingMore,
  dataSource,
  loading,
  pagination,
  onPaginationClick,
  ...otherProps
}) => {
  const handlePaginationChange = newPage => {
    if (onPaginationClick) {
      onPaginationClick(newPage);
    }
  };
  const renderTitle = () => {
    if (typeof title === 'string') {
      return <Heading as="h5">{title}</Heading>;
    }
    return title;
  };
  const renderFooter = () => {
    const showLoadMoreBtn = onLoadMore && dataSource && dataSource.length > 0;
    if (footer || onLoadMore) {
      return (
        <React.Fragment>
          {footer && footer()}
          {showLoadMoreBtn && (
            <ButtonStyled
              id={`${id}-load-more-button`}
              type="primary"
              onClick={onLoadMore}
              loading={fetchingMore}
            >
              Load more
            </ButtonStyled>
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  const isEmpty = dataSource && dataSource.length === 0;

  const components = {
    table: TablePure,
    body: {
      wrapper: BodyPure,
      row: RowPure,
      cell: CellPure,
    },
  };

  return (
    <TableStyled
      id={id}
      className={className}
      title={title ? () => renderTitle() : null}
      footer={footer || onLoadMore ? () => renderFooter() : null}
      loading={loading && !fetchingMore}
      dataSource={dataSource}
      showHeader={!isEmpty}
      components={components}
      expandIcon={ExpandIcon}
      pagination={
        pagination && {
          ...pagination,
          hideOnSinglePage: true,
          onChange: handlePaginationChange,
        }
      }
      {...otherProps}
    />
  );
};

Table.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  title: PropTypes.any,
  footer: PropTypes.any,
  onLoadMore: PropTypes.func,
  onPaginationClick: PropTypes.func,
  fetchingMore: PropTypes.bool,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
};

Table.defaultProps = {
  id: generateRandomString('table'),
  pagination: false,
};

export default withEmptyState(React.memo(Table));
