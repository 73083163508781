import { Tabs as AntTabs } from 'antd';
import styled from 'styled-components';
import Colors from '../../themes/colors';

const Tabs = styled(AntTabs)`
  .ant-tabs-bar {
    margin: 0 0 1.5rem 0 !important;
  }
  .ant-tabs-tab {
    font-weight: 400 !important;
    padding: 0 !important;
  }
  .ant-tabs-nav {
    color: ${Colors.gray};
  }
  .ant-tabs-tab-active {
    color: ${Colors.blue} !important;
    padding-bottom: 0.5rem !important;
  }
  .ant-tabs-ink-bar {
    background-color: ${Colors.blue};
  }

  .ant-list-item-meta-description {
    color: ${Colors.gray} !important;
  }
`;

Tabs.defaultProps = {
  tabBarGutter: 30,
};

export default Tabs;
