import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import Spin from '../Spin';
import Colors from '../../themes/colors';

const CardWrapper = styled(AntCard)`
  border-color: ${Colors.gray_6} !important;
  .ant-card-head {
    border-bottom: none;
    .ant-card-head-title {
      font-size: 0.875rem;
      font-weight: 700;
      padding: 1.5rem 0 0 0;
    }
  }

  .ant-card-body {
    padding: ${({ bordered }) => (bordered ? '1.5rem' : '0')};
  }
`;

const Card = props => {
  const { loading, blur, children, ...cardProps } = props;
  return (
    <Spin
      data-testid="card-loading-spin"
      spinning={loading}
      bordered
      blur={blur}
    >
      <CardWrapper {...cardProps}>{children}</CardWrapper>
    </Spin>
  );
};

Card.propTypes = {
  loading: PropTypes.bool,
  blur: PropTypes.bool,
  children: PropTypes.node,
  bordered: PropTypes.bool,
};

Card.defaultProps = {
  loading: false,
  blur: false,
  bordered: true,
};

export default Card;
