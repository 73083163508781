import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Vars from '../../themes';
import { styleConstructor } from '../../utils';

const Label = styled.label`
  line-height: 1.25rem;
  color: ${Vars.PRIMARY_COLOR};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const Text = styled.div`
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${Vars.PRIMARY_COLOR};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const OverlineLabelStyled = styled.div`
  display: block;
  text-align: ${({ align }) => align};
`;

const OverlineLabel = props => {
  const { label, text, align, invert, styleLabel, styleText, ...rest } = props;
  const labelId = typeof label === 'string' ? label : null;
  return invert ? (
    <OverlineLabelStyled align={align} {...rest}>
      <Text id={labelId} styling={styleText}>
        {text}
      </Text>
      <Label htmlFor={labelId} styling={styleLabel}>
        {label}
      </Label>
    </OverlineLabelStyled>
  ) : (
    <OverlineLabelStyled align={align} {...rest}>
      <Label htmlFor={labelId} styling={styleLabel}>
        {label}
      </Label>
      <Text id={labelId} styling={styleText}>
        {text}
      </Text>
    </OverlineLabelStyled>
  );
};

OverlineLabel.propTypes = {
  label: PropTypes.any,
  text: PropTypes.any,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  invert: PropTypes.bool,
  styleLabel: PropTypes.object,
  styleText: PropTypes.object,
};

OverlineLabel.defaultProps = {
  align: 'left',
  invert: false,
};

OverlineLabel.Label = Label;
OverlineLabel.Text = Text;

export default OverlineLabel;
