import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import memoize from 'memoize-one';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { List, Button } from 'center-ui';
import ComponentError from 'core-ui/components/widgets/ComponentError';
import {
  PAGINATION_FRAGMENT,
  CARD_FRAGMENT,
} from '../../services/graphql/fragments';
import AlertsItem from './AlertsItem';
import { pollInterval } from '../../utils/appNotifications';

const limit = 25;

const GET_APP_NOTIFICATIONS = gql`
  query alerts($limit: Int, $offset: String) {
    alerts(limit: $limit, offset: $offset) @connection(key: "alerts") {
      alertsCount
      actionsCount
      items {
        ID
        text
        isRead
        sentDateTime
        subjectCode
        meta
      }
      pagination {
        ...PaginationFragment
      }
    }
    me {
      ID
      cardItems {
        ...CardFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${CARD_FRAGMENT}
`;

const ListStyled = styled(List)`
  overflow-y: scroll;
  height: calc(100vh - 300px);
`;

const ButtonStyled = styled(Button)`
  margin: auto !important;
  display: block !important;
  margin-bottom: 3.5rem !important;
`;

class AlertsList extends React.PureComponent {
  fetchMoreLoading = false;

  alertItems = [];

  unreadItemIDs = [];

  componentWillUnmount = () => {
    const { onMarkRead } = this.props;
    this.getUnreadItemIDs(this.alertItems);
    if (this.unreadItemIDs.length > 0 && onMarkRead) {
      onMarkRead(this.unreadItemIDs);
    }
  };

  getUnreadItemIDs = items => {
    items.forEach(item => {
      if (!item.isRead) {
        this.unreadItemIDs.push(item.ID);
      }
    });
  };

  renderListItem = item => <AlertsItem {...item} />;

  renderLoadMoreButton = (canLoadMore, onLoadMore) => {
    if (!canLoadMore) return null;
    return (
      <ButtonStyled
        id="alerts-load-more-button"
        type="primary"
        onClick={() => onLoadMore()}
        loading={this.fetchMoreLoading}
      >
        Load more
      </ButtonStyled>
    );
  };

  render = () => (
    <Query
      query={GET_APP_NOTIFICATIONS}
      variables={{ limit }}
      notifyOnNetworkStatusChange
      fetchPolicy="network-only"
      pollInterval={pollInterval}
    >
      {({ loading, error, data = {}, fetchMore }) => {
        if (error) return <ComponentError />;
        const handleLoadMore = (limitItems = limit) => {
          this.fetchMoreLoading = true;
          return fetchMore({
            variables: {
              offset: data.alerts.pagination.offset,
              limit: limitItems,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              const prevItems = prev.alerts.items;
              const result = {
                alerts: {
                  ...fetchMoreResult.alerts,
                  items: [...prevItems, ...fetchMoreResult.alerts.items],
                },
              };
              this.fetchMoreLoading = false;
              return result;
            },
          });
        };
        const canLoadMore = !!(
          data &&
          data.alerts &&
          data.alerts.pagination.next
        );
        const isLoading = (!data || !data.alerts) && loading;
        const alertItems = (data && data.alerts && data.alerts.items) || [];

        // Memoize alert items to later dispach mark read status
        this.alertItems = memoize(items => items)(alertItems);

        return (
          <ListStyled
            dataSource={alertItems}
            loading={isLoading}
            loadMore={this.renderLoadMoreButton(canLoadMore, handleLoadMore)}
            renderItem={this.renderListItem}
            emptyStateParams={{ description: 'No alerts yet' }}
          />
        );
      }}
    </Query>
  );
}

AlertsList.propTypes = {
  onMarkRead: PropTypes.func,
};

export default AlertsList;
