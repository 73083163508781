import React from 'react';
import PropTypes from 'prop-types';

class CellPure extends React.PureComponent {
  render = () => {
    const { children, ...rest } = this.props;
    return <td {...rest}>{children}</td>;
  };
}

CellPure.propTypes = {
  children: PropTypes.any,
};

export default CellPure;
