export const ERP_TYPE_CODE = {
  mock: 'mock',
  netsuite: 'netsuite',
  quickbooks: 'quickbooks',
};

export const ERP_SOURCE_TYPES = {
  costcenters: 'costcenters',
  expensetypes: 'expensetypes',
};

export const getConnector = (connectors, erpType) =>
  erpType &&
  ERP_TYPE_CODE[erpType] &&
  connectors &&
  connectors.find(c => c.type === erpType);

export default { ERP_TYPE_CODE, getConnector };
