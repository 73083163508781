import _forIn from 'lodash/forIn';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _isArray from 'lodash/isArray';
import moment from 'moment-timezone';
import { MIME_TYPES } from './constants';
import {
  EXPENSE_DETAILS_VIEW,
  MY_OUT_OF_POCKET_EDIT,
  MYCARD,
  MYCARD_EXPENSES,
  MYCARD_OVERVIEW,
} from './routes';

export * from './cards';

export const sortData = (data, keys, orders) => {
  if (keys && _isArray(data)) {
    return _orderBy(data, keys, orders);
  }
  return data;
};

// Eg: partitionData(items, {status: ['Pending', '* as Posted']})
// …: partitionData(COLLECTION, {ITEM.KEY to match: ARRAY_OF['VALUE of property to partition by', 'OLD_MATCH as NEW_MATCH, or default with *']})
export const partitionData = (data = [], keys = {}) => {
  const result = {};
  const matchKeys = {};
  // Helper function to init vars above
  const setProperty = (str, key) => {
    // Regex to determine if string has 'a as b' pattern
    const re = /^(.*)\sas\s(.*)/gi;
    // const re = /(?<matchString>.*)\sas\s(?<storeString>.*)/gi;
    const p = re.exec(str);
    if (!p) {
      // if (!p || (p && !p.groups)) {
      // If not a as b, just init the result[str] and set a key=str on matchKeys
      result[str] = [];
      matchKeys[key][str] = str;
    } else {
      // Build minifier can't handle >ES6; adapting here
      const [, matchString, storeString] = p;
      // In this case, we init the result[b] and set the matchKeys as a=b to lookup the result key
      result[storeString] = [];
      matchKeys[key][matchString] = storeString;
    }
  };
  _forIn(keys, (vals, key) => {
    // Iterate through given keys to init result with structure with arrays to push items to
    matchKeys[key] = {};
    _map(vals, val => setProperty(val, key));
  });
  _map(data, item => {
    // Iterate through data, pushing matching items to appropriate result bucket
    _forIn(keys, (_, key) => {
      // This is a nested call N*M times, but keys will likely be a small set to find matching fields of a given item
      const itemKey = _get(item, key, null);
      if (itemKey) {
        const matchKeyLookup = _get(_get(matchKeys, key, {}), itemKey, null);
        if (matchKeyLookup) {
          result[matchKeys[key][itemKey]].push(item);
        } else if (matchKeys[key]['*']) {
          result[matchKeys[key]['*']].push(item);
        }
      }
    });
  });
  return result;
};
// SOURCE: https://github.com/kennethjiang/js-file-download
export const fileDownload = (data, fileName, mime) => {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }
};

export const getHumanizedDate = (
  date,
  defaultFormat = 'dddd MMM D h:mm A',
  ignoreTime = false,
) => {
  const now = moment();
  const mDate = moment(date);
  const elapsed = moment.duration(mDate.diff(now));
  const diffHrs = mDate.diff(now, 'hours');
  const within24Hrs = Math.abs(diffHrs) < 24; // 24 hrs
  const calVars = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    sameElse: defaultFormat,
  };
  const humanTime =
    within24Hrs && !ignoreTime
      ? elapsed.humanize(true)
      : mDate.calendar(null, calVars);
  return humanTime;
};

/* Takes a string template and populates values.
   Supports optional functions to transform values.
   Returns a string unless a supplied function creates a non-string element (eg JSX node), in which case a series of nodes is returned
*/
export const constructLabelTemplate = (string, vars, transformFuncs = {}) => {
  if (!string || !vars) {
    console.error('Cannot construct string: ', { string, vars });
    return '';
  }
  let hasNonStringElement = false;
  const re = /\$\{(.+?)\}/g; // Finds all template variables to replace (eg: ${var})
  const varsInString = string.match(re).map(v => v.slice(2, -1)); // Array of keys to loop through
  let constructedString = string;
  const labelElements = string.split(/(\${\b})|(\b)/);
  // Helper funcs
  const isString = v => typeof v === 'string';
  // For string elements, we strip out [${}] chars
  const processStringElement = e => {
    const s = e.replace('${', '').replace('}', '');
    return `${s}`;
  };
  // Process all keys in string template
  _forEach(varsInString, key => {
    const val = vars[key];
    const element = transformFuncs[key] ? transformFuncs[key](val, vars) : val;
    let elementIdx = labelElements.indexOf(key);
    if (elementIdx > 0 && labelElements[elementIdx - 1] === '${')
      elementIdx = -1;
    if (isString(element) && !hasNonStringElement) {
      constructedString = constructedString.replace(`\${${key}}`, element);
    } else {
      hasNonStringElement = true;
    }
    if (elementIdx >= 0) labelElements[elementIdx] = element;
  });
  // Returns either a string or series of nodes
  return hasNonStringElement
    ? labelElements.map(e => (isString(e) ? processStringElement(e) : e))
    : constructedString;
};

/* Returns a moment array of two moment dates. Default params return start/end times of 1 month prior through 23:59:59 today.
 */
export const getDefaultDateRange = (
  startOn = moment().startOf('day'),
  // Init offset interval to -1 month
  offset = { int: -1, unit: 'month' },
) => {
  const startDate = moment(startOn).add(offset.int, offset.unit);
  const endDate = moment(startOn).endOf('day');
  const range = [startDate, endDate];
  // eg return [moment(start of today -1 month), moment(end of today)]
  return range;
};

export const getFormattedDateRange = dateRange => {
  const [startDate, endDate] = dateRange;
  return { from: startDate.format(), to: endDate.format() };
};

export const browserDetection = () => {
  const isGoogleBot =
    navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1;
  // eslint-disable-next-line
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0;
  const isChrome =
    !isGoogleBot &&
    !isEdge &&
    !isOpera &&
    !!window.chrome &&
    (!!window.chrome.webstore ||
      navigator.vendor.toLowerCase().indexOf('google inc.') !== -1);
  const isSafari =
    !isChrome && navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
  const isBlink = (isChrome || isOpera) && !!window.CSS;
  let browser;

  if (isIE) {
    browser = 'IE';
  } else if (isEdge) {
    browser = 'Edge';
  } else if (isFirefox) {
    browser = 'Firefox';
  } else if (isOpera) {
    browser = 'Opera';
  } else if (isChrome) {
    browser = 'Chrome';
  } else if (isSafari) {
    browser = 'Safari';
  } else if (isBlink) {
    browser = 'Blink';
  } else if (isGoogleBot) {
    browser = 'Googlebot';
  } else {
    browser = 'Unknown';
  }
  return browser;
};

export const paginate = (array, pageNumber, pageSize) =>
  array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

/**
 * Turns an array of objects into a string
 * @param array
 * @returns {*}
 */
export const stringifyObjectArrayByProperty = (
  array,
  objectProperty,
  separator,
) => {
  separator = separator || ':';
  return array.reduce(
    (accumulator, currentValue) =>
      accumulator
        ? `${accumulator}${separator}${currentValue[objectProperty]}`
        : `${currentValue[objectProperty]}`,
    '',
  );
};

/**
 * Generate a random integer
 * @param min
 * @param max
 * @returns {number}
 */
export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * @returns {string}
 */
export const generateAvatar = () => {
  const gender = getRandomIntInclusive(1, 2) === 1 ? 'men' : 'women';
  const face = getRandomIntInclusive(0, 99);
  return `https://randomuser.me/api/portraits/${gender}/${face}.jpg`;
};

export const getIsPDF = mime => mime === MIME_TYPES.pdf;

export const getUploadMimeTypes = (asArray = false) => {
  const { png, jpg, gif, pdf } = MIME_TYPES;
  const acceptMimes = [png, jpg, gif, pdf];
  return asArray ? acceptMimes : acceptMimes.join(',');
};

export const shouldUseDelegateContext = path =>
  path === MYCARD ||
  path === MYCARD_OVERVIEW ||
  path === MYCARD_EXPENSES ||
  path === MY_OUT_OF_POCKET_EDIT ||
  path === EXPENSE_DETAILS_VIEW;
