import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { Link as RouteLink } from 'react-router-dom';
import Colors from 'center-ui/themes/colors';
import { styleConstructor } from 'center-ui/utils';
import rightArrowImg from '../../assets/imgs/right-arrow-link.svg';
import upArrowImg from '../../assets/imgs/up-arrow-link.svg';

const slideRight = keyframes`
  from { transform:translateX(0px); }
  to { transform:translateX(4px); }
`;

const slideUp = keyframes`
  from { transform:translateY(0px); }
  to { transform:translateY(-2px); }
`;

const Wrapper = styled.span`
  display: inline-block;
  color: ${Colors.blue};
  :hover {
    opacity: ${({ arrowDirection }) => (arrowDirection ? 1 : 0.8)};
  }
`;

const LinkStyled = styled(RouteLink)`
  ${({ styling }) =>
    styleConstructor({
      fontWeight: 400,
      color: Colors.blue,
      ...styling,
    })};
  :hover {
    color: ${Colors.blue};
  }
`;

const AnchorStyled = styled.a`
  ${({ styling }) =>
    styleConstructor({
      color: Colors.blue,
      ...styling,
    })};
  :hover {
    color: ${Colors.blue};
  }
`;

const RightArrow = styled.img`
  display: inline-block;
  margin-left: 6px;
  vertical-align: text-bottom;
  ${Wrapper}:hover & {
    animation: ${slideRight} 0.2s linear forwards;
  }
`;

const UpArrow = styled.img`
  display: inline-block;
  margin-left: 6px;
  ${Wrapper}:hover & {
    animation: ${slideUp} 0.2s linear forwards;
  }
`;

const Button = styled.button`
  ${({ styling }) =>
    styleConstructor({
      backgroundColor: 'transparent',
      display: 'inherit',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      ...styling,
    })};
  :hover {
    opacity: 0.9;
  }
  :focus {
    outline: none;
  }
`;

const LaodingSpin = styled(Icon)`
  margin-right: 0.25rem;
`;

const Link = ({
  children,
  to,
  href,
  onClick,
  arrowDirection,
  loading,
  className,
  ...rest
}) => {
  const rightArrow =
    arrowDirection === 'right' ? (
      <RightArrow
        alt="Right Arrow"
        src={rightArrowImg}
        width={18}
        height={16}
      />
    ) : (
      ''
    );

  const upArrow =
    arrowDirection === 'up' ? (
      <UpArrow alt="Up Arrow" src={upArrowImg} height={18} />
    ) : (
      ''
    );

  const renderLink = () => {
    if (to) {
      return (
        <LinkStyled to={to} {...rest}>
          {children}
          {rightArrow}
          {upArrow}
        </LinkStyled>
      );
    }

    if (href) {
      return (
        <AnchorStyled href={href} {...rest}>
          {children}
          {rightArrow}
          {upArrow}
        </AnchorStyled>
      );
    }

    if (onClick) {
      return (
        <>
          {loading && <LaodingSpin type="loading" />}
          <Button onClick={onClick} {...rest}>
            {children}
            {rightArrow}
            {upArrow}
          </Button>
        </>
      );
    }
    return null;
  };
  return (
    <Wrapper className={className} arrowDirection={arrowDirection}>
      {renderLink()}
    </Wrapper>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  arrowDirection: PropTypes.oneOf(['right', 'up']),
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default Link;
