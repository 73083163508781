import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Toolbar = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 72px;
  line-height: 72px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0 24px;
  z-index: 9;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

const LeftSide = styled.div`
  float: left;
  button + button {
    margin-left: 8px;
  }
`;

const RightSide = styled.div`
  float: right;
`;

class FooterToolbar extends React.PureComponent {
  render() {
    const { children, extra, ...restProps } = this.props;
    return (
      <Toolbar {...restProps}>
        <LeftSide>{children}</LeftSide>
        <RightSide>{extra}</RightSide>
      </Toolbar>
    );
  }
}

FooterToolbar.propTypes = {
  children: PropTypes.any,
  extra: PropTypes.any,
};

export default FooterToolbar;
