import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import styled from 'styled-components';

const { Option, OptGroup } = AntSelect;

const SelectStyled = styled(AntSelect)`
  .ant-select-selection__choice {
    background-color: rgba(0, 79, 224, 0.1) !important;
    border-color: rgba(0, 79, 224, 0.1) !important;
  }
`;

const Select = ({ getScrollableArea, ...rest }) => {
  const [open, setOpen] = useState(rest.defaultOpen);

  // ComponentDidMount
  useEffect(() => {
    if (getScrollableArea) {
      getScrollableArea().addEventListener('scroll', closeMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ComponentWillUnmount
  useEffect(
    () => () => {
      if (getScrollableArea) {
        getScrollableArea().removeEventListener('scroll', closeMenu);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const closeMenu = () => {
    setOpen(false);
  };
  return (
    <SelectStyled
      notFoundContent="No results found"
      {...rest}
      open={open}
      onDropdownVisibleChange={isOpen => setOpen(isOpen)}
    />
  );
};

Select.propTypes = {
  getScrollableArea: PropTypes.func,
};

Select.Option = Option;
Select.OptGroup = OptGroup;

export default Select;
