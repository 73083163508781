import _intersectionWith from 'lodash/intersectionWith';

export const userHasFeatures = (userFeatures, featuresRequired = []) => {
  if (!featuresRequired || featuresRequired.length === 0) {
    return true;
  }

  return (
    _intersectionWith(userFeatures, featuresRequired, (u, f) => u === f)
      .length > 0
  );
};
