import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colors from 'center-ui/themes/colors';
import { Icon } from 'center-ui';
import SimpleButton from '../SimpleButton';

import noReceiptIcon from '../../assets/imgs/no-receipt.svg';

const NoReceiptWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.white};
  text-align: center;
  display: flex;
`;

const ReceiptMessageWrapper = styled.div`
  text-align: center;
  margin: auto;
`;

const NoReceiptIconWrapper = styled.div`
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  border: 1px solid ${Colors.gray_3};
  margin-bottom: 1rem;
  color: ${Colors.gray_3};
`;

const LoadingIcon = styled(Icon)`
  margin: auto;
  font-size: 2.5rem;
`;

const NoReceiptIcon = styled.img`
  margin: auto;
  width: 2.125rem;
  height: 2.5rem;
`;

const SimpleBlueButton = styled(SimpleButton)`
  color: ${Colors.blue};
  display: inline;
`;

const ReceiptNoReceiptPlaceholder = ({
  uploadingReceipt,
  receiptMissing,
  onClick,
  buttonText,
}) => {
  const renderUploadReceiptButton = () => (
    <SimpleBlueButton id="upload-receipt-button" onClick={onClick}>
      {buttonText}
    </SimpleBlueButton>
  );

  const noReceiptText = () => (
    <p>
      Drop your receipt image here <br /> or {renderUploadReceiptButton()}
    </p>
  );

  const receiptMissingText = () => (
    <>
      <p>
        <strong>Receipt marked as missing</strong>
      </p>
      <p>
        If you have found the receipt, drop your <br />receipt image here or{' '}
        {renderUploadReceiptButton()}
      </p>
    </>
  );

  return (
    <NoReceiptWrapper>
      <ReceiptMessageWrapper>
        <NoReceiptIconWrapper>
          {uploadingReceipt ? (
            <LoadingIcon type="loading" />
          ) : (
            <NoReceiptIcon src={noReceiptIcon} alt="No Receipt" />
          )}
        </NoReceiptIconWrapper>
        {receiptMissing ? receiptMissingText() : noReceiptText()}
      </ReceiptMessageWrapper>
    </NoReceiptWrapper>
  );
};

ReceiptNoReceiptPlaceholder.propTypes = {
  uploadingReceipt: PropTypes.bool,
  receiptMissing: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
};

ReceiptNoReceiptPlaceholder.defaultProps = {
  buttonText: 'choose file',
};

export default ReceiptNoReceiptPlaceholder;
