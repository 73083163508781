import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Date } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import ReceiptBinPreview from './ReceiptBinPreview';
import { getHumanizedDate } from '../../utils';

const GroupWrapper = styled.div`
  padding: 24px 0 8px 0;
  line-height: 1.71;
`;

const ReceiptsBinPreview = ({
  receipts,
  groupName: groupDate,
  onDeleteReceipt,
  onSelectReceipt,
  onViewReceipt,
  activeReceiptId,
}) => {
  const transformGroupDate = date => {
    const humanDate = getHumanizedDate(date, 'MMM D, Y', true);
    return `${humanDate}`.replace(/(\sat\s.*$)/g, '');
  };

  const renderGroupDate = () => (
    <GroupWrapper>
      <Date
        date={groupDate}
        transformDateTime={transformGroupDate}
        styling={{ color: Colors.gray }}
      />
    </GroupWrapper>
  );

  const renderReceiptsGrid = () =>
    receipts.map(receipt => (
      <ReceiptBinPreview
        key={`receipt-bin-preview-${receipt.fileID}`}
        receipt={receipt}
        onDeleteReceipt={onDeleteReceipt}
        onSelectReceipt={onSelectReceipt}
        onViewReceipt={onViewReceipt}
        loading={activeReceiptId === receipt.fileID}
      />
    ));

  return (
    <>
      {renderGroupDate()}
      {renderReceiptsGrid()}
    </>
  );
};

ReceiptsBinPreview.propTypes = {
  groupName: PropTypes.string, // Date
  receipts: PropTypes.array,
  onDeleteReceipt: PropTypes.func,
  onSelectReceipt: PropTypes.func,
  onViewReceipt: PropTypes.func,
  activeReceiptId: PropTypes.string,
};

export default ReceiptsBinPreview;
