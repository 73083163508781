import React from 'react';
import styled from 'styled-components';
import { Heading } from 'center-ui';
import CenteredPageTemplate from '../../../templates/CenteredPageTemplate';
import logo from '../../../assets/imgs/centercardlogo.svg';
import emoji from '../../../assets/imgs/emoji1.png';

const CenteredPageTemplateStyled = styled(CenteredPageTemplate)`
  min-height: 100% !important;
`;

const Wrapper = styled.div`
  max-width: 28.5rem;
  width: 100%;
  text-align: center;
`;

const Logo = styled.img`
  width: 100%;
  height: 2.5rem;
  margin-top: 1.25rem;
`;

const Emoji = styled.img`
  margin: 1.25rem 0;
`;

const Text = styled(Heading)`
  margin-bottom: 1rem !important;
`;

const ErrorPage = () => (
  <CenteredPageTemplateStyled>
    <Wrapper>
      <Heading as="h1">Oh no!</Heading>
      <Emoji alt="Error emoji" src={emoji} width={140} />
      <Text as="h4">Something isn&#39;t working right</Text>
      <p>Don&#39;t worry we will put our best team on it.</p>
      <Logo alt="Center Logo" src={logo} />
    </Wrapper>
  </CenteredPageTemplateStyled>
);

export default ErrorPage;
