import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete as AntAutoComplete } from 'antd';

const { Option, OptGroup } = AntAutoComplete;

const AutoComplete = ({ getScrollableArea, onSearch, ...rest }) => {
  const [open, setOpen] = useState(rest.defaultOpen);

  // ComponentDidMount
  useEffect(() => {
    if (getScrollableArea) {
      getScrollableArea().addEventListener('scroll', closeMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ComponentWillUnmount
  useEffect(
    () => () => {
      if (getScrollableArea) {
        getScrollableArea().removeEventListener('scroll', closeMenu);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const closeMenu = () => {
    setOpen(false);
  };

  const handleSearch = value => {
    if (onSearch) onSearch(value);
    setOpen(true);
  };

  return (
    <AntAutoComplete
      notFoundContent="No results found"
      {...rest}
      onSearch={handleSearch}
      open={open}
      onDropdownVisibleChange={isOpen => setOpen(isOpen)}
    />
  );
};

AutoComplete.propTypes = {
  getScrollableArea: PropTypes.func,
  onSearch: PropTypes.func,
};

AutoComplete.Option = Option;
AutoComplete.OptGroup = OptGroup;

export default AutoComplete;
