import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import AsyncProgressDialog from '../../components/AsyncProgressDialog';
import { GET_JOB } from '../../services/graphql/queries';
import useAsyncJobs from '../../common/useAsyncJobs';
import { ASYNC_JOB_STATUS, ASYNC_JOB_PARAMS } from '../../utils/asyncJobs';

const pollInterval = 1500; // 1.5 sec

const {
  Active,
  Pending,
  PendingChanges,
  Complete,
  CompleteWithWarning,
  NoOperation,
  Failed,
  Error,
} = ASYNC_JOB_STATUS;

const AsyncJobs = ({ match, onAsyncCallback }) => {
  const client = useApolloClient();
  const [
    { jobs, hasPendingJob, message },
    upsertAsyncJob,
    deleteAsyncJob,
  ] = useAsyncJobs();
  const pendingJobs = _filter(
    jobs,
    j => j.status === Pending || j.status === PendingChanges,
  );
  let errorCount = 0;

  const handleAsyncJobStatusChange = useCallback(job => {
    upsertAsyncJob(job);
    // https://stackoverflow.com/questions/56399643/react-hooks-dependencies-infinite-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAsyncJobDelete = useCallback(jobID => {
    deleteAsyncJob(jobID);
    // https://stackoverflow.com/questions/56399643/react-hooks-dependencies-infinite-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAsyncJobError = useCallback(({ job, error = true }) => {
    const { jobType } = job;
    if (ASYNC_JOB_PARAMS[jobType].onComplete) {
      ASYNC_JOB_PARAMS[jobType].onComplete({
        job,
        error,
        client,
        onAsyncCallback,
      });
    }
    // https://stackoverflow.com/questions/56399643/react-hooks-dependencies-infinite-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      const [errorJob] = _filter(jobs, { status: Error });
      if (errorJob) {
        const { ID: jobID } = errorJob;
        handleAsyncJobDelete(jobID);
        handleAsyncJobError({ job: errorJob });
      }
    },
    [
      jobs,
      handleAsyncJobError,
      handleAsyncJobStatusChange,
      handleAsyncJobDelete,
    ],
  );

  const renderJobQuery = job => {
    const {
      ID: jobID,
      pollInterval: jobPollInterval,
      status: jobStatus,
      jobType,
    } = job;
    if (`${jobID}`.includes('tempAsyncJob')) return null; // Don't query on temp entries
    const poll = jobStatus === Pending ? jobPollInterval || pollInterval : null;
    // TODO Replace Query, useApolloClient with useLazyQuery from react-apollo when available
    // https://www.apollographql.com/docs/react/api/react-hooks/#uselazyquery
    return (
      <Query
        key={jobID}
        query={GET_JOB}
        variables={{ id: jobID }}
        notifyOnNetworkStatusChange
        fetchPolicy="network-only"
        pollInterval={poll}
        onCompleted={data => {
          const status = _get(data, 'job.status', null);
          const error = _get(data, 'job.warning', null);

          switch (status) {
            case Active:
            case Pending:
            case PendingChanges:
              // Keep Calm and Carry On (continue polling)
              break;
            case Complete:
            case CompleteWithWarning:
            case NoOperation:
            case Failed:
            default:
              handleAsyncJobDelete(jobID);
              ASYNC_JOB_PARAMS[jobType].onComplete({
                job: { ...job, status },
                route: match.path,
                data,
                error,
                client,
                onAsyncCallback,
              });
              break;
          }
        }}
        onError={err => {
          errorCount += 1;
          if (errorCount > 3) {
            // Fail after 3 polling errors
            handleAsyncJobDelete(jobID);
            handleAsyncJobError({
              job: { ...job, status: Error },
              error: err,
            });
          }
        }}
      >
        {() => null}
      </Query>
    );
  };

  return (
    <>
      {hasPendingJob && <AsyncProgressDialog message={message} />}
      {pendingJobs.map(renderJobQuery)}
    </>
  );
};

AsyncJobs.propTypes = {
  match: PropTypes.object,
  onAsyncCallback: PropTypes.func,
};

export default withRouter(AsyncJobs);
