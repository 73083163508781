import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import media from 'styled-media-query';
import Colors from '../../themes/colors';

const Modal = styled(AntModal)`
  .ant-modal-title {
    font-size: 1.125rem !important;
    font-weight: 700 !important;
  }

  .ant-modal-header {
    border-bottom: none !important;
  }

  .ant-modal-footer {
    padding: 1.5rem !important;
    border-top: none !important;
  }

  .ant-modal-body {
    padding: 0 1.5rem !important;
  }

  .ant-btn {
    height: 3rem !important;
  }

  a.ant-btn {
    line-height: 2.875rem !important;
    margin-left: 0.5rem;
  }

  .ant-btn-cancel:not(:hover) {
    border: 1px solid ${Colors.gray} !important;
    color: ${Colors.gray} !important;
  }

  .ant-btn-cancel:active {
    background-color: ${Colors.gray_6} !important;
  }

  .ant-btn-danger:not(:hover) {
    background-color: ${Colors.red} !important;
    color: ${Colors.white} !important;
    border: 1px solid ${Colors.red} !important;
  }

  .ant-btn-danger:focus {
    background-color: ${Colors.red} !important;
    color: ${Colors.white} !important;
  }

  .ant-modal-close {
    visibility: hidden !important;
    top: -0.125rem !important;
    ${media.lessThan('medium')`
         visibility: visible !important;
    `};
  }

  ${media.lessThan('medium')`
    height: 100% !important;
    max-width: 100% !important;
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    top: 0 !important;

    .ant-modal-content {
      height: 100% !important;
      position: relative !important;
    }

    .ant-modal-footer {
      width: 100% !important;
      position: fixed !important;
      bottom: 0 !important;
      right: 0 !important;
      background: ${Colors.white} !important;
      border-top: 1px solid ${Colors.gray_6} !important;
    }
  `};
`;

const modalFn = {
  SUCCESS: Modal.success,
  ERROR: Modal.error,
  INFO: Modal.info,
  WARNING: Modal.warning,
};

const onModalFn = ({ type = 'INFO', title, content, ...params }) => {
  const TYPE = type.toUpperCase();
  modalFn[TYPE]({
    ...params,
    title,
    content,
  });
};

Modal.fn = onModalFn;

Modal.defaultProps = {
  centered: true,
};

export default Modal;
