import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import Vars from '../../themes/variables';

const Drawer = styled(AntDrawer)`
  .ant-drawer-close {
    color: ${Vars.PRIMARY_COLOR} !important;
  }
  .ant-drawer-header {
    border-bottom: 0 !important;
  }
`;

export default Drawer;
