import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Button } from 'center-ui';
import Colors from 'center-ui/themes/colors';
import ReceiptImg from '../Receipt/ReceiptImg';
import ReceiptBinActionBar from './ReceiptBinActionBar';

const ModalStyled = styled(Modal)`
  max-width: 1200px;
  max-height: calc(100vh - 100px);

  .ant-modal-content {
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    padding: 0 !important;
    min-width: 10rem;
    max-height: calc(100vh - 7.5rem);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-close {
    visibility: hidden !important;
  }

  .ant-spin-nested-loading {
    min-height: 10rem;
  }
`;

const ReceiptImageStyled = styled(ReceiptImg)`
  margin: auto;
`;

const ReceiptScrollArea = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
`;

const ButtonStyled = styled(Button)`
  &&& {
    position: absolute !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    background-color: ${Colors.gray} !important;
    font-size: 1.375rem !important;
    color: white !important;
    border: none !important;
  }
`;

const CancelBtn = styled(ButtonStyled)`
  &&& {
    right: 1.5rem !important;
    top: 1.5rem !important;
  }
`;

const ReceiptViewModal = ({
  receipt: { URI, fileID },
  onAttach,
  onDelete,
  onCancel,
  attachingReceipt,
  deletingReceipt,
  ...rest
}) => (
  <ModalStyled {...rest} visible width="auto" footer={null}>
    <CancelBtn
      id="view-receipt-modal-close-btn"
      shape="circle"
      icon="close"
      onClick={onCancel}
    />
    <ReceiptScrollArea>
      <ReceiptImageStyled src={URI} />
    </ReceiptScrollArea>
    <ReceiptBinActionBar
      onAttach={onAttach}
      onDelete={() => onDelete(fileID)}
      placement="modal"
      attachingReceipt={attachingReceipt}
      deletingReceipt={deletingReceipt}
    />
  </ModalStyled>
);

ReceiptViewModal.propTypes = {
  receipt: PropTypes.object.isRequired,
  onAttach: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  attachingReceipt: PropTypes.bool,
  deletingReceipt: PropTypes.bool,
};

export default ReceiptViewModal;
