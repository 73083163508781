import _intersectionWith from 'lodash/intersectionWith';

export const UserRoles = {
  ALL: '*',
  SP: 'SP', // Spender
  FM: 'FM', // Finance Manager
  BM: 'BM', // Budget Manager
  EA: 'EA', // Approver
  IV: 'insightsViewer',
  ED: 'expenseDelegate',
};

export const isUserInRoles = (userRoles, allowedRoles) =>
  _intersectionWith(
    userRoles,
    allowedRoles,
    (f, s) => s === UserRoles.ALL || f === s,
  ).length > 0;

export const isUserApprover = userRoles =>
  isUserInRoles(userRoles, ['FM', 'EA']);
