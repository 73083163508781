import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _some from 'lodash/some';
import moment from 'moment-timezone';
import API from '../services/rest/api';
import { constructLabelTemplate, fileDownload } from '.';
import {
  APPROVE_ALL_EXPENSES,
  ARCHIVE_ALL_EXPENSES,
  CREATE_EXPENSE_EXPORT,
  CREATE_EXPENSE_EXPORT_CHANGES,
} from '../services/graphql/mutations';
import {
  GET_EXPENSE_EXPORT,
  GET_ASYNC_JOBS,
} from '../services/graphql/queries';
import { REPORTS } from './routes';

export const ASYNC_JOB = {
  ApproveAll: 'expenses.approveall',
  ArchiveAll: 'expenses.archiveall',
  ExpenseExport: 'expenses.export',
  ExpenseExportChanges: 'expenses.export.changes',
};

export const ASYNC_JOB_STATUS = {
  Error: '_error',
  Active: 'active',
  Paused: 'paused',
  Pending: 'pending',
  PendingChanges: 'pendingChanges',
  Complete: 'complete',
  CompleteWithWarning: 'completeWithWarning',
  NoOperation: 'noOperation',
  Failed: 'failed',
};

const {
  Pending,
  PendingChanges,
  Complete,
  CompleteWithWarning,
  NoOperation,
  Failed,
} = ASYNC_JOB_STATUS;

const {
  ApproveAll,
  ArchiveAll,
  ExpenseExport,
  ExpenseExportChanges,
} = ASYNC_JOB;

/**
 * Creates a new async job to save into Apollo cache
 * @param job
 * @returns {{__typename}}
 */
const makeAsyncJob = job => {
  if (!job.__typename) job.__typename = 'AsyncJob';
  if (!job.pollInterval) job.pollInterval = 1500;
  if (!job.message) job.message = ASYNC_JOB_PARAMS[job.jobType].pendingMessage;
  if (!job.variables) job.variables = {};
  if (!job.variables.__typename) job.variables.__typename = 'AsyncJobVariables';
  if (!job.status) job.status = Pending;
  return job;
};

/**
 * Insert/Update/Delete job in Apollo cache
 * @param job
 * @param client
 * @returns {{noop: boolean}|{hasPendingJob, jobs, message: (string|null)}}
 */
export const processAsyncJobs = (job, client) => {
  if (!job || !job.ID) {
    console.error('Missing param in processAsyncJobs(job, client)', {
      job,
      client,
    });
    return { noop: true };
  }
  const { asyncJobs } = client.readQuery({ query: GET_ASYNC_JOBS });
  const jobs = [...asyncJobs.jobs];
  const idx = _findIndex(jobs, { ID: job.ID });
  const shouldInsertJob = idx < 0 && job.jobType;
  const shouldDeleteJob = idx >= 0 && job.shouldDelete;
  const shouldUpdateJob = idx >= 0 && job.jobType;
  if (shouldInsertJob) {
    jobs.push(makeAsyncJob(job));
  } else if (shouldDeleteJob) {
    jobs.splice(idx, 1);
  } else if (shouldUpdateJob) {
    jobs[idx] = makeAsyncJob(job);
  } else {
    console.warning('No-op in processAsyncJobs():', { job, jobs });
    return { noop: true };
  }
  const hasPendingJob =
    _some(jobs, {
      status: Pending,
    }) ||
    _some(jobs, {
      status: PendingChanges,
    });
  const message = (() => {
    if (hasPendingJob) {
      const pendingJobs = _filter(
        jobs,
        j => j.status === Pending || j.status === PendingChanges,
      );
      const [{ message: jobMessage }] = pendingJobs;
      if (pendingJobs.length === 1) {
        return jobMessage;
      }
      return `${jobMessage} +${pendingJobs.length - 1}`;
    }
    return null;
  })();
  return { jobs, hasPendingJob, message };
};

/**
 * Convenience method to refetch an array of queries
 * @param queries
 * @param client
 */
const refetchQueries = (queries, client) => {
  const findQueries = (manager, name) => {
    const matching = [];
    manager.queries.forEach(q => {
      if (q.observableQuery && q.observableQuery.queryName === name) {
        matching.push(q);
      }
    });
    return matching;
  };
  const refetchQueryByName = name =>
    Promise.all(
      findQueries(client.queryManager, name).map(q =>
        q.observableQuery.refetch(),
      ),
    );
  _forEach(queries, queryName => {
    refetchQueryByName(queryName);
  });
};

/**
 * Get job details by jobID after a job is complete
 * @param job
 * @param client
 * @returns {Promise<Promise<*|Promise<any>>|*>}
 */
const queryJobDetails = async (job, client) => {
  const { query } = ASYNC_JOB_PARAMS[job.jobType];
  if (query) {
    return client
      .query({
        query,
        variables: { id: job.ID },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data);
  }
  return Promise.resolve(null);
};

/**
  Helper func to download file from URI
*/
const downloadFile = (uri, fileName, fileFormat) => {
  API.downloadFileFromURI(uri, fileFormat).then(blob => {
    fileDownload(blob, fileName, fileFormat);
  });
};

/**
 * Handler for ExpenseExport
 * @param payload
 */
const handleExpenseExport = payload => {
  const { job, data, client, route, onAsyncCallback } = payload;
  queryJobDetails(job, client)
    .then(jobDetails => {
      const jobStatus = _get(data, 'job.status', null);
      const params = _get(
        ASYNC_JOB_PARAMS[job.jobType].completeMessage,
        jobStatus,
        null,
      );

      const {
        name,
        format,
        startDate,
        endDate,
        uri,
      } = jobDetails.expenseExport;

      if (params) {
        onAsyncCallback(params);
      }

      switch (jobStatus) {
        case Complete:
          if (route === REPORTS) {
            refetchQueries(['expenseExports'], client);
          }
          if (uri) {
            downloadFile(
              uri,
              `${name}-Export-${moment(startDate).format('MM-DD-Y')}-${moment(
                endDate,
              ).format('MM-DD-Y')}.${format}`,
              format,
            );
          }
          break;
        default:
          break;
      }
    })
    .catch(err => {
      console.error(err);
    });
};

/**
 * Handler for ExpenseExportChanges
 * @param payload
 */
const handleExpenseExportChanges = payload => {
  const { job, data, client, route, onAsyncCallback } = payload;
  queryJobDetails(job, client)
    .then(jobDetails => {
      const jobStatus = _get(data, 'job.status', null);
      const params = _get(
        ASYNC_JOB_PARAMS[job.jobType].completeMessage,
        jobStatus,
        null,
      );

      const {
        name,
        format,
        timeStamp,
        changeReports,
      } = jobDetails.expenseExport;

      if (params) {
        if (jobStatus === Complete) {
          onAsyncCallback({
            ...params,
            description: constructLabelTemplate(params.description, { name }),
          });
        } else {
          onAsyncCallback({
            ...params,
          });
        }
      }

      // Get the last change report
      const changeReport = changeReports.length ? changeReports[0] : null;

      switch (jobStatus) {
        case Complete:
          if (route === REPORTS) {
            refetchQueries(['expenseExports'], client);
          }
          if (changeReport && changeReport.uri) {
            downloadFile(
              changeReport.uri,
              `${name}-Changes-${moment(timeStamp).format(
                'MM-DD-Y',
              )}.${format}`,
              format,
            );
          }
          break;
        default:
          break;
      }
    })
    .catch(err => {
      console.error(err);
    });
};

/**
 * Handler for a bulk status change (ApproveAll, ArchiveAll)
 * @param payload
 */
const handleBulkExpenseStatusChange = payload => {
  const { job, data, client, onAsyncCallback } = payload;
  queryJobDetails(job, client)
    .then(jobDetails => {
      const jobStatus = _get(data, 'job.status', null);
      const params = _get(
        ASYNC_JOB_PARAMS[job.jobType].completeMessage,
        jobStatus,
        null,
      );
      const completedIDs = _get(jobDetails, 'completedIDs', []);
      const count = completedIDs.length;

      switch (jobStatus) {
        case Complete:
        case CompleteWithWarning:
        case NoOperation:
        case Failed:
          if (count > 0) {
            refetchQueries(['expenses', 'spendKPI'], client);
          }
          if (params)
            onAsyncCallback({
              ...params,
              description: constructLabelTemplate(params.description, {
                count: count === 0 ? 'Zero' : count,
              }),
            });
          break;
        default:
          break;
      }
    })
    .catch(err => {
      console.error(err);
    });
};

/**
 * Convenience function to check if there are ExpenseExport jobs running
 * @param jobs
 */
export const hasExpenseExportJob = jobs =>
  _some(
    jobs,
    j =>
      j.jobType === ExpenseExport &&
      (j.status === Pending || j.status === PendingChanges),
  );

/**
 * Convenience function to check if there are ExpenseExportChanges jobs running
 * @param jobs
 */
export const hasBulkExpenseStatusChangeJob = jobs =>
  _some(
    jobs,
    j =>
      (j.jobType === ApproveAll || j.jobType === ArchiveAll) &&
      j.status === Pending,
  );

/**
 * Async Job definitions
 * @type {{}}
 */
export const ASYNC_JOB_PARAMS = {
  [ApproveAll]: {
    mutation: APPROVE_ALL_EXPENSES,
    query: null,
    pendingMessage: 'Approving expenses',
    completeMessage: {
      [Complete]: {
        type: 'success',
        message: 'Expenses approved',
        description: `\${count} audit cleared expenses were approved.`,
      },
      [CompleteWithWarning]: {
        type: 'success',
        message: 'Expenses approved',
        description: `\${count} audit cleared expenses were approved.`,
      },
      [NoOperation]: {
        type: 'success',
        message: 'Expenses approved',
        description: `\${count} audit cleared expenses were approved.`,
      },
      [Failed]: {
        type: 'error',
        message: 'Expenses bulk action',
        description: 'There was an error updating expenses. Please try again.',
      },
    },
    onComplete: payload => {
      handleBulkExpenseStatusChange(payload);
    },
  },
  [ArchiveAll]: {
    mutation: ARCHIVE_ALL_EXPENSES,
    query: null,
    pendingMessage: 'Archiving expenses',
    completeMessage: {
      [Complete]: {
        type: 'success',
        message: 'Expenses archived',
        description: `\${count} audit cleared expenses were archived.`,
      },
      [CompleteWithWarning]: {
        type: 'success',
        message: 'Expenses archived',
        description: `\${count} audit cleared expenses were archived.`,
      },
      [NoOperation]: {
        type: 'success',
        message: 'Expenses archived',
        description: `\${count} audit cleared expenses were archived.`,
      },
      [Failed]: {
        type: 'error',
        message: 'Expenses bulk action',
        description: 'There was an error updating expenses. Please try again.',
      },
    },
    onComplete: payload => {
      handleBulkExpenseStatusChange(payload);
    },
  },
  [ExpenseExport]: {
    mutation: CREATE_EXPENSE_EXPORT,
    query: GET_EXPENSE_EXPORT,
    pendingMessage: 'Generating export',
    completeMessage: {
      [Complete]: {
        type: 'success',
        message: 'Export complete',
        description: 'Expenses exported',
      },
      [NoOperation]: {
        type: 'success',
        message: 'Export file empty',
        description:
          'There are no items to export for the time range requested.',
      },
      [Failed]: {
        type: 'error',
        message: 'Export failed',
        description: 'There was an error exporting expenses. Please try again.',
      },
    },
    onComplete: payload => {
      handleExpenseExport(payload);
    },
  },
  [ExpenseExportChanges]: {
    mutation: CREATE_EXPENSE_EXPORT_CHANGES,
    query: GET_EXPENSE_EXPORT,
    pendingMessage: 'Generating change report',
    completeMessage: {
      [Complete]: {
        type: 'success',
        message: 'Check for changes complete',
        description: `"\${name}"`,
      },
      [CompleteWithWarning]: {
        type: 'success',
        message: 'Check for changes complete',
        description: 'There are no changes.',
      },
      [NoOperation]: {
        type: 'success',
        message: 'Check for changes complete',
        description: 'There are no changes.',
      },
      [Failed]: {
        type: 'error',
        message: 'Check for changes failed',
        description:
          'There was an error checking for changes. Please try again.',
      },
    },
    onComplete: payload => {
      handleExpenseExportChanges(payload);
    },
  },
};
