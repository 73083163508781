import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import KpiValue from './KpiValue';
import KpiLabel from './KpiLabel';

const Wrapper = styled.div`
  display: block;
`;

const KPI = props => {
  const {
    className,
    children,
    label,
    value,
    valueStyle,
    labelStyle,
    numberFormat,
  } = props;

  if (children) {
    return <Wrapper className={className}>{children}</Wrapper>;
  }
  return (
    <Wrapper className={className}>
      <KpiValue
        value={value}
        styling={valueStyle}
        numberFormat={numberFormat}
      />
      <KpiLabel label={label} styling={labelStyle} />
    </Wrapper>
  );
};

KPI.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.any,
  value: PropTypes.any,
  valueStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  numberFormat: PropTypes.object,
};

KPI.Label = KpiLabel;
KPI.Value = KpiValue;

export default KPI;
