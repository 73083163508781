import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import * as Colors from '../../themes/colors';

const IconWrapper = styled.div`
  border: none !important;
  background: transparent !important;

  i {
    color: ${Colors.gray_2} !important;
  }
`;

const ExpandIcon = ({ expanded, expandable, onExpand, needIndentSpaced }) => {
  if (!expandable && !needIndentSpaced) return null;
  if (!expandable)
    return <span className="ant-table-row-expand-icon ant-table-row-spaced" />;
  let type;
  let ariaLabel;
  if (expanded) {
    type = 'caret-down';
    ariaLabel = 'Collapse row';
  } else {
    type = 'caret-right';
    ariaLabel = 'Expand row';
  }
  return (
    <IconWrapper
      onClick={onExpand}
      className="ant-table-row-expand-icon"
      ariaLabel={ariaLabel}
    >
      <Icon type={type} />
    </IconWrapper>
  );
};

ExpandIcon.propTypes = {
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  needIndentSpaced: PropTypes.bool,
  onExpand: PropTypes.func,
};

export default ExpandIcon;
