import { createElement } from 'react';
import pathToRegexp from 'path-to-regexp';
import Loadable from 'react-loadable';
import { getMenuDataWithChildren } from './menu';
import * as Routes from '../utils/routes';

let routerDataCache;
// wrapper of dynamic
const dynamicWrapper = component =>
  Loadable({
    loader: () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData();
      }
      return component().then(raw => {
        const Component = raw.default || raw;
        return props =>
          createElement(Component, {
            ...props,
            routerData: routerDataCache,
          });
      });
    },
    loading: () => null,
  });

const getFlatMenuData = menus => {
  let keys = {};
  menus.forEach(item => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
};

export const getRouterData = () => {
  const routerConfig = {
    [Routes.HOME]: {
      component: dynamicWrapper(() => import('../features/HomePage')),
    },
    [Routes.NOT_FOUND_PAGE]: {
      component: dynamicWrapper(() =>
        import('core-ui/components/pages/NotFoundPage'),
      ),
    },
    [Routes.MYCARD_OVERVIEW]: {
      component: dynamicWrapper(() => import('../features/MyCardSummaryPage')),
      name: 'My Card Overview',
    },
    [Routes.MYCARD_EXPENSES]: {
      component: dynamicWrapper(() => import('../features/MyCardExpensesPage')),
      name: 'Expenses',
    },
    [Routes.LOGIN]: {
      component: dynamicWrapper(() => import('../features/LoginPage')),
    },
    [Routes.CHANGE_PASSWORD]: {
      component: dynamicWrapper(() => import('../features/ChangePasswordPage')),
    },
    [Routes.FORGOT_PASSWORD]: {
      component: dynamicWrapper(() => import('../features/ForgotPasswordPage')),
    },
    [Routes.RESET_PASSWORD]: {
      component: dynamicWrapper(() => import('../features/ResetPasswordPage')),
    },
    [Routes.CREATE_PASSWORD]: {
      component: dynamicWrapper(() => import('../features/CreatePasswordPage')),
    },
    [Routes.CENTERACCOUNT_SUMMARY]: {
      component: dynamicWrapper(() =>
        import('../features/CenterAccountSummaryPage'),
      ),
      name: 'Center Account Summary',
    },
    [Routes.CENTERACCOUNT_TRANSACTIONS]: {
      component: dynamicWrapper(() =>
        import('../features/CenterAccountTransactionsPage'),
      ),
      name: 'Center Account Transactions',
    },
    [Routes.STATEMENTS]: {
      component: dynamicWrapper(() => import('../features/StatementsPage')),
      name: 'Statements',
    },
    [Routes.LINKED_ACCOUNTS]: {
      component: dynamicWrapper(() => import('../features/LinkedAccountsPage')),
      name: 'Linked Accounts',
    },
    [Routes.USERS]: {
      component: dynamicWrapper(() => import('../features/UsersPage')),
      name: 'User Management',
    },
    [Routes.USER_CREATE]: {
      component: dynamicWrapper(() => import('../features/UserCreatePage')),
      name: 'User Create',
    },
    [Routes.USER_EDIT]: {
      component: dynamicWrapper(() => import('../features/UserEditPage')),
      name: 'User Edit',
    },
    [Routes.USER_INFO]: {
      component: dynamicWrapper(() => import('../features/UserInfoPage')),
      name: 'User Info',
    },
    [Routes.COST_CENTERS]: {
      component: dynamicWrapper(() => import('../features/CostCentersPage')),
      name: 'Cost Centers',
    },
    [Routes.COST_CENTERS_INFO]: {
      component: dynamicWrapper(() => import('../features/CostCenterInfoPage')),
      name: 'Cost Center View',
    },
    [Routes.COST_CENTERS_CREATE]: {
      component: dynamicWrapper(() =>
        import('../features/CostCenterCreatePage'),
      ),
      name: 'Cost Center Create',
    },
    [Routes.COST_CENTERS_EDIT]: {
      component: dynamicWrapper(() => import('../features/CostCenterEditPage')),
      name: 'Cost Center Edit',
    },
    [Routes.EXPENSE_TYPES]: {
      component: dynamicWrapper(() => import('../features/ExpenseTypesPage')),
      name: 'Expense Types',
    },
    [Routes.EXPENSE_TYPES_CREATE]: {
      component: dynamicWrapper(() =>
        import('../features/ExpenseTypeCreatePage'),
      ),
      name: 'Expense Types Create',
    },
    [Routes.EXPENSE_TYPES_EDIT]: {
      component: dynamicWrapper(() =>
        import('../features/ExpenseTypeEditPage'),
      ),
      name: 'Expense Type Edit',
    },
    [Routes.GENERAL_LEDGER]: {
      component: dynamicWrapper(() => import('../features/GeneralLedgerPage')),
      name: 'General Ledger Accounts',
    },
    [Routes.GENERAL_LEDGER_CREATE]: {
      component: dynamicWrapper(() =>
        import('../features/GeneralLedgerCreatePage'),
      ),
      name: 'General Ledger Acccounts Create',
    },
    [Routes.GENERAL_LEDGER_EDIT]: {
      component: dynamicWrapper(() =>
        import('../features/GeneralLedgerEditPage'),
      ),
      name: 'General Ledger Accounts Edit',
    },
    [Routes.INTEGRATIONS]: {
      component: dynamicWrapper(() => import('../features/IntegrationsPage')),
      name: 'Integrations',
    },
    [Routes.INTEGRATIONS_CONNECTORS]: {
      component: dynamicWrapper(() =>
        import('../features/IntegrationsConnectorsPage'),
      ),
      name: 'Integrations Connectors',
    },
    [Routes.INTEGRATIONS_SETUP]: {
      component: dynamicWrapper(() =>
        import('../features/IntegrationsSetupPage'),
      ),
      name: 'Integration Setup',
    },
    [Routes.INTEGRATIONS_SETTINGS]: {
      component: dynamicWrapper(() =>
        import('../features/IntegrationsSettingsPage'),
      ),
      name: 'Integration Settings',
    },
    [Routes.INTEGRATIONS_IMPORT_SOURCE]: {
      component: dynamicWrapper(() =>
        import('../features/IntegrationsImportSourcePage'),
      ),
      name: 'Integration Settings',
    },
    [Routes.EXPENSE_HUB]: {
      component: dynamicWrapper(() => import('../features/ExpenseHubPage')),
      name: 'Expense Hub',
    },
    [Routes.EXPENSE_DETAILS_VIEW]: {
      component: dynamicWrapper(() =>
        import('../features/MyExpenseDetailsPage'),
      ),
      name: 'Expense Details View',
    },
    [Routes.EXPENSE_DETAILS_REVIEW]: {
      component: dynamicWrapper(() =>
        import('../features/ExpenseDetailsReviewPage'),
      ),
      name: 'Expense Details Review',
    },
    [Routes.MY_MILEAGE_EDIT]: {
      component: dynamicWrapper(() => import('../features/MyMileageEditPage')),
      name: 'Mileage Edit',
    },
    [Routes.MY_OUT_OF_POCKET_EDIT]: {
      component: dynamicWrapper(() =>
        import('../features/MyOutOfPocketEditPage'),
      ),
      name: 'Out of Pocket Edit',
    },
    [Routes.REPORTS]: {
      component: dynamicWrapper(() => import('../features/ReportsPage')),
      name: 'Reports',
    },
    [Routes.INSIGHTS]: {
      component: dynamicWrapper(() => import('../features/InsightsPage')),
      name: 'Insights',
    },
    [Routes.RULES_CUSTOM_APPROVAL]: {
      component: dynamicWrapper(() =>
        import('../features/CustomApprovalRulesPage'),
      ),
      name: 'Custom Approval Rules',
    },
  };

  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuDataWithChildren());

  // Route configuration data
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach(path => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find(key =>
      pathRegexp.test(`${key}`),
    );
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb,
    };
    routerData[path] = router;
  });
  return routerData;
};
